import { useTranslation } from "react-i18next";
import styled from "styled-components"
import '../assets/css/CategoryGrid.css'
import { useNavigation } from '../utils/navigation'

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    overflow:hidden;
    background-color: #e9e9e9;
    
    padding:15px 10px;
    min-height: 180px; 
    
    @media only screen and (max-width:768px){
      padding:0;
    }
`;
const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    
`;
const Image = styled.img`
    
    //position: absolute;
    //background-size: contain;
    top: 0;
    right: 0;
    max-height: 180px;
    max-width:100%;
    //opacity: 0.8;
`;
const Info = styled.div`
    flex:1;
    top:0;
    left:0;
    max-width:60%;
    min-width:60;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left:10px;
    z-index: 2;
    
`;
const Title = styled.h1`
    width:80%;
    letter-spacing: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1;
    margin:0;
    color: ${props=>props.color};
    font-family: 'Righteous';
`;
const Desc = styled.p`
    width: 100%;
    padding: 0;
    border: 0;
    text-align: left;
`;
const Button = styled.button`
    border-radius: 5px;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: .04em;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: .6s all cubic-bezier(.25,1,.5,1);
    background: #fff;
    display: inline-block;
    padding: .6rem 1rem;
    background-color: #0000;
    max-width: 140px;
    border: 2px solid #06213e;
    color: #06213e;
`;

const CategoryItem = ({item}) => {
    const { t, i18n } = useTranslation();
    const {goToCategoryShop} = useNavigation();

  return (
    <Container>
        <InnerContainer>
        <Info>
            {item.id === 1 || item.id ===4 ? <Title color="#8c9192" >
                {item.title.slice(0,2)}
                <span style={{ color: item.color }}>{item.title.slice(2,3)}</span>
                {item.title.slice(3)}
            </Title>:
            <Title color={item.color}>{item.title}</Title>
        }
            

            <Desc>{i18n.language === 'sr' ? item.desc : item.descEN}</Desc>
            <Button onClick={()=>goToCategoryShop(item.title.toLowerCase())}>{t('VIEW_MORE')}</Button>
        </Info>
        <div className="categorygrid_img_container">
            <Image src={item.img} alt={item.title} />
        </div>
        </InnerContainer>
    </Container>
  )
}

export default CategoryItem