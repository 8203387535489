import { calculatePrice } from "../utils/discount";
import {createSlice} from "@reduxjs/toolkit"
import ReactGA from 'react-ga4';
import CryptoJS from 'crypto-js';
const CART_KEY = "cart";


const getCartFromStorage = () => {
    const cart = window.localStorage.getItem(CART_KEY);
    if (cart) {
        try {
            const bytes = CryptoJS.AES.decrypt(cart, 'my-new-secret-key');
            const decryptedCart = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedCart;
        } catch (e) {
            console.error('Error decrypting cart:', e);
            return {
                itemsList: [],
                totalQuantity: 0,
            };
        }
    } else {
        return {
            itemsList: [],
            totalQuantity: 0,
        };
    }
};

const saveCartToStorage = (cart) => {
    try {
        const encryptedCart = CryptoJS.AES.encrypt(
            JSON.stringify(cart),
            'my-new-secret-key'
        ).toString();
        window.localStorage.setItem(CART_KEY, encryptedCart);
    } catch (e) {
        console.error('Error encrypting cart:', e);
    }
};

const initialState = getCartFromStorage();

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const { article, quantity } = action.payload;
      if (quantity <= 0) {return}
      try{
      ReactGA.event({
        category: 'korpa',
        action: 'korpaDodavanje',
        label: article.category + ' '+  article.title,
        // event_params: {
        //   name: "korpa_add_item",
        // },
      });
    } catch (error) {
      console.error('Greška prilikom slanja događaja:', error);
    }
    

      const existingItem = state.itemsList.find(
        (item) => item.article.id === article.id
      );
      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        /*state.itemsList.push({
          article: article,
          quantity: quantity,
        });*/
        const newItem = Object.assign({}, article, {
          discountPrice: Math.round(calculatePrice(article.price, state.totalQuantity))
        });
        state.itemsList.push({
          article: newItem,
          quantity: quantity,
        });
      }
      state.totalQuantity += quantity;
      state.itemsList.forEach(item => {
        item.article.discountPrice = Math.round(calculatePrice(item.article.price, state.totalQuantity));
      });

      saveCartToStorage(state); // čuvanje korpe u storage
    },
    removeFromCart(state, action) {
      const id = action.payload;
      const itemToRemove = state.itemsList.find((item) => item.article.id === id);
      try{
      ReactGA.event({
        category: 'korpa',
        action: 'korpaBrisanje',
        label: itemToRemove.article.category + ' ' + itemToRemove.article.title,
      });
     } catch (error) {
      console.error('Greška prilikom slanja događaja:', error);
    }

      if (itemToRemove) {
        state.itemsList = state.itemsList.filter(
          (item) => item.article.id !== id
        );
        state.totalQuantity -= itemToRemove.quantity;

        if (state.itemsList && state.itemsList.length > 0) {
        state.itemsList.forEach(item => {
          item.article.discountPrice = Math.round(calculatePrice(item.article.price, state.totalQuantity));
        });
        }
      }

      saveCartToStorage(state); // čuvanje korpe u storage
    },
    updateCart(state, action){
      const { article, quantity } = action.payload;
      const existingItem = state.itemsList.find(
        (item) => item.article.id === article.id
      );
      if (existingItem) {
        existingItem.quantity = quantity;
        state.totalQuantity = state.itemsList.reduce((total, item) => total + item.quantity, 0);
        
        state.itemsList.forEach(item => {
          item.article.discountPrice = Math.round(calculatePrice(item.article.price, state.totalQuantity));
        });
        saveCartToStorage(state); // čuvanje korpe u storage
      }
    },
    resetCart(state) {
      state.itemsList = [];
      state.totalQuantity = 0;
      saveCartToStorage(state); // čuvanje prazne korpe u storage
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;



/*
const getCartFromStorage = () => {
  const cart = window.localStorage.getItem(CART_KEY);
  if (cart) {
    return JSON.parse(cart);
  } else {
    return {
      itemsList: [],
      totalQuantity: 0,
    };
  }
};

const saveCartToStorage = (cart) => {
  window.localStorage.setItem(CART_KEY, JSON.stringify(cart));
};*/
/*const cartSlice = createSlice({
    name: 'cart',
    initialState : {
        itemsList:[],
        totalQuantity: 0,
    },
    reducers : {
        addToCart(state, action) { 
            const { article, quantity } = action.payload;
            const existingItem = state.itemsList.find(item => item.article.id === article.id);
            if (existingItem) {
              existingItem.quantity += quantity;
              existingItem.totalPrice += Math.round(article.price * quantity * 100) / 100;
            } else {
              state.itemsList.push({
                article: article,
                quantity: quantity,
                totalPrice: Math.round(article.price * quantity * 100) / 100
              })
            }
            state.totalQuantity += quantity;
          },
        removeFromCart(state,action) {
            const id = action.payload;
            const itemToRemove = state.itemsList.find(item => item.article.id === id);
            
            if (itemToRemove) {
                state.itemsList = state.itemsList.filter(item => item.article.id !== id);
                state.totalQuantity -= itemToRemove.quantity;
    }

         },
    },
});

export const cartActions = cartSlice.actions;

export default cartSlice;*/