import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import "../assets/css/PurchaseConformation.css";
import { ReactComponent as SuccessSvg } from "../assets/images/success-svgrepo-com.svg";
import { CheckoutContext } from '../context/CheckoutContext';
import { useNavigation } from "../utils/navigation";
import zvezdeGIF from '../assets/images/icons/zvezde.gif'


const animatedGif = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const Container = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position: relative;
`;
const Logo = styled.h1`
    font-weight: 100;
    font-size: 40px;
    letter-spacing: 5px;
    font-family:"Righteous";
    padding:0px;
    margin:0px;
    margin-left:10px;
    text-align: center;
    @media only screen and (max-width:768px){
       letter-spacing:1px;
    }
`;
const HeadDiv = styled.div`
    background-color: #f1f1f1;
    margin-top:120px;
    width:50vw;

    height:50vh;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    //background-image: url(${zvezdeGIF});
    position:relative;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    @media only screen and (max-width:768px){
        width:90vw;
    }
    z-index:2;
`;
const Title = styled.h2`
    padding:0px;
    margin:0;
    color:black;
    font-size: ${props => props.font_size};
    color: ${props => props.color};
`;
const Button = styled.button`
    margin:40px 0px;
    padding: 12px;
    border:2px solid #5862bf;
    border-radius: 10px;
    background-color: #5862bf;
    cursor:pointer;
    font-weight: 500;
    color:white;
    width:30%;
   
    white-space: nowrap;
    &:hover{
        background-color: white;
        color:#5862bf;
    }
    font-size:12px;
    @media only screen and (max-width:768px){
        width:50%;
    }
    z-index:10;
`;
const Image1 = styled.img`
  position:absolute ;
  z-index:3;
  //background-color: black;
  margin-top:100px;
  width:50vw;
  height:50vh;
  animation: ${animatedGif} 4s infinite;
  @media only screen and (max-width:768px){
    width:90vw;
  }
`;

const Image2 = styled.img`
  position:absolute ;
  z-index:3;
  //background-color: black;
  margin-top:40px;
  width:50vw;
  height:50vh;
  @media only screen and (max-width:768px){
    width:90vw;
  }
  transform: translate(10%, -20%);
  animation: ${animatedGif} 2s infinite;
`;
const Image3 = styled.img`
  position:absolute;
  //background-color: black;
  //margin-top:0px;
  top:0;
  width:50vw;
  height:120px;
  @media only screen and (max-width:768px){
    width:90vw;
  }
  z-index:4;
`;

const TabContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tab = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Righteous';
  font-size: 12px;
  height: 40px;
  text-align: center;
`;

const ActiveTab = styled(Tab)`
  border-bottom: 3px solid teal;
  color: #000;
`;

const InactiveTab = styled(Tab)`
  border-bottom: 3px solid #cfcfcf;
  color: #cfcfcf;
`;

const PurchaseConfirmation = () => {
  const { t } = useTranslation();
  const { goToHome } = useNavigation();
  const { purchaseData } = useContext(CheckoutContext);

  return (
    <div>
      <TabContainer>
      <InactiveTab>{t('Checkout.ODABIR_PROIZVODA')}</InactiveTab>
      <InactiveTab>{t('Checkout.DOSTAVA_I_PLACANJE')}</InactiveTab>
      <ActiveTab>{t('Checkout.POTVRDA')}</ActiveTab>
    </TabContainer>
    <Container >
      <Image3 src={require('../assets/images/icons/confetti.gif')}/>
      <HeadDiv>
      <Logo>SNUS</Logo>
      <Image1 src={require('../assets/images/icons/zvezde.gif')}/>
      <Image2  src={require('../assets/images/icons/zvezde.gif')}/>
      <SuccessSvg className="success-svg" /> 
        <Title font_size="24px" color='#0e0e0e' style={{padding:"10px 0px"}}>{t('PurchaseConfirmation.THANK_YOU')}</Title>
        <Title font_size="18px" color='#585858'style={{padding:"5px 0px"}}>{t('PurchaseConfirmation.ORDER_NUMBER')} #ID - {purchaseData.id ? purchaseData.id : "trenutak..." }</Title>
        <Title font_size="14px" color='#585858' style={{width:"70%", textAlign:"center"}}>{t('PurchaseConfirmation.PROCESSING')}</Title>
        <Button onClick={()=> goToHome()}>{t('PurchaseConfirmation.HOME')}</Button>
      </HeadDiv>
    </Container>
    </div>
  );
};

export default PurchaseConfirmation;
