import { Add, Remove } from '@mui/icons-material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

const fadeInTop = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const fadeOutBottom = keyframes`
  from {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const ContainerX  = styled.div`
    background-color:#f1f1f1;
    @media only screen and (min-width:768px){
        padding: 40px 10px 20px 20px;
    }
`;
const Container = styled.div`
  ${({ isOpen }) => isOpen
    ? css`
      display: none;
    `
    : css`
    display:block;
    `}

    color: black;
    padding: 0;
    border: 0;
    outline: none;
    width: 100%;
    font-weight: 700;
    background-color: transparent;//rgba(0, 0, 0, 0.3);
`;
const Box = styled.div`
 
  ${({ isOpen }) => isOpen
    ? css`
      animation: ${fadeOutBottom} 0.5s ease-in-out both;
    `
    : css`
      animation: ${fadeInTop} 0.5s ease-in-out both;
    `}
    
    width: 100%;
    background-color:#f1f1f1;;
    padding:0px;
    @media only screen and (max-width:768px){
        width:100vw;
    }
    top:0;
    
    
`;
const MyAccountHead = styled.div`
    font-family: "Righteous";
    font-weight: 100;
    font-size: 12px;
    padding: 20px 10px;
    margin:0;
    display:flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    background-color: #f1f1f1;
    color: #1f1f1f;
    @media only screen and (min-width:768px){
        //display:none;
    }
`;
const MyAccountBody = styled(MyAccountHead)`
    border-bottom:2px solid #ddd;
`
const CustomerSidebar = () => {
    const { t } = useTranslation();
    const customerDetails = [
        {
          name: t('AccountDropDown.MyAccount'),
          link: '/kupac/moj-nalog/',
        },
        {
          name: t('AccountDropDown.MyDetails'),
          link: '/kupac/moji-detalji/',
        },
        {
          name: t('AccountDropDown.OrderHistory'),
          link: '/kupac/istorija-narudzbina/',
        },
        {
          name: t('AccountDropDown.AddressBook'),
          link: '/kupac/adresar/',
        },
      ]
const [isOpenContainer, setIsOpenContainer] = useState(true);
  const [isOpenBox, setIsOpenBox] = useState(true);
  const [isBoxAnimating, setIsBoxAnimating] = useState(false);

  const toggleBox = () => {

    if (isBoxAnimating) {
      return;
    }
    setIsOpenBox(!isOpenBox);
    setIsBoxAnimating(true);
    if (isOpenContainer === false) {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 500);
    } else {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 10);
    }
  };
  return (
      <ContainerX>
    <MyAccountHead onClick={toggleBox}>{t('AccountDropDown.ACCOUNT')} {isOpenContainer ? <Add/>: <Remove/> } </MyAccountHead>
    <Container isOpen={isOpenContainer} isBoxAnimating={isBoxAnimating}>
            <Box isOpen={isOpenBox} isBoxAnimating={isBoxAnimating} onClick={(event) => event.stopPropagation()}>
            {customerDetails.map((item, index) => (
                <MyAccountBody key={index} >
                    <Link style={{textDecoration:"none", all:"unset", width:"100%"}}  to={item.link}>{item.name}</Link>
                </MyAccountBody>
            ))}
    </Box>
</Container>
    </ContainerX>
  )
}

export default CustomerSidebar