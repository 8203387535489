import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import Footer from "../layouts/footer/Footer";
import PurchaseConformation from "./PurchaseConformation";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CheckoutContext } from "../context/CheckoutContext";
import { discountOptions } from "../data";
import { CartContext } from "../context/CartContext";
import { AuthUserContext } from "../context/AuthUserContext";
import Header from "../layouts/header/Header";
import CheckoutForm from "../components/CheckoutForm";
import AddressBook from "../components/AddressBook";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import Login from "./Login";
import { Close } from "@mui/icons-material";

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ContainerRight = styled.div`
  padding: 40px;
  margin-top: 60px;
  width: 40%;
  background-color: white;
  @media only screen and (max-width: 768px) {
    padding: 0px;
    width: 100%;
  }
`;
const CheckoutButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
const CheckoutButton = styled.button`
  width: 50%;
  margin-top: 20px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 20px;
  text-decoration: none;
  transition: 0.6s all cubic-bezier(0.25, 1, 0.5, 1);
  background-color: ${(props) => (props.disabled ? "#ccc" : "#5862bf")};
  border: ${(props) => (props.disabled ? "none" : "2px solid #5862bf")};
  color: #fff;
  display: block;
  margin-bottom: 1rem;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  &:hover {
    background-color: ${(props) => (props.disabled ? "#ccc" : "#6b76da")};
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    width: 70vw;
  }
`;
const ContainerLeft = styled.div`
  padding: 40px;
  margin: 100px 0px;
  width: 400px;
  background-color: #f1f1f1;
  @media only screen and (max-width: 768px) {
    padding: 0;
    margin: 20px 0px;
    width: 95%;
  }
  border-radius: 10px;
`;
const ContainerLeftWrapper = styled.div`
  //background-color: #fff;
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`;
const Title = styled.h2`
  font-size: 25px;
  //font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-family: "Righteous";
`;
const ProductQuantity = styled.h3`
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 1.4px;
  padding: 0;
  //margin:0px 0px 10px 0px;
  margin: 0;
  color: #777;
`;
const ProductDiscountNotes = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: left;
  gap: 3px;
  justify-content: left;
  flex-wrap: wrap;
`;
const ProductDiscountNote = styled.h1`
  background-color: ${(props) => props.background_color};

  margin: 0;
  padding: 8px 7px;
  font-size: 10px;
  line-height: 1;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: left;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    //font-size:12px;
  }
`;
const WrapTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
`;

const ProductTitle = styled.h2`
  padding: 10px 0 5px 0;
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: #686868;
`;
const ProductPrice = styled.h3`
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 1.4px;
  padding: 0;
  margin: 20px 0px 0px 0px;
  color: #000;
  font-family: "Righteous";
`;
const SumContainer = styled.div`
  display: ${(props) => props.display || "flex"};
  padding: 15px 0px;
  border-bottom: 1px solid #aaa;
  justify-content: space-between;
  gap: 20px;
`;
const SumText = styled.h3`
  text-align: left;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 1px;
  padding: 0;
  margin: 10px 0px;
  color: ${(props) => props.color || "#777777"};
  font-family: "Righteous";
`;
const TotalPrice = styled.h2`
  font-size: 25px;
  //font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin: 0;
  padding: 10px 0px;
  font-family: "Righteous";
  color: #1f1f1f;
`;

const P = styled.p`
  font-size: 13px;
  color: #777;
  letter-spacing: 2px;
`;
const TabContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tab = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: "Righteous";
  font-size: 12px;
  height: 40px;
  text-align: center;
`;

const ActiveTab = styled(Tab)`
  border-bottom: 3px solid teal;
  color: #000;
`;

const InactiveTab = styled(Tab)`
  border-bottom: 3px solid #cfcfcf;
  color: #cfcfcf;
`;

const EditAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #0000007a;
  z-index: 9;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
`;
const EditAddressWrapper = styled.div`
  height: 90%;
  width: 90%;
  background-color: white;
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  position: relative;
  @media only screen and (min-width: 400px) {
    width: 40%;
  }
`;
const Exit = styled.div`
  position: fixed;
  //top:20px;
  //right:20px;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
`;

const MobileCheckoutDiv = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    /* Stilovi za mobilne uređaje */
  }
`;
const Checkout = () => {
  const { t, i18n } = useTranslation();

  const { user } = useContext(AuthUserContext);

  const [selectedOption, setSelectedOption] = useState("option1");
  const [editAddress, setEditAddress] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const navigate = useNavigate();
  useEffect(() => {
    if (parseInt(totalQuantity) === 0) {
      navigate("/korpa");
    }
  }, []);

  const cartItems = useSelector((state) => state.cart.itemsList);
  const {
    totalPrice,
    totalDiscountedPrice,
    shippingCost,
    shippingCostDiscount,
  } = useContext(CartContext);

  const { purchaseData, submitted, setSubmitted } = useContext(CheckoutContext);

  return (
    <div>
      <Helmet>
        <title>Narudžbina</title>
        <meta
          name="description"
          content="Završite vašu narudžbinu i kupite svoj omiljeni snus online u Srbiji na našoj online web stranici. Kupite , narucite najbolje snus nikotinske proizvode na terioriji cele Srbije."
        />
        <meta property="og:title" content="Snus Narudžbina | Snus Srbija" />
        <meta
          property="og:description"
          content="Zavrsite vasu narudzbinu i kupite svoj omiljeni snus online u Srbiji na našoj online web stranici. Kupite , narucite najbolje snus nikotinske proizvode na terioriji cele Srbije."
        />
        <meta
          property="og:image"
          content={require("../assets/images/checkoutCover.webp")}
        />
        <link rel="canonical" href="https://www.snussrbija.com/narudzbina" />
        <meta name="keywords" content="snus narudzbina" />
      </Helmet>
      {Object.keys(purchaseData).length !== 0 ? (
        <PurchaseConformation />
      ) : (
        <>
          {submitted && (
            <div style={{ position: "fixed", width: "100%", height: "100%" }}>
              <Loading />
            </div>
          )}
          {editAddress && (
            <div
              style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                zIndex: "99999",
              }}
            >
              <EditAddressContainer>
                <EditAddressWrapper>
                  <Exit onClick={() => setEditAddress(!editAddress)}>
                    <Close style={{ color: "black", fontSize: "30px" }} />
                  </Exit>
                  <AddressBook />
                </EditAddressWrapper>
              </EditAddressContainer>
            </div>
          )}
          <Header />

          {!user ? (
            <Login />
          ) : (
            <ContainerWrapper>
              <ContainerRight>
                <TabContainer>
                  <InactiveTab>{t("Checkout.ODABIR_PROIZVODA")}</InactiveTab>
                  <ActiveTab>{t("Checkout.DOSTAVA_I_PLACANJE")}</ActiveTab>
                  <InactiveTab>{t("Checkout.POTVRDA")}</InactiveTab>
                </TabContainer>
                <MobileCheckoutDiv>
                  <Title style={{ textAlign: "center", fontWeight: "300" }}>
                    {t("Checkout.DELIVERY_ADDRESS")}
                  </Title>
                  <div style={{ padding: "0 20px" }}>
                    <Title style={{ fontWeight: "300", fontSize: "20px" }}>
                      {user.first_name} {user.last_name}
                    </Title>
                    <SumText
                      color="#777"
                      style={{
                        textTransform: "uppercase",
                        letterSpacing: "1.6px",
                        fontSize: "11px",
                      }}
                    >
                      SRBIJA
                    </SumText>
                    <SumText
                      color="#777"
                      style={{
                        textTransform: "uppercase",
                        letterSpacing: "1.6px",
                        fontSize: "11px",
                      }}
                    >
                      {user.city} - {user.postal_code}
                    </SumText>
                    <SumText
                      color="#777"
                      style={{
                        textTransform: "uppercase",
                        letterSpacing: "1.6px",
                        fontSize: "11px",
                      }}
                    >
                      {user.address} {user.house_number && user.house_number}
                    </SumText>
                    <SumText
                      color="#777"
                      style={{
                        textTransform: "uppercase",
                        letterSpacing: "1.6px",
                        fontSize: "11px",
                      }}
                    >
                      tel: {user.phone}
                    </SumText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CheckoutButton
                      style={{ padding: "10px 10px " }}
                      onClick={() => setEditAddress(!editAddress)}
                    >
                      {t("AddressBook.CHANGE_ADDRESS")}
                    </CheckoutButton>
                  </div>
                </MobileCheckoutDiv>
                <CheckoutForm />
                <CheckoutButtonDiv>
                  <CheckoutButton
                    type="submit"
                    onClick={() => setSubmitted(true)}
                    disabled={!isChecked}
                  >
                    {t("Checkout.POTVRDI_NARUDZBINU")}
                  </CheckoutButton>
                </CheckoutButtonDiv>
              </ContainerRight>
              <ContainerLeft>
                <ContainerLeftWrapper>
                  <Title>{t("Checkout.VASA_PORUDZBINA")}</Title>
                  {cartItems.map((item) => (
                    <SumContainer
                      style={{ display: "block" }}
                      key={item.article.id}
                    >
                      <ProductTitle style={{ color: item.article.color }}>
                        {" "}
                        <span style={{ color: "#1f1f1f" }}>
                          {item.article.category}{" "}
                        </span>{" "}
                        {item.article.title}
                      </ProductTitle>
                      <ProductDiscountNotes>
                        {discountOptions.map((option) => {
                          if (
                            totalQuantity > option.quantity &&
                            (option.maxQuantity === undefined ||
                              totalQuantity <= option.maxQuantity)
                          ) {
                            return (
                              <ProductDiscountNote
                                background_color={option.backgroundColor}
                                key={option.id}
                              >
                                {i18n.language === "en"
                                  ? option.messageEN
                                  : option.message}
                              </ProductDiscountNote>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </ProductDiscountNotes>
                      <WrapTwo>
                        <ProductQuantity>
                          {t("Checkout.KOLICINA")}:
                        </ProductQuantity>
                        <ProductTitle
                          style={{
                            fontFamily: "Righteous",
                            margin: "0",
                            padding: "0",
                            fontSize: "18px",
                            color: "#1f1f1f",
                          }}
                        >
                          {item.quantity}x
                        </ProductTitle>
                      </WrapTwo>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {totalQuantity >= 5 && (
                          <ProductPrice
                            style={{
                              color: "#777",
                              textDecoration: "line-through",
                            }}
                          >
                            {item.article.price}.00RSD
                          </ProductPrice>
                        )}
                        <ProductPrice>
                          {item.article.discountPrice}.00RSD
                        </ProductPrice>
                      </div>
                    </SumContainer>
                  ))}
                  <SumContainer style={{ alignItems: "center" }}>
                    <SumText
                      style={{ textTransform: "uppercase", fontSize: "18px" }}
                    >
                      {t("Checkout.UKUPNO")}
                    </SumText>
                    <div>
                      {totalQuantity >= 5 && (
                        <SumText style={{ textDecoration: "line-through" }}>
                          {totalPrice.toLocaleString()}.00RSD
                        </SumText>
                      )}
                      <SumText color="#000000">
                        {totalDiscountedPrice.toLocaleString()}.00RSD
                      </SumText>
                    </div>
                  </SumContainer>
                  <SumContainer display="block">
                    <SumText
                      style={{ textTransform: "uppercase", fontSize: "18px" }}
                    >
                      {t("Checkout.DOSTAVA")}
                    </SumText>
                    <label
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="radio"
                        value="option1"
                        checked={selectedOption === "option1"}
                        onChange={handleOptionChange}
                        style={{ cursor: "pointer" }}
                      />
                      <SumText
                        color="#1f1f1f"
                        style={{
                          textTransform: "uppercase",
                          letterSpacing: "1.6px",
                          fontSize: "11px",
                        }}
                      >
                        {t("Checkout.PLACANJE_KURIru")}
                      </SumText>
                      <div>
                        {totalQuantity >= 5 && totalQuantity < 50 ? (
                          <>
                            <SumText
                              color="#777"
                              style={{ textDecoration: "line-through" }}
                            >
                              {shippingCost}.00RSD
                            </SumText>
                            <SumText color="#00AEAE">
                              {t("Checkout.BESPLATNO")}
                            </SumText>
                          </>
                        ) : (
                          <SumText color="#000">
                            {shippingCostDiscount}.00RSD
                          </SumText>
                        )}
                      </div>
                    </label>
                  </SumContainer>
                  <SumContainer style={{ display: "block" }}>
                    <SumText
                      style={{
                        color: "black",
                        textTransform: "uppercase",
                        fontSize: "20px",
                        fontWeight: "700",
                        letterSpacing: "0",
                      }}
                    >
                      {t("Checkout.UKUPNO")}
                    </SumText>
                    <SumText
                      style={{
                        color: "#1f1f1f",
                        textTransform: "uppercase",
                        fontSize: "12px",
                      }}
                    >
                      {t("Checkout.UKUPNO_TROSKOVI")}
                    </SumText>
                    <TotalPrice>
                      {Math.round(
                        totalDiscountedPrice + shippingCostDiscount
                      ).toLocaleString()}
                      .00RSD
                    </TotalPrice>
                  </SumContainer>
                  <SumContainer style={{ border: "none" }}>
                    <label style={{ display: "flex", gap: "15px" }}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{ cursor: "pointer" }}
                        required
                      />
                      <P color="#000000" style={{ textAlign: "left" }}>
                        {t("Checkout.STIKLIRANJEM_POLJA")}
                        <Link
                          to="/politika-privatnosti"
                          style={{
                            color: "blue",
                            borderBottom: "1px solid blue",
                            cursor: "pointer",
                          }}
                        >
                          {t("Checkout.USLOVI_KUPOVINE")}
                        </Link>{" "}
                        *
                      </P>
                    </label>
                  </SumContainer>
                </ContainerLeftWrapper>
              </ContainerLeft>
            </ContainerWrapper>
          )}
          <Footer />
        </>
      )}
    </div>
  );
};

export default Checkout;
