function calculateDiscountNew(productName, quantity) {
  if (productName === "Proizvod A") {
    if (quantity >= 50) {
      return 0.34;
    } else if (quantity >= 30) {
      return 0.3;
    } else if (quantity >= 10) {
      return 0.25;
    } else if (quantity >= 5) {
      return 0.125;
    } else if (quantity >= 3) {
      return 0.065;
    } else {
      return 0;
    }
  } else if (productName === "Proizvod B") {
    // Dodati uslove za popuste za Proizvod B
  } else if (productName === "Proizvod C") {
    // Dodati uslove za popuste za Proizvod C
  } else {
    return 0;
  }
}

function calculateDiscountOld(quantity) {
  if (quantity >= 50) {
    return 0.34;
  } else if (quantity >= 30) {
    return 0.3;
  } else if (quantity >= 10) {
    return 0.25;
  } else if (quantity >= 5) {
    return 0.125;
  } else if (quantity >= 3) {
    return 0.065;
  } else {
    return 0;
  }
}

function calculateDiscount(quantity) {
  if (quantity >= 50) {
    return 0.2182;
  } else if (quantity >= 10) {
    return 0.0909;
  } else {
    return 0;
  }
}
export function calculatePrice(price, quantity) {
  const discount = calculateDiscount(quantity);
  const discountedPrice = price * (1 - discount);
  return discountedPrice;
}

export function calculateShippingCost(
  totalQuantity,
  totalDiscountPrice,
  city,
  hasRedemtion = true
) {
  let shippingCost = 0; // Ispravio kako je poskupelo
  let weight = 0.032 * totalQuantity;
  //32 grama tezina jedna kesice
  //OTKUPNINA
  //ima otkupa ?

  if (hasRedemtion) {
    if (totalDiscountPrice < 17999) {
      //baza 180
      if (weight > 0) {
        shippingCost = 180;
      }
    } else {
      //baza 270 rsd + na svaku
      shippingCost = 270;
      shippingCost += 0.015 * (totalDiscountPrice - 18000);
    }
  }
  if (
    city.toLowerCase().includes("belgrade") ||
    city.toLowerCase().includes("beograd")
  ) {
    shippingCost += 60;
  }
  shippingCost += 40; // Ispravio kako je poskupelo

  //NA TEZINU
  if (weight < 0.5) {
    if (weight > 0) {
      shippingCost += 378;
    }
  } else if (weight < 1) {
    shippingCost += 442;
  } else if (weight < 2) {
    shippingCost += 529;
  } else if (weight < 5) {
    shippingCost += 529;
  } else if (weight < 10) {
    shippingCost += 882;
  } else if (weight < 15) {
    shippingCost += 1134;
  } else if (weight < 20) {
    shippingCost += 1260;
  } else if (weight < 30) {
    shippingCost += 1450;
  } else {
    // za 50KG
    shippingCost += 1954;
  }
  return Math.round(shippingCost);
}
