import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIService from '../services/APIService'; // Pretpostavljajući da ovo postoji
import Footer from '../layouts/footer/Footer';
import MainNavbar from '../layouts/header/MainNavbar';
import styled from 'styled-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigation } from '../utils/navigation';

const Container = styled.div`
  display:flex;
  align-content: center;
  justify-content: center;
  padding-top: 100px;
  min-height: calc(75vh - 70px);
`
const Wrapper = styled.div`

  width:30%;
  @media only screen and (max-width:768px){
    width:90%;
  }
`
const Title = styled.h1`
  padding:0;
  font-size: 22px;
  line-height: 1.4;
  font-weight: 700;
  color: #041e42;
  display:flex;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: "Righteous";
`
const Text = styled.h4`
  font-weight: 600;
  text-align:center;
  color: #6e6e6e;
`
const Input = styled.input`
  display: block;
  height:8px;
  margin:4px 0px;
  
`
const Button = styled.button`
  width:50%;
  text-transform: uppercase;
  

`
const PasswordRules = styled.div`
  /* Add password rules box styles here */
  background-color: #f2f2f2;
  padding: 20px;
  margin-top: 10px;
  border-left: 5px solid red; /* Change color based on password strength */
  width:70%;
`;

const Rule = styled.div`
  font-weight:700;
  font-size:16px;
  color: ${props => (props.isValid ? '#4cae04' : '#991f39')};
  margin: 10px 0;
  display: flex;
  align-items: center;

  &::before {
    content: '${props => (props.isValid ? '' : '❌')}';
    margin-right: 10px;
  }
`;
const Form = styled.form`
  
`
const StrengthContainer = styled.div`
  display: flex;
  align-items: flex-start;
  
`;
const StrengthText = styled.p`
  font-weight: bold;
  color: ${props => props.strengthColor};
  margin:0;
  padding:0;
`;

function PasswordReset() {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  let {goToLogin} = useNavigation();
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });
  const { uid, token } = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [errorPasswordText2, setErrorPasswordText2] = useState('');

  const validatePassword = (password) => {
    const validations = {
      minLength: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/.test(password),
    };
    setPasswordValidations(validations);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ovde šaljete zahtev na backend sa novom lozinkom, UID-om i tokenom
    if(newPassword === confirmPassword && passwordValidations.minLength && atLeastThreeOtherRulesPassed()){
    try {
      const response = await APIService.ResetPassword(uid, token, newPassword);
      // Obrada uspešnog zahteva
      goToLogin()
    } catch (error) {
      // Obrada grešaka
    }
  }
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    validatePassword(password);
  };



  const getPasswordStrengthText = (validations, t) => {
    //const { t } = useTranslation(); // Ensure you're using this hook at the top level of your component
    const passedRules = Object.values(validations).filter(Boolean).length;
    const totalRules = Object.keys(validations).length;
    const strength = passedRules / totalRules;
  
    if (strength === 1) {
      return t('PasswordReset.PASSWORD_STRENGTH_STRONG');
    } else if (strength >= 0.75) {
      return t('PasswordReset.PASSWORD_STRENGTH_GOOD');
    } else if (strength >= 0.5) {
      return t('PasswordReset.PASSWORD_STRENGTH_FAIR');
    } else {
      return t('PasswordReset.PASSWORD_STRENGTH_WEAK');
    }
  };
  
  const getPasswordStrengthColor = (strengthText, t) => {
    // Use the translated text to determine the color
    switch (strengthText) {
      case t('PasswordReset.PASSWORD_STRENGTH_STRONG'):
        return '#4cae04';
      case t('PasswordReset.PASSWORD_STRENGTH_GOOD'):
        return '#4cae04';
      case t('PasswordReset.PASSWORD_STRENGTH_FAIR'):
        return '#991f39';
      default:
        return '#991f39';
    }
  };
  
  const atLeastThreeOtherRulesPassed = () => {
    const { uppercase, lowercase, number, specialChar } = passwordValidations;
    const validations = [uppercase, lowercase, number, specialChar];
    
    return validations.filter(Boolean).length >= 3;

  };
  useEffect(()=>{
    if(confirmPassword !== newPassword){
        setErrorPasswordText2(t('Register.PASSWORD_DO_NOT_MATCH'))
    }else{
        setErrorPasswordText2('')
        
    }
    
},[confirmPassword,newPassword])
  
  // Inside your PasswordResetForm component
  const strengthText = getPasswordStrengthText(passwordValidations, t);
const strengthColor = getPasswordStrengthColor(strengthText, t);

  return (
    <div>
      <MainNavbar/>
    <Container>
    <Wrapper>
    <Title>{t('PasswordReset.PASSWORD_RESET_TITLE')}</Title>
      <Text>{t('PasswordReset.PASSWORD_INSTRUCTIONS')}</Text>
    <form onSubmit={handleSubmit} className='form1' >
    <label className='login-label'>{t('PasswordReset.CREATE_PASSWORD_LABEL')}</label>
    <div className='login-input-container' style={{position:"relative"}}>
    <div style={{position:"absolute", right:"0%", top:"10px"}} >
                {isVisible ? (
                <Visibility onClick={() => setIsVisible(!isVisible)} />
            ) : (
                <VisibilityOff onClick={() => setIsVisible(!isVisible)} />
            )}
    </div>
    <Input
          className='input1'
          style={{width:"100%"}}
          type={isVisible ? "text" : "password"}
          value={newPassword}
          onChange={handlePasswordChange} 
          placeholder="Enter new password"
          required
        />
    </div>
    <StrengthContainer>
    <StrengthText strengthColor='#1f1f1f'>
    {t('PasswordReset.PASSWORD_STRENGTH')}
    </StrengthText>
    <StrengthText strengthColor={strengthColor}>{strengthText}</StrengthText>
    </StrengthContainer>
    {newPassword && (!passwordValidations.minLength || !atLeastThreeOtherRulesPassed()) && (
          <PasswordRules>
            <StrengthText strengthColor='#1f1f1f'>
              {t('PasswordReset.RULES_MIN_LENGTH')}
            </StrengthText>
            <Rule isValid={passwordValidations.minLength}>
              {t('PasswordReset.RULES_MIN_LENGTH')}
            </Rule>
            <StrengthText strengthColor='#1f1f1f'>
              {t('PasswordReset.RULES_OPTIONS')}
            </StrengthText>
            <Rule isValid={passwordValidations.uppercase}>
              {t('PasswordReset.RULES_UPPERCASE')}
            </Rule>
            <Rule isValid={passwordValidations.lowercase}>
              {t('PasswordReset.RULES_LOWERCASE')}
            </Rule>
            <Rule isValid={passwordValidations.number}>
              {t('PasswordReset.RULES_NUMBER')}
            </Rule>
            <Rule isValid={passwordValidations.specialChar}>
              {t('PasswordReset.RULES_SPECIAL_CHAR')}
            </Rule>
      </PasswordRules>
  )}
  <br />
  <label className='login-label'
                >{t('PasswordReset.REENTER_PASSWORD_LABEL')} </label>
  <div className='login-input-container' style={{position:"relative"}}>
            <div style={{position:"absolute", right:"0%", top:"10px"}} >
                {isVisible2 ? (
                <Visibility onClick={() => setIsVisible2(!isVisible2)} />
            ) : (
                <VisibilityOff onClick={() => setIsVisible2(!isVisible2)} />
            )}
            </div>
        <Input
          className='input1'
          style={{height:"8px", width:"100%"}}
          type={isVisible2 ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          required
        />
        <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"14px", fontWeight:'bold'}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorPasswordText2 !== '' ? 1 : 0, y: errorPasswordText2 !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorPasswordText2}</motion.h3>
         </div>
         <Text>
         {t('PasswordReset.SIGN_IN_REMINDER')}
         </Text>
        <Button className='login-form-button' disabled={(!(passwordValidations.minLength && atLeastThreeOtherRulesPassed()))} type="submit">{t('PasswordReset.SUBMIT_BUTTON')}</Button>
    </form>
    </Wrapper>
    </Container>
    <Footer/>
    </div>
  );
}

export default PasswordReset;
