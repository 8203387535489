import React, { Suspense, useContext, useEffect, useState } from "react";
import Product from "./pages/Product";
import Home from "./pages/Home";
import Shop from "./pages/Shop";
import ShopSearch from "./pages/ShopSearch";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import AgeRestriction from "./pages/AgeRestriction";
import Proba from "./components/Proba";
import Basket from "./pages/Basket";
import { useSelector } from "react-redux";
import Checkout from "./pages/Checkout";
import Error404 from "./pages/Error404";
import PurchaseConformation from "./pages/PurchaseConformation";

import { AgeProvider } from "./context/AgeContext";
import { ProductContext } from "./context/ProductContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { CartProvider } from "./context/CartContext";
import { SliderProvider } from "./context/SliderContext";
import { AuthUserContext, AuthUserProvider } from "./context/AuthUserContext";

import ReactGA from "react-ga4";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Loading from "./components/Loading";
import PurchaseConfirmation from "./pages/PurchaseConformation";
import Customer from "./pages/Customer";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Places from "./components/customer/Places";

const App = () => {
  let { loadData } = useContext(ProductContext);
  const [ageConfirmation, setAgeConfirmation] = useState(
    localStorage.getItem("ageConfirmed") ? false : true
  );

  useEffect(() => {
    loadData();
    ReactGA.initialize("G-MS03CC19QQ", { debug: true });
    //ReactGA.pageview(window.location.href + window.location.search)
    ReactGA.send({
      hitType: "pageview",
      page: window.location.href + window.location.search,
    });
  }, []);

  function ProtectedRoute() {
    const navigate = useNavigate();
    const { user } = useContext(AuthUserContext);

    useEffect(() => {
      if (!user) {
        // Ako korisnik nije prijavljen, preusmerite na odgovarajuću putanju (na primer, stranicu za prijavljivanje)
        navigate("/prijava");
      }
    }, [user, navigate]);

    return <Customer />;
  }

  return (
    <>
      {ageConfirmation && <AgeRestriction />}
      <BrowserRouter>
        <AuthUserProvider>
          <CartProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <SliderProvider>
                    <Home />
                  </SliderProvider>
                }
              ></Route>
              <Route
                path="/kuca"
                element={
                  <SliderProvider>
                    <Home />
                  </SliderProvider>
                }
              ></Route>
              <Route path="/korpa" element={<Basket />}></Route>
              <Route path="/vs" element={<PurchaseConfirmation />}></Route>
              <Route path="/prijava" element={<Login />}></Route>
              <Route
                path="/zaboravio-lozinku"
                element={<ForgotPassword />}
              ></Route>
              <Route
                path="/reset-password/:uid/:token/"
                element={<ResetPassword />}
              />
              <Route
                path="/politika-privatnosti"
                element={<PrivacyPolicy />}
              ></Route>
              <Route path="/registracija" element={<Register />}></Route>
              <Route
                path="/narudzbina/registracija"
                element={<Register />}
              ></Route>
              <Route path="/search" element={<ShopSearch />}></Route>
              <Route path="/snus-prodaja" element={<Shop />}></Route>
              <Route path="/snus-prodaja/:slug" element={<Shop />}></Route>
              <Route path="/:slug" element={<Product />}></Route>
              <Route
                path="/narudzbina"
                element={
                  <>
                    <CheckoutProvider>
                      <Checkout />
                    </CheckoutProvider>
                  </>
                }
              ></Route>

              <Route path="/places" element={<Places />}></Route>
              <Route path="*" element={<Error404 />}></Route>
              {/* PROTECTED ROUTES */}
              <Route
                path="/kupac/:details"
                element={<ProtectedRoute />}
              ></Route>
            </Routes>
          </CartProvider>
        </AuthUserProvider>
      </BrowserRouter>
    </>
  );
};

//export default App;

export default function WrappedApp() {
  return (
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
}
