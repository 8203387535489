import axios from "axios";
import ReactGA from "react-ga4";

export default class APIService {
  //static URL = "http://127.0.0.1:8000/";
  static URL = "https://snus-back.herokuapp.com/";

  static async GetArticles() {
    const response = await fetch(APIService.URL + "api/articles/", {
      method: "GET",
      headers: {
        "Content-Type": "aplication/json",
      },
    });
    return await response.json();
  }

  static async GetArticle(article_id) {
    const response = await fetch(
      APIService.URL + `api/article/${article_id}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "aplication/json",
          //'Authorization':'Token 9b85c3784a75fa85e8ae3fc58ca69ff9136af186'
        },
      }
    );
    return await response.json();
  }

  static async GetArticlesByCategoryId(category_id) {
    const response = await fetch(
      APIService.URL + `api/category/${category_id}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "aplication/json",
          //'Authorization':'Token 9b85c3784a75fa85e8ae3fc58ca69ff9136af186'
        },
      }
    );
    return await response.json();
  }

  static async GetArticlesByNicotineIntensity(strenght) {
    const response = await fetch(APIService.URL + `api/strenght/${strenght}/`, {
      method: "GET",
      headers: {
        "Content-Type": "aplication/json",
      },
    });
    return await response.json();
  }
  static async GetRecommended() {
    const response = await fetch(APIService.URL + "api/recommended/", {
      method: "GET",
      headers: {
        "Content-Type": "aplication/json",
      },
    });
    return await response.json();
  }

  static async getRecommendedByProductId(id) {
    try {
      const response = await axios.get(
        APIService.URL + `api/recommended-products/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  static async searchArticles(
    query = "",
    sortBy = "nicotine",
    nicotineLevels = [],
    category = []
  ) {
    const searchQuery = query ? `${query}/` : "";

    const params = new URLSearchParams();
    params.set("sort_by", sortBy);
    if (nicotineLevels.length) {
      params.set("nicotine_levels", nicotineLevels.join(","));
    }
    if (category != null && category.length) {
      params.set("category", category.join(","));
    }
    const url =
      APIService.URL + `api/search/${searchQuery}?${params.toString()}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  }

  static async checkout(
    name,
    surname,
    email,
    address,
    phone,
    note,
    articles,
    city,
    postalCode
  ) {
    const options = {
      method: "POST",
      url: APIService.URL + "api/checkout/",
      headers: {
        //Authorization: 'Token 9b85c3784a75fa85e8ae3fc58ca69ff9136af186',
        "Content-Type": "application/json",
      },
      data: {
        customer_name: name,
        customer_surname: surname,
        customer_email: email,
        customer_address: address,
        customer_phone: phone,
        city: city,
        postal_code: postalCode,
        customer_note: note,
        articles: articles,
      },
    };
    try {
      const response = await axios(options);

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  static async ArticleNotification(article_id, email, user_id = null) {
    try {
      let data = {
        article_id: article_id,
        email: email,
      };
      if (user_id !== null) {
        data.user_id = user_id;
      }
      const response = await axios.post(
        APIService.URL + "api/article-notification/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        ReactGA.event({
          category: "obavestenje",
          action: "pretplataProizvod",
          label: "Korisnik se pretplatio za proizvod",
        });
      } catch (error) {
        console.error("Greška prilikom slanja događaja:", error);
      }

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async Login(email, password = null) {
    try {
      let data = {
        email: email,
      };
      if (password !== null) {
        data.password = password;
      }
      const response = await axios.post(APIService.URL + "api/login/", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      try {
        ReactGA.set({ userId: response.data.customer_id });
        ReactGA.event({
          category: "korisnik",
          action: "prijava",
          label: "Korisnik prijavljen",
        });
      } catch (error) {
        console.error("Greška prilikom slanja događaja:", error);
      }
      //});

      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async Register(
    name,
    surname,
    email,
    address1,
    city,
    postal_code,
    phone,
    houseNumber = undefined,
    address2 = undefined,
    password = undefined
  ) {
    try {
      let data = {
        first_name: name,
        last_name: surname,
        email: email,
        address: address1,
        city: city,
        postal_code: postal_code,
        phone: phone,
      };
      if (houseNumber !== undefined) {
        data.house_number = houseNumber;
      }
      if (address2 !== undefined) {
        data.address2 = address2;
      }
      if (password !== undefined) {
        data.password = password;
      }
      const response = await axios.post(
        APIService.URL + "api/register/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        ReactGA.event({
          category: "korisnik",
          action: "registracija",
          label: "Novi korisnik",
        });
      } catch (error) {
        console.error("Greška prilikom slanja događaja:", error);
      }

      return response.data;
    } catch (error) {
      try {
        ReactGA.event({
          category: "korisnik",
          action: "registracijaNeuspesna",
          label: "Novi korisnik",
        });
      } catch (error) {
        console.error("Greška prilikom slanja događaja:", error);
      }

      throw error;
    }
  }

  static async orderHistory(customer_id) {
    const options = {
      method: "POST",
      url: APIService.URL + "api/order-history/",
      headers: {
        //Authorization: 'Token 9b85c3784a75fa85e8ae3fc58ca69ff9136af186',
        "Content-Type": "application/json",
      },
      data: {
        customer_id: customer_id,
      },
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  static async putCustomer(customer_id, data) {
    const options = {
      method: "PUT",
      url: APIService.URL + "api/customer-update/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        customer_id: customer_id,
        ...data,
      },
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  //Resetovanje Passworda
  static async ResetPasswordRequest(email) {
    try {
      const response = await axios.post(
        this.URL + "api/password-reset-request/",
        { email }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  static async ResetPassword(uid, token, newPassword) {
    try {
      const response = await axios.post(
        this.URL + `api/password-reset-confirm/${uid}/${token}/`,
        {
          password: newPassword,
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
