import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "../assets/css/HomeSlider.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigation } from "../utils/navigation";
import {home_slider} from "../data"
import { useTranslation } from "react-i18next";

const Container = styled.div`
  max-width: 100vw;
  overflow: hidden;
  overflow-x:hidden;
  //min-height: 70vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media only screen and (min-width:769px){
      max-height:60vh;
  }
`;
const Slide = styled.div`
  //padding-top:70px;
  background-color: ${props => props.bg};
  //height: 65vh;
  display: flex !important;
  align-items: center;
  max-width: 100vw;
  padding:0px;
  flex-shrink: 0;
  @media only screen and (max-width:768px){
      flex-direction: column;
      min-height:50vh;
    }
    
`;

const ImgContainer = styled.div`
  flex: 1;
  display:flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width:50vw;
  @media only screen and (max-width:768px){
      width: 100vw;
      height:25vh;
    }
    
`;

const Image = styled.img`
  width:100%;
  height:auto;
  margin:0 auto;
  //object-fit: cover;
  @media only screen and (min-width:768px){
    min-width:50vw;
  }
  @media only screen and (max-width:769px){
    max-width:100vw;
    max-height:40vh;
}
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  padding: 20px;
  height: 100vh;
  width:50vw;
  &.slick-slide{
    height:100%;
    max-height: 100vh;
  }
  @media only screen and (max-width:768px){
      width: 100vw;
      height:100%;
      //height: 100vw;
      &.slick-slide{
        max-height: auto;
      }
  }
`;


const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: 300;
  text-transform: uppercase;
  //letter-spacing: 1.6px;
  padding:0;
  margin:0;
  color: ${props => props.color};
  font-family: "Righteous";
`;

const Description = styled.p`

  font-size: 16px;
  margin-bottom: 30px;
  color: ${props => props.color};
  font-weight: 100;
  text-align: left;
  height:140px;
  font-family: "Righteous";
  @media only screen and (max-width:768px){
      max-width: 80vw;
    }
`;

const Button = styled.button`
    border-radius: 100px;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    transition: .6s all cubic-bezier(.25,1,.5,1);
    max-width: 240px;
    width: 240px;
    margin: 0;
    margin-bottom:10px;
    padding: 8px 12px;
    color: #fff;
    background-color: ${props => props.background_color};
    border: 2px solid ${props => props.background_color};
    &:hover{
        background-color: teal;
        border: 2px solid teal;
    }
`;

const PrevArrow = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  //background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;
const NextArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;





const HomeSlider = () => {
    const { t, i18n } = useTranslation();
    const {goToShop} = useNavigation();
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true,
        autoplaySpeed: 10000, //10s
        variableWidth: false, // ova opcija postavlja sve slajdove na istu širinu
        variableHeight:false,
        initialSlide:1,
      };
  return (
    <div>
    <Container>
    <Slider {...settings}>
      {home_slider.map((item) => (
        <Slide bg={item.bg} key={item.id}>
        <ImgContainer>
          <Image src={item.img} alt={item.title} loading="lazy" />
        </ImgContainer>
        <InfoContainer>
            <Title color={item.textColor} >{i18n.language === 'sr' ? item.title : item.titleEN}</Title>
            <Description color={item.textColor}>{i18n.language === 'sr' ? item.desc : item.descEN}</Description>
            <Button background_color={item.textColor} onClick={()=>goToShop()}>{t('SHOP')}</Button>
        </InfoContainer>
        </Slide>
        ))}
     </Slider>
     </Container>
     </div>
);
};
export default HomeSlider;
