import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import "../assets/css/RecommendedShopSlider.css"
import ShopProduct from "./ShopProduct";
import { CallMade } from "@mui/icons-material";
import { useNavigation } from "../utils/navigation";
import { useTranslation } from "react-i18next";

const Component = styled.div`
`;
const SlideContainer = styled.div`
    padding-bottom:40px;
`;
const TitleDiv = styled.div`
padding:40px;
background-color: teal;
display: flex;
align-items: center;
justify-content: space-between;
@media only screen and (max-width:768px){
  padding:15px;
  flex-direction: column;
}
`;
const Title = styled.h1`
    color:white;
    padding:0;
    font-size: 40px;
    font-family: "Righteous";
    font-weight: 100;
    text-transform: uppercase;
    margin:0;
    @media only screen and (max-width:768px){
        margin: 20px 0px;
        font-size: 26px;
        text-align: center;
        white-space: nowrap;
    }
`;
const Button = styled.button`
    line-height: 1.25;
    letter-spacing: 0;
    font-weight: 700;
    color: white;
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    text-align: left;
    margin: 0;
    background-color: transparent;
    border:none;
    padding:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
`;
const Icon = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ rotated }) => (rotated ? 'rotate(45deg)' : 'rotate(90deg)')};
`;

const PrevArrow = styled.div`
  position: absolute;
  top: 95%;
  left: 10px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  //background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;
const NextArrow = styled.div`
  position: absolute;
  top: 95%;
  right: 10px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;

const RecommendedShopSlider = ({sliderContent}) => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [rotated, setRotated] = useState(false);
  const {goToShop} = useNavigation();
  const settings = {
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Component>
      <TitleDiv>
        <Title>{t('RecommendedShopSlider.RECOMMENDED_PRODUCTS_TITLE')}</Title>
        <Button onMouseEnter={() => setRotated(true)}
          onMouseOut={() => setRotated(false)}
          onClick={() => goToShop()}
          >{t('RecommendedShopSlider.SHOP_BUTTON_TEXT')} <p style={{color:"#f4d35e", margin:"0 5px"}}>{t('RecommendedShopSlider.SEE_ALL_TEXT')}</p>
            <Icon rotated={rotated} style={{color:"#f4d35e"}} >
              <CallMade/>
            </Icon>
          </Button>
        </TitleDiv>
      {isLoaded && (
        <Slider {...settings}>
           {sliderContent.map((item,index) => (
          <SlideContainer key={item.id}>
             <ShopProduct item={item} /> 
             
          </SlideContainer>
        ))}
           
        </Slider>
      )}
    </Component>
  );
};

export default RecommendedShopSlider;