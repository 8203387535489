import styled , {keyframes, css} from "styled-components";
import { useState, useEffect, useContext } from 'react';
import { Search, Close, CallMade } from "@mui/icons-material";
import APIService from '../../services/APIService'
import {useNavigate} from 'react-router-dom'
import { useNavigation } from "../../utils/navigation";
import { ProductContext } from "../../context/ProductContext";
import { useTranslation } from "react-i18next";
const fadeInTop = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const fadeOutBottom = keyframes`
  from {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const ContainerX = styled.div`

`;

const ContainerTitle = styled.h2`
    padding:0;
    font-size: 12px;
    font-weight: 400;
    color: white;
    margin:0;
`;
const ContainerLink = styled.div`
margin: 0;
display:flex;
align-items: center;
justify-content: center;  
cursor: pointer;
&:hover{
  color: #52c5c5;;
}
flex-direction: column;
`;
const Container = styled.div`
${({ isOpen }) => isOpen
  ? css`
    display: none;
  `
  : css`
   display:block;
  `}

  color: black;
  padding: 0;
  border: 0;
  outline: none;
  position:fixed;
  top: 0px;
bottom: 0;
left: 0;
right: 0;

  width: 100vw;
  font-weight: 700;
  height:100vh;
  background-color: rgba(0, 0, 0, 0.3);
  @media only screen and (max-width:768px){
  }
  z-index:99999;
`;
const Box = styled.div`
overflow: auto; /* omogućava skrolanje unutar div-a */
-webkit-overflow-scrolling: touch;
${({ isOpen }) => isOpen
  ? css`
    animation: ${fadeOutBottom} 0.5s ease-in-out both;
  `
  : css`
    animation: ${fadeInTop} 0.5s ease-in-out both;
  `}
  
  width: 25%;
  //margin:0;
  background-color: teal;
  height:80vh;
  padding:0px;
  @media only screen and (max-width:768px){
      height:70vh;
      width:90vw;
  }
  top:0;
  padding:20px;
`;



const TopContainer = styled.div`
    position:absolute;
    width:90%;
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:column;
    padding-bottom:30px;
    border-bottom:2px solid white;
    background-color: teal;
    top:0;
    padding-top:20px;
    height: 100px;
    z-index: 2;

`;
const Exit = styled.div`
    position:absolute;
    top:20px;
    left:20px;
`;
const Logo  = styled.h1`
    font-weight: 100;
    font-size: 40px;
    letter-spacing: 1px;
    margin:0;
    padding:0;
    margin-bottom: 10px;
    font-family: "Righteous";
`;

const LogoImg = styled.img`
    width:80px;
`;
const SearchContainer = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    padding:5px 0px;
    width:100%;
    border-radius: 10px;
    background-color: white;
`;
const SearchInput = styled.input`
    width: 100%;
    border:none;
    outline:none;
    
    ::placeholder {
        font-size: 12px;
        font-weight: 400;
        color: #00000086;
  }
    font-size: 14px;
    font-weight: 500;
    color:black;
    background:none;
`;


const BottomContainer = styled.div`
    overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  margin-top:120px;
  z-index: 1;
`;
const BottomTitle = styled.h1`
    font-weight: 100;
    font-size: 30px;
    letter-spacing: 0px;
    margin:0;
    padding:0;
    margin: 30px 0px;
    text-transform: uppercase;
    font-family: "Righteous";
    color: #fff;
`;
const ProductContainer = styled.div`
    display: flex;
    background-color: #009b9b;
    height:90px;
    border-radius:10px;
    overflow: hidden;
    cursor:pointer;
    &:hover{
        border: 2px solid #009b9b;
    }
    &:before {
        border: 2px solid transparent;
    }
    &hover:before{
        border-color:#009b9b;
    }
    img {
    transition: transform 0.3s ease-out;
    }
  &:hover img {
    transform: scale(1.1);
  }
    cursor:pointer;
    margin:10px 0px;
`;
const ProductImageContainer = styled.div`
    flex:1;
    margin:5px;
    
`;
const ProductPicture = styled.picture`
    width: 100%;
    height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
    border-radius: 10px;
  
`;
const ProductImage = styled.img`
    height: 70px;
    //width: 70px;
`;
const ProductInfoContainer =styled.div`
    flex:2;
    padding-left:5px;
    

`;
const ProductTitle = styled.h1`
    margin:0;
    padding-top:10px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 100;
    color: #fff;
    display:flex;
    align-items: left;
    text-transform:uppercase;
    margin-bottom: 10px;
    font-family: "Righteous";
`;
const ProductPrice = styled.h2`
    margin:0;
    padding:0px;
    font-size: 14px;
    line-height: 1.0;
    font-weight: 700;
    color: #e2e2e2;
    display:flex;
    text-transform:uppercase;
    margin-right:20px;
`;
const Button = styled.button`
    line-height: 1.25;
    letter-spacing: 0;
    font-weight: 700;
    color: white;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    text-align: left;
    margin: 0;
    background-color: transparent;
    border:none;
    padding:10px;
    display:flex;
    justify-content: center;
    align-items: center;
`;
const Icon = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ rotated }) => (rotated ? 'rotate(45deg)' : 'rotate(0)')};
`;

const SearchMenu = () => {
  const { t } = useTranslation();
  const [rotated, setRotated] = useState(false);
  const [articles, setArticles] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText !== '') {
      const delayDebounceFn = setTimeout(() => {
        APIService.searchArticles(searchText, 'title', [], null).then((resp) => {
          setArticles(resp);
        });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      APIService.GetArticles().then((resp) => {
        setArticles(resp.slice(0, 4));
      });
    }
  }, [searchText]);

  const [isOpenContainer, setIsOpenContainer] = useState(true);
  const [isOpenBox, setIsOpenBox] = useState(true);
  const [isBoxAnimating, setIsBoxAnimating] = useState(false);

  const toggleBox = () => {
    if (isBoxAnimating) {
      return;
    }
    setIsOpenBox(!isOpenBox);
    setIsBoxAnimating(true);
    if (isOpenContainer === false) {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 500);
    } else {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 10);
    }
  };

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  const navigate = useNavigate();

  const handleSearch = () => {
    const queryParams = new URLSearchParams({
      search: searchText,
    }).toString();
    navigate('/search?' + queryParams);
    window.location.reload();
  };

  const { goToProduct, goToShop } = useNavigation();

  const handleProductCardClick = (category, title) => {
    toggleBox();
    goToProduct(category, title);
  };

  return (
    <ContainerX>
      <ContainerLink onClick={toggleBox}>
        <Search style={{ color: 'white', fontSize: 30 }} />
        <ContainerTitle>{t('SearchMenu.ContainerTitle')}</ContainerTitle>
      </ContainerLink>
      <Container onClick={toggleBox} isOpen={isOpenContainer} isBoxAnimating={isBoxAnimating}>
        <Box isOpen={isOpenBox} isBoxAnimating={isBoxAnimating} onClick={handleStopPropagation}>
          <TopContainer>
            <Exit>
              <Close style={{color:"white"}} onClick={toggleBox} />
            </Exit>
            <LogoImg src={require('../../assets/images/logowhite.png')} />
            <SearchContainer>
              <Search style={{ fontSize: '25px', margin: '0px 20px' }} onClick={handleSearch} />
              <SearchInput
                placeholder={t('SearchMenu.SearchInput.placeholder')}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(event) => {
                  if (event.charCode === 13) {
                    handleSearch();
                  }
                }}
              />
            </SearchContainer>
          </TopContainer>
          <BottomContainer>
            <BottomTitle>{t('SearchMenu.BottomTitle')}</BottomTitle>
            {articles?.map((item, index) => {
              const images = item.images.map((img) => img.image_url);
              const primaryImage = images.find(
                (img) => item.images.find((i) => i.is_primary_image).image_url === img
              );

              return (
                <ProductContainer
                  key={item.id}
                  onClick={() =>
                    handleProductCardClick(
                      item.category.toLowerCase(),
                      item.title.toLowerCase().replace(/ /g, '-'),
                      item.id
                    )
                  }
                >
                  <ProductImageContainer>
                    <ProductPicture>
                      <ProductImage src={primaryImage} alt={item.title} />
                    </ProductPicture>
                  </ProductImageContainer>
                  <ProductInfoContainer>
                    <ProductTitle>{item.category} {item.title}</ProductTitle>
                    <ProductPrice>{item.price} RSD</ProductPrice>
                  </ProductInfoContainer>
                </ProductContainer>
              );
            })}
            <Button
              onMouseEnter={() => setRotated(true)}
              onMouseOut={() => setRotated(false)}
              onClick={() => goToShop()}
            >
              {t('SearchMenu.ButtonText')}
              <Icon rotated={rotated}>
                <CallMade />
              </Icon>
            </Button>
          </BottomContainer>
        </Box>
      </Container>
    </ContainerX>
  );
};

export default SearchMenu;