import styled from "styled-components";
import Announcment from "../components/Announcment";
import Footer from "../layouts/footer/Footer";
import ShopProducts from "../components/ShopProducts";
import { useState, useEffect, useContext } from "react";
import ShopFilterMenu from "../components/ShopFilterMenu";
import DropDown from "../components/DropDown";
import MainNavbar from "../layouts/header/MainNavbar";
import APIService from "../services/APIService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HomeSlider from "../components/HomeSlider";
import { Helmet } from "react-helmet";
import { ProductContext } from "../context/ProductContext";
import { categories } from "../data";
import { useNavigation } from "../utils/navigation";
import Loading from "../components/Loading";
import { CallMade } from "@mui/icons-material";
import NotifyPopUp from "../components/NotifyPopUp";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  background-color: #f1f4f8;
  @media only screen and (max-width: 768px) {
    background-color: #fff;
  }
`;
const Title = styled.h1`
  padding: 20px 60px;
  font-size: 40px;
  font-family: "Righteous";
  font-weight: 100;
  text-transform: uppercase;
  margin: 0;
  @media only screen and (max-width: 768px) {
    padding: 20px 0;
    text-align: center;
  }
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 60px;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin: 0;
    padding: 20px;
    justify-content: space-around;
  }
  position: relative;
`;
const FilterStickyContainer = styled(FilterContainer)`
  position: sticky;
  top: 70px;
  background-color: #fff;
  z-index: 99;
  @media only screen and (min-width: 768px) {
    padding: 20px;
    background-color: #f1f4f8;
  }
`;
const Filter = styled.div`
  max-width: 60vw;
  @media only screen and (max-width: 768px) {
    max-width: none;
  }
`;

const FilterNicotineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const FilterText = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  display: block;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
`;
const FilterDiv = styled.div`
  display: block;
  font-size: 0;
  flex: 0 1 auto;
  width: auto;
  text-align: center;
  @media only screen and (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;
const FilterLabel = styled.label`
  min-width: 100px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 5px 5px;
  border: 2px solid #39d4d4;
  background: #fff;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    min-width: 100px;
    margin: 0 2px 2px;
  }
`;
const LabelTitle = styled.div`
  display: inline-block;
`;
const LabelInput = styled.input`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0;
  margin: 4px 20rem 0 0;
  padding: 0;
  background: none;
  outline: none;
  border: 2px solid #041e42;
  display: none;
`;
const MobileTitleNone = styled.div`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const Button = styled.button`
  line-height: 1.25;
  letter-spacing: 0;
  font-weight: 700;
  color: black;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  margin: 0;
  background-color: transparent;
  border: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: rotate(90deg);
`;

const Shop = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const { goToNotFound } = useNavigation();
  const [categorySEO, setCategorySEO] = useState();
  const { goToShop } = useNavigation();

  const [isActive, setIsActive] = useState(false);
  const [nicotineCounts, setNicotineCounts] = useState({});
  const [nicotineValues, setNicotineValues] = useState([]);
  const [categoryCounts, setCategoryCounts] = useState({});
  const [categoryValues, setCategoryValues] = useState([]);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [categorySlug, setCategorySlug] = useState(false);
  let {
    setChosenProducts,
    setProducts,
    products,
    fetchData,
    handleNicotineChange,
    handleCategoryChange,
    nicotine,
    category,
    getProductsByCategoryId,
    loading,
    chosenProducts,
    popUp,
  } = useContext(ProductContext);

  const [loadingShop, setLoadingShop] = useState(false);
  const handleButtonClick = (event) => {
    event.stopPropagation();
    setIsActive(!isActive);
  };
  const handleClick = () => {
    setIsActive(false);
  };

  useEffect(() => {
    setProducts([]);
    if (slug) {
      setCategorySlug(true);
      const foundCategoryInfo = categories.find(
        (a) => a.title.toLowerCase().replace(/\s+/g, "-") === slug.toLowerCase()
      );
      if (foundCategoryInfo) {
        const waitData = async () => {
          await getProductsByCategoryId(foundCategoryInfo.id);
          setLoadedOnce(false);
        };
        waitData();

        setCategorySEO(foundCategoryInfo);
      } else {
        goToNotFound();
      }
    } else {
      fetchData();
    }
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => {
    setChosenProducts(products);
    if (!loadedOnce && products.length) {
      const counts = {};
      products.forEach((item) => {
        const nicotineItem = item.nicotine;
        if (counts[nicotineItem] === undefined) {
          counts[nicotineItem] = 1;
        } else {
          counts[nicotineItem]++;
        }
      });
      setNicotineCounts(counts);
      setNicotineValues(Object.keys(counts));

      const counts_category = {};
      products.forEach((item) => {
        const categoryItem = item.category;
        if (counts_category[categoryItem] === undefined) {
          counts_category[categoryItem] = 1;
        } else {
          counts_category[categoryItem]++;
        }
      });

      setCategoryCounts(counts_category);
      setCategoryValues(Object.keys(counts_category));
      setLoadedOnce(true);
    }
  }, [loadedOnce, products]);
  return (
    <Container onClick={handleClick}>
      {!categorySEO ? (
        <Helmet>
          <title>Snus Prodaja | Snus Srbija</title>
          <meta property="og:title" content="Snus Prodaja | Snus Srbija" />
          <meta
            name="description"
            content="Pogledajte našu ponudu snus proizvoda u našoj radnji i pronađite svoj omiljeni ukus. Narucite iz Beograda, Novog Sada ili Subotice online kupovina."
          />
          <meta
            property="og:description"
            content="Pogledajte našu ponudu snus proizvoda u našoj radnji i pronađite svoj omiljeni ukus. Narucite iz Beograda, Novog Sada, Subotice online kupovina."
          />
          <meta
            property="og:image"
            content={require("../assets/images/shopCover.webp")}
          />
          <link rel="canonical" href="https://www.snussrbija.com/radnja" />
          <meta
            name="keywords"
            content="snus prodaja, snus Srbija, snus online kupovina, snus prodavnica"
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>{categorySEO.title} PROIZVODI | Snus Srbija</title>
          <meta
            property="og:title"
            content={`${categorySEO.title} PROIZVODI | Snus Srbija`}
          />
          <meta name="description" content={categorySEO.meta_description} />
          <meta
            property="og:description"
            content="Pogledajte našu ponudu snus proizvoda u našoj radnji i pronađite svoj omiljeni ukus. Narucite iz Beograda, Novog Sada, Subotice online kupovina."
          />
          <meta property="og:image" content={categorySEO.img} />
          <link
            rel="canonical"
            href={`https://www.snussrbija.com/radnja/snus-prodaja/${categorySEO.title.toLowerCase()}`}
          />
          <meta
            name="keywords"
            content={`${categorySEO.title} , ${categorySEO.keywords} `}
          />
        </Helmet>
      )}
      {popUp && <NotifyPopUp />}
      <MainNavbar />
      <Announcment />
      <HomeSlider />
      <Title>{t("Shop.SHOP_TITLE")}</Title>
      <FilterStickyContainer>
        <Filter onClick={handleButtonClick}>
          <DropDown isActive={isActive} />
        </Filter>
        <Filter>
          <ShopFilterMenu
            nicotineValues={nicotineValues}
            nicotineCounts={nicotineCounts}
            categoryCounts={categoryCounts}
            categoryValues={categoryValues}
          />
        </Filter>
      </FilterStickyContainer>
      <FilterContainer>
        <Filter>
          <FilterNicotineWrapper>
            {loading && <Loading />}
            <MobileTitleNone>
              {categorySlug ? (
                <FilterText>{t("Shop.SELECT_NICOTINE_STRENGTH")}</FilterText>
              ) : (
                <FilterText>{t("Shop.SELECT_CATEGORY")}</FilterText>
              )}
            </MobileTitleNone>
            {categorySlug ? (
              <FilterDiv>
                {nicotineValues.map((item, index) => {
                  const checked = nicotine.includes(item);
                  const color = checked ? "white" : "black";
                  const backgroundColor = checked ? "teal" : "white";

                  return (
                    <FilterLabel key={index} style={{ color, backgroundColor }}>
                      <LabelTitle>{item}MG</LabelTitle>
                      <LabelInput
                        type="checkbox"
                        checked={checked}
                        onChange={() => handleNicotineChange(item)}
                      />
                    </FilterLabel>
                  );
                })}
              </FilterDiv>
            ) : (
              <FilterDiv>
                {categoryValues.map((item, index) => {
                  const checked = category.includes(item);
                  const color = checked ? "white" : "black";
                  const backgroundColor = checked ? "teal" : "white";

                  return (
                    <FilterLabel key={index} style={{ color, backgroundColor }}>
                      <LabelTitle>{item}</LabelTitle>
                      <LabelInput
                        type="checkbox"
                        checked={checked}
                        onChange={() => handleCategoryChange(item)}
                      />
                    </FilterLabel>
                  );
                })}
              </FilterDiv>
            )}
          </FilterNicotineWrapper>
        </Filter>
      </FilterContainer>

      {slug && (
        <Button onClick={() => goToShop()}>
          {t("Shop.VIEW_ALL_PRODUCTS")}
          <Icon>
            <CallMade />
          </Icon>
        </Button>
      )}
      <ShopProducts articles={chosenProducts} />
      <Footer />
    </Container>
  );
};

export default Shop;
