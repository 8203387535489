import { useContext, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {  Visibility, VisibilityOff } from '@mui/icons-material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MainNavbar from '../layouts/header/MainNavbar';
import Footer from '../layouts/footer/Footer';
import { AuthUserContext } from '../context/AuthUserContext';
import { useNavigation } from '../utils/navigation';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import SubmitAnim from '../components/SubmitAnim';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height:70vh;
`
const ViewAllButton = styled.button`
  line-height: 1.25;
  letter-spacing: 0;
  font-weight: 700;
  color: #1f1f1f;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  margin: 0;
  background-color: transparent;
  border:none;
  display:flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
`;
const Login = () => {
    const { t } = useTranslation();
    const {goToRegistration, goToForgotPassword} = useNavigation()
    const {loginUser, setEmailUser} = useContext(AuthUserContext);
    const [email, setEmail] = useState('');
    const [ErrorEmailText, setErrorEmailText] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [firstLoad, setFirstLoad] = useState(true);
    
    const [password, setPassword] = useState('');
    const [errorPasswordText, setErrorPasswordText] = useState('');
    const [isVisible, setIsVisible] = useState(false);


    const pathURL = window.location.pathname; 

    const [isSubmitting, setIsSubmitting] = useState(false);
    let handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        loginUser(e.target.email.value, e.target.password.value);

        setTimeout(() => {
            setIsSubmitting(false);
          }, 300);
    }

// GOOGLE
    function handleCallbackResponse(response) {
        var userObject = jwt_decode(response.credential)
        localStorage.setItem('emailUser', JSON.stringify({"email": userObject.email, "name":userObject.given_name, "surname":userObject.family_name, "avatar":userObject.picture}))
        setEmailUser({"email": userObject.email, "name":userObject.given_name, "surname":userObject.family_name, "avatar":userObject.picture})
        loginUser(userObject.email);
    }

    useEffect(() => {
    /*global google*/ 
    google.accounts.id.initialize({
        client_id: "289868755464-ifopbs2daeogjlg81ftdp92vgpat5av6.apps.googleusercontent.com",
        callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        { theme: "outlined", size: "large" }
    );
    }, []);
  return (
    <div >
        {pathURL == '/prijava' &&
        <>
        <Helmet>
            <title>Prijavi se | Snus Srbija</title>
            <meta name="description" content="Prijavite se na našu platformu kako biste pristupili svim svojim personalizovanim opcijama. Uđite u svoj nalog i iskoristite sve prednosti koje nudimo. Sigurna i intuitivna prijava." />
            <meta
            property="og:title"
            content="Prijavi se | Snus Srbija"
            />
            <meta
            property="og:description"
            content="Prijavite se na našu platformu kako biste pristupili svim svojim personalizovanim opcijama. Uđite u svoj nalog i iskoristite sve prednosti koje nudimo. Sigurna i intuitivna prijava"
            />
            <link
            rel="canonical"
            href="https://www.snussrbija.com/prijava"
            />
            <meta
            name="keywords"
            content="snus prijava, snus serbia"
            />
      </Helmet>
        <MainNavbar/>
        </>
  }
        <Container>
        <div className="login-form-div">
        {pathURL == '/prijava' ?
         <h1 className='login-title'  style={{marginTop:"100px"}}>{t('Login.LOGIN_TITLE')}</h1>
         :
         <h1 className='login-title'  style={{marginTop:"100px"}}>{t('Login.WELCOME_TO_SECURE_ORDER')}</h1>
        }
        
        <div id="signInDiv" ></div>
        <div className='login-br'>
            <h3 className='login-br-text'> {t('Login.OR_LOGIN')}</h3>
        </div>

            <p className='login-error-note-div'>
            {t('Login.ERROR_MESSAGE')}
            </p>

        
        <form className='form1' onSubmit={e => handleSubmit(e)} >
            
            <label className='login-label'
            >{t('Login.EMAIL_LABEL')}</label>
            <input 
             type="text"
             name='email'
             value={email}
             onChange={e => setEmail(e.target.value)}
             required
             className='input1'
             style={{height:"8px"}}
            
             // style for input when it's selected and empty
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorEmailText('')
            }}
             // style for input when it's not selected or has content
             onBlur={e => {
                 if (e.target.value === '') {
                 e.target.style.borderBottom = '2px solid #991f39';
                 setErrorEmailText(t('Login.REQUIRED_FIELD'))
                 }else if (!emailRegex.test(e.target.value)) {
                    e.target.style.borderBottom = '2px solid #991f39';
                    setErrorEmailText(t('Login.INVALID_EMAIL'))
                }
                 else{
                     e.target.style.borderBottom = '1px solid aqua';
                    setErrorEmailText('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: ErrorEmailText !== '' ? 1 : 0, y: ErrorEmailText !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{ErrorEmailText}</motion.h3>

            <label className='login-label'
                >{t('Login.PASSWORD_LABEL')}</label>
            
            <div className='login-input-container' style={{position:"relative"}}>
            <div style={{position:"absolute", right:"0%", top:"6px"}} >
                {isVisible ? (
                <Visibility onClick={() => setIsVisible(!isVisible)} />
            ) : (
                <VisibilityOff onClick={() => setIsVisible(!isVisible)} />
            )}
            </div>
            <input 
             type={isVisible ? "text" : "password"}
             value={password}
             name='password'
             onChange={e => setPassword(e.target.value)}
             required
             className='input1'
             style={{height:"8px", width:"100%"}}
             // style for input when it's selected and empty
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorPasswordText('')
            }}
             // style for input when it's not selected or has content
             onBlur={e => {
                 if (e.target.value === '') {
                 e.target.style.borderBottom = '2px solid #991f39';
                 setErrorPasswordText(t('Login.REQUIRED_FIELD'))
                 }else{
                     e.target.style.borderBottom = '1px solid aqua';
                     setErrorPasswordText('')
                }
             }}
            />
            </div>
            
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorPasswordText !== '' ? 1 : 0, y: errorPasswordText !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorPasswordText}</motion.h3>
        <button type="submit" className='login-form-button'
        >{ !isSubmitting ? t('Login.CONFIRM_BUTTON') : <SubmitAnim />} </button>
        </form>

        

        <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
        <ViewAllButton 
              onClick={() => goToForgotPassword()}
              >ZABORAVIO SI LOZINKU?
                <div  >
                  <KeyboardDoubleArrowRightIcon style={{color:"#1f1f1f"}} />
                </div>
              </ViewAllButton>
        <h2 className='login-title'
            style={{marginBottom:"0px"}}
            >{t('Login.STARTING_OUT')}</h2>
        
        <button className='login-form-button-reverse'
        style={{marginBottom:"40px"}}
        onClick={pathURL == '/prijava' ?  () => goToRegistration() :  () => goToRegistration()}
        >{t('Login.CREATE_ACCOUNT_BUTTON')}</button>
        </div>
        </div>
      </Container>
      {pathURL == '/prijava' &&
      <Footer/>
            }
    </div>

  )
}

export default Login