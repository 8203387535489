import { useTranslation } from "react-i18next";
import styled from "styled-components"

const Container = styled.div`
    display:flex;
    background-color: teal;
    padding: 40px 0px;
    @media only screen and (max-width:768px){
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

`;
const First = styled.div`
    flex:1;
    text-align:left;
    padding:20px 0px 20px 20px;
    @media only screen and (max-width:768px){
        text-align: center;
        padding:0px;
    }
`;
const Title = styled.h1`
    color: #fff;
    text-align: left;
    font-family: "Righteous";
    font-weight: 100;
    text-transform: uppercase;
    font-size: 40px;
    margin:0;
    padding:0;
    @media only screen and (max-width:768px){
      font-size:34px;
      text-align: center;
    }
`;
const HighlightedText = styled.span`
  color: #3B5999;
`;
const Desc = styled.span`
    color: #fff;
    margin: 0 0 1rem;
    display:block;
    padding:5px 40px;
    margin:0px;
    text-align: center;
    
`;
const ImageContainer = styled.div`
    width:25%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media only screen and (max-width:768px){
        width:50%;
    }
   
`;

const Image = styled.img`
    width:100px;
`;
const ImageDesc = styled.span`
    color: #fff;
    padding-right: 20px;
    display: block;
    margin: 0;
    font-size: 15px;
`;

const P = styled.p `
    font-weight: bold;
    color: #00afaf;
    display: inline;
`;
const Body =styled.div`
    flex:1;
    display:flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width:768px){
        flex-wrap:wrap;
    }
`;

const HowToVelo = () => {
    const { t } = useTranslation();
  return (
    <Container>
        <First>
            <Title>{t('HowToVelo.CONTAINER_TITLE')} <HighlightedText>{t('HowToVelo.HIGHLIGHTED_TEXT')}</HighlightedText></Title>
            <Desc>{t('HowToVelo.FIRST_DESC_1')}</Desc>
            <Desc>{t('HowToVelo.FIRST_DESC_2')}</Desc>
        </First>

    <Body>
        <ImageContainer>
            <Image src="https://i0.wp.com/snussrbija.com/wp-content/uploads/2022/08/n1.png?w=120" alt="Otvoriti"/>
            <ImageDesc><P>1.</P>  {t('HowToVelo.IMAGE_DESC_1')}</ImageDesc>
        </ImageContainer>
        <ImageContainer>
            <Image src="https://i0.wp.com/snussrbija.com/wp-content/uploads/2022/08/n2.png?w=120" alt="Nalepiti"/>
            <ImageDesc><P>2.</P> {t('HowToVelo.IMAGE_DESC_2')}</ImageDesc>
        </ImageContainer>
        <ImageContainer>
            <Image src="https://i0.wp.com/snussrbija.com/wp-content/uploads/2022/08/n3.png?w=120" alt="Osetiti"/> 
            <ImageDesc><P>3.</P>  {t('HowToVelo.IMAGE_DESC_3')}</ImageDesc>
        </ImageContainer>
        <ImageContainer>
            <Image src="https://i0.wp.com/snussrbija.com/wp-content/uploads/2022/08/n4.png?w=120" alt="Baciti"/> 
            <ImageDesc><P>4.</P>  {t('HowToVelo.IMAGE_DESC_4')}</ImageDesc>
        </ImageContainer>
        </Body>
    </Container>
  )
}

export default HowToVelo