import { SwapVert } from "@mui/icons-material";
import styled, {keyframes} from "styled-components";
import {useState, useEffect, useContext} from "react"
import { useTranslation } from "react-i18next";
import { ProductContext } from "../context/ProductContext";

const fadeIn = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const Component  = styled.div`
    vertical-align: baseline;
    outline: none;
    display: inline-block;
    position: relative;
    //
    @media only screen and (max-width:768px){
        margin-right:0;
    }

`;
const Button  = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    margin:0;
    background-color: transparent;
    padding:0px;

`;
const ButtonLink = styled.div`
  margin: 0;
  padding:0;
  display:flex;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  color:black;
  &:hover{
    color: #52c5c5;
  }
  
`;
const Span  = styled.span`
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 20px;
    margin-right: 10px;
    font-family: "Righteous";
    font-weight: 100;
    text-transform: uppercase;
    transition: .4s all cubic-bezier(.22,1,.36,1);
`;
const Ul  = styled.ul`
    padding: 0;
    border: 0;
    outline: none;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    margin: 0;
    z-index: 6;
    width: 200px;
    box-shadow: 0 2.8px 2.2px #00000009,0 6.7px 5.3px #0000000c,0 12.5px 10px #0000000f,0 22.3px 17.9px #00000012,0 41.8px 33.4px #00000016,0 100px 80px #0000001f;
    display: block;
    pointer-events: all;
    background-color: none;
    animation: ${fadeIn} 0.3s ease-out ;
    animation-delay:0ms;
`;
const Li  = styled.li`
    font-weight: 400;
    text-align: left;
    padding: 1rem;
    font-size: 15px;
    line-height: 20px;
    background: #fff;
    border-bottom: 1px solid #dedede;
    cursor: pointer;
    &:hover{
        color:white;
        background-color: #52c5c5;
    }
    //animation: ${fadeIn} 0.5s ease-out forwards;
  //animation-delay: ${({ delay }) => `${delay}ms`};
`;

const DropDown = ({isActive}) => {
    const { t } = useTranslation();
    const items = [t('DropDown.PRICE_ASC'),t('DropDown.PRICE_DESC'),t('DropDown.IN_STOCK'),'A-Z', 'Z-A'];
    const [selectedSort, setSelectedSort] = useState(t('DropDown.ITEMS_DEFAULT'));
    let { handleSort } = useContext(ProductContext )
    useEffect(()=>{
      handleSort(selectedSort)
    },[selectedSort])

  return (
    <Component  >
        <Button >
            <ButtonLink>
                <Span>{t('DropDown.TITLE')}</Span>
                <SwapVert style={{ fontSize:"30px"}} />
            </ButtonLink>
        </Button>
        {isActive &&
        <Ul>
            {items.map((item, index) => (
        <Li key={index} delay={index * 100} onClick={() => setSelectedSort(item)}>
          {item}
        </Li>
      ))}
        </Ul>
}

    </Component>
  )
}

export default DropDown