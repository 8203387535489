import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import MainNavbar from '../layouts/header/MainNavbar';
import Footer from '../layouts/footer/Footer';
import Announcment from '../components/Announcment';
import CustomerSidebar from '../layouts/main/CustomerSidebar'
import MyAccount from '../components/customer/MyAccount';
import AddressBook from '../components/AddressBook';
import OrderHistory from '../components/customer/OrderHistory';
import CustomerDetails from '../components/CustomerDetails';


const Container = styled.div`
    padding-top:70px;
    display:flex;
    @media only screen and (max-width:768px){
        display:block;
    }
`
const Left = styled.div`
    flex:1;
    background-color: #f1f1f1;
`;
const Right = styled.div`
    flex:3;
    margin-bottom: 80px;
`;
const ABDiv = styled.div`
@media only screen and (min-width:400px){
  max-width: 70%;
}
`
const OHDiv = styled.div`
  @media only screen and (min-width:400px){
    padding:20px;
}
`
const Customer = () => {
    const {details} = useParams()
    const [selectedDetail, setSelectedDetail] = useState(null);

  // Ažurirajte odabrani detalj kada se promeni details parametar
  useEffect(() => {
    setSelectedDetail(details);
  }, [details]);

  // Funkcija za renderovanje odgovarajuće komponente na osnovu odabranog detalja
  const renderDetailComponent = () => {
    switch (selectedDetail) {
      case 'moj-nalog':
        return <MyAccount />;
      case 'adresar':
        return <ABDiv><AddressBook/></ABDiv>;
      case 'moji-detalji':
        return <CustomerDetails />;
      case 'istorija-narudzbina':
        return <OHDiv><OrderHistory /></OHDiv>;
      default:
        return null;
    }
  };
  return (
      <>
      <MainNavbar/>
    <Container>
        <Left>
            <CustomerSidebar/>
        </Left>
        <Right>
        <div>{renderDetailComponent()}</div>
        </Right>

    </Container>
    <Footer/>
    </>
  )
}

export default Customer