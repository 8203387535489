import { useTranslation } from "react-i18next";
import styled from "styled-components"

const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #000000b4;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size:cover;
    z-index: 99999;
`;
const Wrapper = styled.div`

    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 90%;
    @media only screen and (max-width:768px){
        min-height:70%;
    }
    
`;
const Title = styled.h1`

    max-width: 500px;
    margin: 10px 0px  14px 0;
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
    font-weight: 400;
    @media only screen and (max-width:768px){
        font-size:16px;
    }
`;
const Br = styled.br`
margin-top:20px;
display:none;
@media only screen and (max-width:768px){
        display:block;
    }
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
`;
const Input = styled.input`
    flex:1;
    min-width: 40%;
    margin:10px 0px;
    padding: 10px;
`;
const Buttons = styled.div`
    //background-color: red;
    margin-top:30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media only screen and (max-width:768px){
        gap:5px;
        flex-direction: column;
    }

`;

const Button = styled.button`
    border-width: 2px;
    border-style: solid;
    padding: 17px 20px;
    box-sizing: border-box;
    display: block;
    text-align: center;
    cursor: pointer;
    transition: background-color .15s,border-color .15s;
    border-color: #465094;
    max-width: 280px;
    width: 100%;
    background-color: ${props=>props.side==="left" ? "#465094" : "white"};
    color: ${props=>props.side==="left" ? "#fff" : "#000"};

    &:hover{
        background-color: ${props=>props.side==="left" ? "white" : "#465094"};
        color: ${props=>props.side==="left" ? "#000" : "#fff"};
    }

`;

const Link = styled.span`
    font-size: 13px;
    line-height: 1.25;
    letter-spacing: .5px;
    color: #1f1f1f;
    padding-bottom: 55px;
    max-width: 582px;
    margin: 48px auto 0;
    text-align: center;

`;

const AgeRestriction = () => {
    const { t } = useTranslation();
  
    const handleYesClick = () => {
      const ageConfirmed = localStorage.getItem('ageConfirmed');
      if (!ageConfirmed) {
        localStorage.setItem('ageConfirmed', true);
      }
    };
  
    const handleNoClick = () => {
      window.location.href = 'https://www.google.com';
    };
  
    return (
      <Container>
        <Wrapper>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <p style={{ marginTop: '55px', fontSize: '50px', margin: '0px', padding: '0px', color: 'black', fontFamily: 'Righteous' }}>{t('AgeRestriction.Age')}</p>
            <p style={{ maxWidth: '70%', textAlign: 'center', fontSize: '22px', fontWeight: '700', margin: '0px', padding: '0px', fontFamily: 'Righteous', color: 'black' }}>{t('AgeRestriction.Description')}</p>
          </div>
          <Title>{t('AgeRestriction.Title')}</Title>
          <Form>
            <Buttons>
              <Button side="right" onClick={handleNoClick}>{t('AgeRestriction.No')}</Button>
              <Button side="left" onClick={handleYesClick}>{t('AgeRestriction.Yes')}</Button>
            </Buttons>
            <Link>{t('AgeRestriction.Disclaimer')}</Link>
          </Form>
        </Wrapper>
      </Container>
    );
  };
  
  export default AgeRestriction;