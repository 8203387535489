import styled, {css, keyframes } from 'styled-components';
import { Info } from "@mui/icons-material";
import React, { useState, useEffect } from 'react';
import { motion} from "framer-motion";

const slideOut = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.2) translate(100%, 100%);
    opacity: 0;
  }
  100% {
    transform: scale(0.1) translate(100%, 100%);
    opacity: 1;
  }
`;
const slideIn = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
  100% {
    transform: scale(0) translate(100%, 100%);
    opacity: 0;
  }
`;
const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0000007a;
`;

const Circle = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #ccc;
  border-top-color: teal;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
const Proba = () => {
  const circleVariants = {
    animate: {
      rotate: 360,
      borderRadius: ["50%", "25%", "50%", "50%", "50%"],
      transition: {
        duration: 0.7,
        ease: "linear",
        repeat: Infinity,
      },
    },
  };

  return (
    <Container>
      <Circle variants={circleVariants} animate="animate" />
    </Container>
  );
};


export default Proba;
