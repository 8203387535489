import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes za animaciju
const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
`;

// Styled komponenta za kružić
const Circle = styled.div`
  width: 10px;
  height: 10px;
  background-color: #1ccbcb; // Možete promeniti boju
  border-radius: 50%;
  margin: 5px;
  animation: ${bounce} 0.5s infinite alternate;
`;

// Styled komponenta za wrapper
const BouncingCircles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > ${Circle}:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > ${Circle}:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

// React komponenta
const SubmitAnim = () => {
  return (
    <BouncingCircles>
      <Circle />
      <Circle />
      <Circle />
    </BouncingCircles>
  );
}

export default SubmitAnim;
