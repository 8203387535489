import { LocationOn, PersonOutlineOutlined } from "@mui/icons-material";
import styled, {keyframes} from "styled-components";
import {useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";

import engIcon from '../../assets/images/icons/united-kingdom.png'
import srIcon from '../../assets/images/icons/serbia.png'
import pin_gif from '../../assets/images/pin.gif'
import axios from 'axios'

const fadeIn = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const Component  = styled.div`
    vertical-align: baseline;
    outline: none;
    display: inline-block;
    position: relative;
    //
    @media only screen and (max-width:768px){
        margin-right:0;
    }

`;
const Button  = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    margin:0;
    padding:0px;
    background-color: transparent;
    position:relative;
`;
const ButtonLink = styled.div`
  margin: 0;
  padding:0;
  display:flex;
  align-items: center;
  justify-content: center;  
  flex-direction:column;
  
  cursor: pointer;
  color: #fff;
  &:active{
    color:  #ed5664;;
  }
  
`;
const Span  = styled.span`
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
    font-family: "Urbanist";
    font-family: "Righteous";
    font-weight: 100;
    transition: .4s all cubic-bezier(.22,1,.36,1);
`;
const Ul  = styled.ul`
    padding: 0;
    border: 0;
    outline: none;
    list-style: none;
    position: absolute;
    top: 130%;
    right: -100px;
    margin: 0;
    width: 150px;
    
    box-shadow: 0 2.8px 2.2px #00000009,0 6.7px 5.3px #0000000c,0 12.5px 10px #0000000f,0 22.3px 17.9px #00000012,0 41.8px 33.4px #00000016,0 100px 80px #0000001f;
    display: block;
    pointer-events: all;
    background-color: none;
    animation: ${fadeIn} 0.3s ease-out ;
    animation-delay:0ms;
    z-index: 999;
`;
const Li  = styled.li`
    font-weight: 400;
    text-align: left;
    padding: 14px;
    font-size: 14px;
    //line-height: 20px;
    background: #fff;
    border-bottom: 1px solid #dedede;
    cursor: pointer;
    font-family: "Righteous";
    color: #1f1f1f;
    text-transform:uppercase;
    display:flex;
    align-items: center;
    justify-content: space-around;
    //gap:5px;
    &:hover{
        color:white;
        background-color: #ed5664;
    }
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid white;
  position:absolute;
`;
const Image = styled.img`
    width: 20px;
    height:20px;
`
const DivGIF = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 9999;
`;
const ImgGIF = styled.img`
   width:200px;
    
`

const FlagDropDown = () => {
  const { t, i18n } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [anim, setAnim] = useState(false);

  useEffect(() => {
    const isAlreadyActive = localStorage.getItem('isActive');
    if (!isAlreadyActive) {
      setIsActive(true);
      localStorage.setItem('isActive', 'true');
    }
  }, []);

  const locales = i18n.language === 'sr' ?{
    sr : {
        title: 'Srpski',
        icon: srIcon,
    },
    en : {
        title:'Engleski', 
        icon: engIcon
    }
}:
{
    sr : {
        title: 'Serbian',
        icon: srIcon,
    },
    en : {
        title:'English', 
        icon: engIcon
    }
}
  const changeLang = (key) => {
    let temp = localStorage.getItem('language')
    if (temp === key) { 
      setIsActive(!isActive);
      return;
    } 
    setAnim(true);
    i18n.changeLanguage(key);
    localStorage.setItem('language', key);
    setIsActive(!isActive)
    setTimeout(() => {
      setAnim(false);
    }, 3000);
  }
  // const [location, setLocation] = useState(
  //   localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) : null
  
  // );

  // useEffect(() => {
  //   const getIpAddress = async () => {
  //     try {
  //       const response = await axios.get('http://ip-api.com/json');
  //       console.log('ODGOVOR', response.data);
  //       localStorage.setItem('location', JSON.stringify(response.data));
  //       setLocation(response.data);
  //     } catch (error) {
  //       console.error('Error fetching IP info:', error);
  //     }
  //   };
  //   if(!location){
  //     getIpAddress();
  //   }
  // }, []);

  // useEffect(() => {

  //   if (location && location.country === 'Serbia') {
  //     let temp = localStorage.getItem('language')
  //     if (temp === 'en') { 
  //       setIsActive(!isActive);
  //       return;
  //     } 
  //     setAnim(true);
  //     i18n.changeLanguage('en');
  //     localStorage.setItem('language', 'en');
  //   }
  // }, [location]);
  return (
    <div>
        {anim && <DivGIF>
            <ImgGIF src={pin_gif}/>
        </DivGIF>}
        
    <Component>
      <Button onClick={() => setIsActive(!isActive)}>
        <ButtonLink>
          <LocationOn style={{ fontSize: "30px" }} />
          <Span>{i18n.language === 'sr' ? 'SRB' : 'ENG' }</Span>
        </ButtonLink>
      </Button>
      
      {isActive && (
        <>
          <Triangle></Triangle>
          <Ul>
          {Object.keys(locales).map((key, index) => {
            const locale = locales[key];
            return (

                <Li key={index} delay={index * 100} onClick={() => changeLang(key)}>
                  <Image src={locale.icon} alt={locale.title}/>
                  {locale.title}
                </Li>
            );
        })}
          </Ul>
        </>
      )}
    </Component>
    </div>
  );
};

export default FlagDropDown;