import { BlurCircular, BlurOn, Diversity2, FilterVintage, JoinLeft, KeyboardArrowDown, KeyboardArrowUp, Leaderboard, PanoramaVerticalSelect, Scale } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick'
import styled from 'styled-components'

const Wrapper = styled.div`
    margin:20px 0px 0px 0px;
    @media only screen and (min-width:400px){
        min-width: 35vw;
        max-width: 400px;
        margin: 20px auto;
    }
`;
const Head = styled.div`
    padding:0px 2px;
    display:flex;
    align-items: center;
    justify-content: space-around;
    cursor:pointer;
    background-color: teal;
`;
const Title = styled.h2`
    color:#fff;//#303030;
    font-size: 14px;
    font-weight: 400;
    font-family: "Righteous";
`;
const Icon = styled.a`
    color: #fff;
    
`;
const BodyContainer = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    border:1px solid teal;//#e6f6f9;
    background-color: #fff;
`
const BodyHead = styled.div`
    padding-top:14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    
`
const BodyIcon = styled.div`
    background-color: teal;
    color:#fff;
    border-radius: 50%;
    width:30px;
    height:30px;
    display:flex;
    align-items: center;
    justify-content: center;
`
const BodyTitle = styled.h2`
    font-size:14px;
    color:#303030;
    font-family: "Righteous";
    letter-spacing: 3px;
    text-transform: uppercase;
`
const BodyText = styled.p`
    font-size:14px;
    text-align: center;
    margin:0;
    padding-bottom:50px;
    font-weight: 300;
`
const SliderMini = ({product}) => {
    const { t } = useTranslation();
    const infoData = [];

        if (product.flavor !== null) {
        infoData.push({
            id: 1,
            icon: <FilterVintage />,
            title: t('SliderMini.FLAVOR'),
            text: product.flavor
        });
        }

        if (product.format !== null) {
            infoData.push({
                id: 2,
                icon: <PanoramaVerticalSelect style={{ fontSize: "16px" }} />,
                title:  t('SliderMini.FORMAT'),
                text: product.format
            });
            }
        if (product.nicotine !== null) {
            infoData.push({
                id: 3,
                icon: <JoinLeft style={{ fontSize: "18px" }} />,
                title: t('SliderMini.NICOTINE_STRENGTH'),
                text: product.nicotine + " MG"
            });
            }
        if (product.net_weight !== null) {
            infoData.push({
                id: 4,
                icon: <Leaderboard style={{ fontSize: "16px" }} />,
                title: t('SliderMini.NET_WEIGHT'),
                text: product.net_weight + " g"
            });
            }
        if (product.pouches_per_can !== null) {
            infoData.push({
                id: 5,
                icon: <BlurCircular style={{ fontSize: "20px" }} />,
                title: t('SliderMini.POUCHES_PER_CAN'),
                text: product.pouches_per_can
            });
            }
        if (product.manufacturer !== null) {
        infoData.push({
            id: 6,
            icon: <Diversity2 style={{ fontSize: "16px" }} />,
            title: t('SliderMini.MANUFACTURER'),
            text: product.manufacturer
        });
        }

    const settings = {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      
  return (
    <div >
        <Wrapper>
        <Head
                >
            <Title>
                {t('SliderMini.SPECIFICATION')}
            </Title>
            
        </Head>
        <Slider {...settings}>
            {infoData.map((item)=>{
        return(
            <BodyContainer key={item.id}>
                <BodyHead>
                    <BodyIcon>{item.icon}</BodyIcon>
                    <BodyTitle>{item.title}</BodyTitle>
                </BodyHead>
                <BodyText>{item.text}</BodyText>
            </BodyContainer>
        )
        })}
        </Slider>
    </Wrapper>
    </div>
  )
}

export default SliderMini