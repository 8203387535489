import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

const selectedLanguage = localStorage.getItem('language');
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: selectedLanguage || 'sr',
        //debug:true,
        fallbackLang:'sr',
    });

export default i18n;