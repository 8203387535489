import { KeyboardBackspace, ShoppingBasket } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'
import { useNavigation } from '../../utils/navigation'
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
    height:70px;
    position:fixed;
    background-color: teal;
    display:flex;
    padding:0;
    min-width: 100%;
    max-width: 100%;
    overflow:hidden;
    z-index:999;
    overflow-x:hidden;
`
const Wrapper = styled.div`
    min-width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:#fff;

`
const WrapperDiv = styled.div`
    flex:1;
    display:flex;
`
const Header = () => {
    const {goToHome, goToCart} = useNavigation();
    const navigate = useNavigate();
  return (
    <Container>
        <Wrapper>
            <WrapperDiv style={{justifyContent:"left", paddingLeft:"30px"}} onClick={()=>navigate(-1)} >
                <KeyboardBackspace style={{color:"#fff"}}/>
            </WrapperDiv>

            <WrapperDiv onClick={() => goToHome()}  style={{justifyContent:"center"}} >
    
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="70px" height="70px" viewBox="0 0 500.000000 500.000000"
            preserveAspectRatio="xMidYMid meet"
            id="logosvg"
            fill="white"
            >

            <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
            >
            <path d="M1632 3539 c-108 -21 -243 -121 -301 -222 -48 -85 -64 -156 -59 -266
            6 -129 39 -210 120 -297 66 -70 126 -110 208 -135 54 -16 55 -18 76 -75 30
            -86 48 -116 109 -182 65 -70 166 -125 263 -142 43 -7 234 -10 587 -9 289 2
            527 0 531 -4 10 -10 -42 -92 -82 -127 -20 -17 -58 -42 -83 -53 -46 -22 -47
            -22 -886 -22 -741 0 -840 -2 -837 -15 2 -8 121 -133 266 -278 l263 -263 789 3
            789 3 85 42 c108 52 177 122 228 231 36 74 37 81 36 187 0 125 -18 182 -89
            279 -58 80 -172 157 -260 176 -26 5 -33 14 -51 67 -12 34 -33 82 -47 106 -34
            57 -130 145 -191 176 -102 50 -113 51 -703 51 -615 0 -572 -6 -534 69 25 49
            91 107 148 132 43 18 77 19 878 19 590 0 836 3 841 11 3 6 -109 127 -262 280
            l-269 269 -760 -1 c-418 -1 -779 -5 -803 -10z m1595 -261 c57 -57 103 -106
            103 -110 0 -5 -292 -8 -648 -8 -623 0 -651 -1 -707 -20 -143 -49 -261 -170
            -301 -307 -9 -29 -21 -53 -28 -53 -7 0 -32 13 -57 28 -111 71 -154 155 -147
            288 5 95 31 148 102 211 84 74 55 72 861 72 l720 1 102 -102z m-276 -688 c30
            -6 74 -23 98 -38 48 -29 105 -100 116 -145 l7 -28 -563 3 c-563 3 -564 3 -609
            26 -56 27 -99 66 -135 120 -50 76 -79 72 508 72 313 0 546 -4 578 -10z m459
            -410 c22 -11 57 -39 80 -62 136 -142 92 -374 -90 -469 l-45 -24 -740 -3 -739
            -3 -103 103 c-57 56 -103 106 -103 110 0 5 293 8 650 8 632 0 652 1 712 21
            143 48 270 179 303 312 8 32 23 34 75 7z" stroke="white" strokeWidth="40"   />
            </g>
            </svg>
    </WrapperDiv>
    <WrapperDiv onClick={goToCart}  style={{justifyContent:"right", paddingRight:"30px"}}>
        <ShoppingBasket/>
    </WrapperDiv>
        </Wrapper>
    </Container>
  )
}

export default Header