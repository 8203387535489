import { useEffect, useState } from 'react'
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { AuthUserContext } from '../context/AuthUserContext';
import { useNavigation } from '../utils/navigation';
import APIService from '../services/APIService';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    position:relative;
`

const CustomerDetails = () => {
    const { t } = useTranslation();
    const {user, setUser} = useContext(AuthUserContext);
    const [loading, setLoading] = useState(false);
    
    const [name, setName] = useState(()=>user ? user.first_name : '');
    const [errorNameText, setErrorNameText] = useState('');

    const [surname, setSurname] = useState(()=>user ? user.last_name : '');
    const [errorSurnameText, setErrorSurnameText] = useState('');

    const [email, setEmail] = useState(()=>user ? user.email : '');
    const [ErrorEmailText, setErrorEmailText] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    

    const [mobile, setMobile] = useState(()=>user ? user.phone : '');
    const [errorMsgMobile, setErrorMsgMobile] = useState('');

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (
        errorNameText === '' &&
        errorSurnameText === '' &&
        ErrorEmailText === '' &&
        errorMsgMobile === ''
      ) {
        // Pozovite funkciju changeCustomer
        changeCustomer();
      }
    };
    
    let changeCustomer = async () => {
      setLoading(true);
      try {
        const formData = {
          first_name: name,
          last_name: surname,
          phone: mobile,
        };

        const response = await APIService.putCustomer(user.customer_id, formData);
        setUser(response);
        localStorage.setItem('user', JSON.stringify(response));
        
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert('Greška');
        }
        console.error(error);
      }
    };
    
  return (
        <Container >
          {loading && <Loading/>}
        
        <form className='form1' onSubmit={handleSubmit}>
        <h1 className='login-title'>{t('CustomerDetails.MY_DETAILS')}</h1>
        
        <label className='login-label'
       
            >{t('CheckoutForm.YOUR_NAME')}</label>
            <input 
             type="text"
             value={name}
             onChange={e => setName(e.target.value)}
             required
             className='checkoutformjsx-input1'
             style={{height:"8px"}}
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorNameText('')
            }}
            onBlur={e => {
                 if (e.target.value === '') {
                 e.target.style.borderBottom = '2px solid #991f39';
                 setErrorNameText(t('CheckoutForm.REQUIRED_FIELD'))
                 }else{
                     e.target.style.borderBottom = '1px solid aqua';
                     setErrorNameText('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorNameText !== '' ? 1 : 0, y: errorNameText !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorNameText}</motion.h3>


    <label className='login-label'
            >{t('CheckoutForm.YOUR_SURNAME')}</label>
            <input 
             type="text"
             value={surname}
             onChange={e => setSurname(e.target.value)}
             required
             className='checkoutformjsx-input1'
             style={{height:"8px"}}
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorSurnameText('')
            }}
            onBlur={e => {
                 if (e.target.value === '') {
                 e.target.style.borderBottom = '2px solid #991f39';
                 setErrorSurnameText(t('CheckoutForm.REQUIRED_FIELD'))
                 }else{
                     e.target.style.borderBottom = '1px solid aqua';
                     setErrorSurnameText('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorSurnameText !== '' ? 1 : 0, y: errorSurnameText !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorSurnameText}</motion.h3>


            <label className='login-label'
            >{t('CheckoutForm.EMAIL_ADDRESS')}</label>
            <input 
             type="text"
             value={email}
             onChange={e => setEmail(e.target.value)}
             required
             disabled
             className='checkoutformjsx-input1'
             style={{height:"8px",color:"#777"}}
            
             // style for input when it's selected and empty
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorEmailText('')
            }}
             // style for input when it's not selected or has content
             onBlur={e => {
                 if (e.target.value === '') {
                 e.target.style.borderBottom = '2px solid #991f39';
                 setErrorEmailText(t('CheckoutForm.REQUIRED_FIELD'))
                 }else if (!emailRegex.test(e.target.value)) {
                    e.target.style.borderBottom = '2px solid #991f39';
                    setErrorEmailText(t('CheckoutForm.INVALID_EMAIL_ADDRESS'))
                }
                 else{
                     e.target.style.borderBottom = '1px solid aqua';
                    setErrorEmailText('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: ErrorEmailText !== '' ? 1 : 0, y: ErrorEmailText !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{ErrorEmailText}</motion.h3>

        
            


            <label className='login-label'
            >{t('CheckoutForm.MOBILE_PHONE')}</label>
            <input 
             type="tel"
             value={mobile}
             onChange={e => setMobile(e.target.value)}
             required
             className='checkoutformjsx-input1'
             style={{height:"8px"}}
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorMsgMobile('')
            }}
            onBlur={e => {
                 if (e.target.value === '') {
                    e.target.style.borderBottom = '2px solid #991f39';
                    setErrorMsgMobile(t('CheckoutForm.REQUIRED_FIELD'))
                 }else{
                    e.target.style.borderBottom = '1px solid aqua';
                    setErrorMsgMobile('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorMsgMobile !== '' ? 1 : 0, y: errorMsgMobile !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorMsgMobile}</motion.h3>

<button className='login-form-button'
style={{maxWidth:"300px"}}
type='submit'
        >
          {t('CustomerDetails.SAVE_CHANGES')}
        </button>
        </form>
      </Container>

  )
}

export default CustomerDetails