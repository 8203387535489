import { createContext, useState, useEffect } from "react"

export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
    const [purchaseData, setPurchaseData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    let contextData = {
        purchaseData:purchaseData,
        setPurchaseData:setPurchaseData,
        submitted:submitted,
        setSubmitted:setSubmitted,
    }

    return (
        <CheckoutContext.Provider value={contextData}>
          {children}
        </CheckoutContext.Provider>
      );
};
  