import React, { useState, createContext } from 'react';

// Kreiramo novi context
export const SliderContext = createContext();

export const SliderProvider = ({ children }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <SliderContext.Provider value={{ slideIndex, setSlideIndex }}>
      {children}
    </SliderContext.Provider>
  );
};
