import styled from "styled-components"
import Announcment from "../components/Announcment";
import Footer from "../layouts/footer/Footer";
import ShopProducts from "../components/ShopProducts";
import {useState,useEffect, useContext} from "react"
import ShopFilterMenu from "../components/ShopFilterMenu";
import DropDown from "../components/DropDown";
import MainNavbar from "../layouts/header/MainNavbar";
import APIService from '../services/APIService'
import { useLocation, useNavigate } from 'react-router-dom';
import { CallMade, Search } from "@mui/icons-material";
import { Helmet } from 'react-helmet';
import { ProductContext } from "../context/ProductContext";
import Loading from "../components/Loading";
import { useNavigation } from "../utils/navigation";
import NotifyPopUp from "../components/NotifyPopUp";
import { useTranslation } from "react-i18next";

const Container = styled.div`
background-color: #F1F4F8;
@media only screen and (max-width:768px){
    background-color: #fff;
}
`;
const Title = styled.h1`
   padding:20px 60px;
    font-size: 40px;
    font-family: "Righteous";
    font-weight: 100;
    text-transform: uppercase;
    margin:0;
    @media only screen and (max-width:768px){
        padding:20px 0;
        text-align: center;
    }

`;
const FilterContainer = styled.div`
    display:flex;
    justify-content: space-around;
    align-items: center;
    padding:60px;
    @media only screen and (max-width:768px){
        flex-wrap: wrap;
        margin:0;
        padding:20px;
        justify-content: space-around;
    }
    position:relative;
    
    
`;
const FilterStickyContainer = styled(FilterContainer)`
position:sticky;
    top:70px;
    background-color:#fff;
    z-index:99;
    @media only screen and (min-width:768px){
        padding:20px;
        background-color: #F1F4F8;
    }

`
const Filter = styled.div`
    max-width: 60vw;
    @media only screen and (max-width:768px){
        max-width: none;
    }
   
`;
const FilterNicotineWrapper = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    @media only screen and (max-width:768px){
        flex-direction: column; 
    }

`;
const FilterText = styled.span`
    font-size:18px;
    font-weight:600;
    margin-right: 10px;
    display:block;
    white-space: nowrap;
    @media only screen and (max-width:768px){
        margin-right: 0;
    }
`;
const FilterDiv = styled.div`

    display: block;
    font-size: 0;
    flex: 0 1 auto;
    width: auto;
    text-align: center;
    @media only screen and (max-width:768px){
        margin-top: 30px;
        margin-bottom: 50px;
    }
`;
const FilterLabel = styled.label`
    min-width: 100px;
    text-align: center;
    font-size:  15px;
    letter-spacing: 0;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    margin: 0 5px 5px;
    border: 2px solid #39d4d4;
    background: #fff;
    padding: 10px 25px;
    border-radius: 10px;
    cursor: pointer;
    @media only screen and (max-width:768px){
        min-width: 100px;
        margin:0 2px 2px;
    }
`;
const LabelTitle = styled.div`
    display: inline-block;
    
`;
const LabelInput = styled.input`
    //appearance: none;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0;
    margin: 4px 20rem 0 0;
    padding: 0;
    background: none;
    outline: none;
    border: 2px solid #041e42;
    display: none;
`;
const MobileTitleNone = styled.div`
@media only screen and (max-width:768px){
        display:none;
    }
`;
const SearchContainer = styled.div`
    
    display: flex;
    max-width:80%;
    background-color: #e9e9e9;
    align-items: center;
    justify-content: center;
    gap:20px;
    height:70px;
    margin:0 auto;
    margin-top:40px;
    @media only screen and (max-width:768px){
      justify-content: left;
      flex-direction: column;
      padding:30px 0px;
      max-width:100%;
      margin-top:40px;
  }
`;
const SearchTitle = styled.h2`
    letter-spacing: 0px;
    color: #041e42;
    padding: 0;
    border: 0;
    margin: 0px 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    @media only screen and (max-width:768px){
      font-size:14px;
      text-align: left;
  }
    display:flex;
    align-items: center;
`;
const SearchInput = styled.input`
    min-width: 200px;
    border:none;
    outline:none;
    ::placeholder {
        font-size: 12px;
        font-weight: 100;
        color: #cacaca;
  }
    font-size: 15px;
    font-weight: 400;
    background: lightgray;
    color: #000000b2;
    height:40px;
    border-radius: 5px;
    padding: 0 30px;
    @media only screen and (max-width:768px){
      width:80%;
      min-height: 45px;
  }
`;
const SearhText = styled.p`
    padding:0;
    margin:0px;
    font-size: 13px;
    color: #b1b1b1;
    @media only screen and (max-width:768px){
      display:none;
  }

`;
const Button = styled.button`
    line-height: 1.25;
    letter-spacing: 0;
    font-weight: 700;
    color: black;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    text-align: left;
    margin: 0;
    background-color: transparent;
    border:none;
    padding:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom:20px;
`;
const Icon = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ rotated }) => (rotated ? 'rotate(45deg)' : 'rotate(0)')};
`;

const ShopSearch = () => {
    const { t } = useTranslation();  
    const [isActive, setIsActive] = useState(true);
    const [nicotineCounts, setNicotineCounts] = useState({});
    const [nicotineValues, setNicotineValues] = useState([]);
    const [categoryCounts, setCategoryCounts] = useState({});
    const [categoryValues, setCategoryValues] = useState([]);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [rotated, setRotated] = useState(false);
    let { setChosenProducts, setProducts, products, handleNicotineChange, nicotine, loading, chosenProducts, popUp} = useContext(ProductContext)
    const {goToShop} = useNavigation();

    const handleButtonClick = (event) => {
        event.stopPropagation();
        setIsActive(!isActive);
      };
      const handleClick = () => {
          setIsActive(false);
      }

      
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);
      const searchTerm = searchParams.get('search'); // uzima deo URL-a nakon glavnog linka
      const [searchText, setSearchText] = useState('')//useState(searchParams.get('search') || '');
      const [newSearchTerm, setNewSearchTerm] = useState(searchTerm);

      const navigate = useNavigate();

      useEffect(() => {
          const searchData = async () => {
            try {
              const response = await APIService.searchArticles(searchTerm, 'nicotine', [], [])
              setProducts(response)
            } catch (error) {
              console.error(error);
            }
          }
          searchData()
          setSearchText(newSearchTerm)
          setNewSearchTerm('')
      }, []);

    useEffect(() => {
      setChosenProducts(products)
      if (!loadedOnce && products.length) {
      const counts = {};
      products.forEach((item) => {
        const nicotine = item.nicotine;
        if (counts[nicotine] === undefined) {
          counts[nicotine] = 1;
        } else {
          counts[nicotine]++;
        }
      });
      console.log(counts);
      setNicotineCounts(counts);
      setNicotineValues(Object.keys(counts));

      const counts_category = {};
      products.forEach((item) => {
        const category = item.category;
        if (counts_category[category] === undefined) {
          counts_category[category] = 1;
        } else {
          counts_category[category]++;
        }
      });

      setCategoryCounts(counts_category);
      setCategoryValues(Object.keys(counts_category));
      setLoadedOnce(true);
  }
  }, [loadedOnce,products]);

    const handleSearch = (event) => {
      const queryParams = new URLSearchParams({
          search: searchText
      }).toString();
      navigate('/search?' + queryParams);
      window.location.reload()
    }

   


  return (
    <Container onClick={handleClick}>
      <Helmet>
        <title>PRETRAGA</title>
        <meta property="og:title" content="Snus Online | Snus Srbija" />
        <meta name="description" content="Pronađite svoj omiljeni snus proizvod na našem sajtu pomoću funkcije pretrage. Pretražujte po brendu, ukusu ili jačini snus i pronađite ono što tražite." />
        <meta property="og:description" content="Pronađite svoj omiljeni snus proizvod na našem sajtu pomoću funkcije pretrage. Pretražujte po brendu, ukusu ili jačini snus i pronađite ono što tražite." />
        <meta property="og:image" content={require("../assets/images/shopCover.webp")} />
        <link rel="canonical" href="https://www.snussrbija.com/search" />
        <meta name="keywords" content="snus prodaja, snus Srbija, snus online, nikotinski proizvodi, pronalaženje snusa" />
      </Helmet>

      {popUp && <NotifyPopUp/> }
        <MainNavbar/>
        <Announcment/>
        
        <SearchContainer>
            <SearchTitle>{t('Shop.SEARCH_RESULTS_TITLE')} <Search style={{fontSize:"25px", margin:"0px 0px"}} onClick={handleSearch} /></SearchTitle>
            
            <SearchInput type="input" placeholder={t('Shop.SEARCH_INPUT_PLACEHOLDER')} value={searchText} 
            onChange={e=>setSearchText(e.target.value)}
            onKeyPress={(event) => {
              if (event.charCode === 13) {
                handleSearch();
              }
            }}
            >
              
            </SearchInput>
            
        <SearhText>{t('Shop.SEARCH_PROMPT_TEXT')}</SearhText>
        </SearchContainer>
        

  
        <Title>{t('Shop.RESULTS_TITLE')}</Title>
        <FilterStickyContainer>

        <Filter onClick= {handleButtonClick}>
                <DropDown isActive={isActive} />
            </Filter>
            <Filter>
            <ShopFilterMenu 
                nicotineValues = {nicotineValues} nicotineCounts={nicotineCounts}
                categoryCounts={categoryCounts} categoryValues={categoryValues}
            />
            </Filter>
        </FilterStickyContainer>
        <FilterContainer>
            <Filter >
                <FilterNicotineWrapper>
        {loading && <Loading /> }
                    <MobileTitleNone>
            <FilterText>{t('Shop.SELECT_NICOTINE_STRENGTH')}</FilterText>
            </MobileTitleNone>
            <FilterDiv>
                {nicotineValues.map((item, index) => {
                const checked = nicotine.includes(item);
                const color = checked ? 'white' : 'black';
                const backgroundColor = checked ? 'teal' : 'white';

                return (
                    <FilterLabel key={index} style={{ color, backgroundColor }}>
                    <LabelTitle>{item}MG</LabelTitle>
                    <LabelInput
                        type="checkbox"
                        checked={checked}
                        onChange={() => handleNicotineChange(item)}
                    />
                    </FilterLabel>
                );
                })}

                </FilterDiv>
                </FilterNicotineWrapper>
            </Filter>
        </FilterContainer>
            <Button onMouseEnter={() => setRotated(true)}
      onMouseOut={() => setRotated(false)}
      onClick={()=> goToShop()}
      >{t('Shop.VIEW_ALL_PRODUCTS')}
        <Icon rotated={rotated} >
          <CallMade/>
        </Icon>
      </Button>

        <ShopProducts articles={chosenProducts}/>
     <Footer/>   
    </Container>
  )
}

export default ShopSearch