import styled from "styled-components";
import moment from "moment";
import { useEffect, useState } from "react";
import { LocalShipping } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContainerWrapper = styled.div`
  padding-top: 70px;
  min-height: 30px;
  max-width: 90%;
  background-color: #fff;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    text-align: center;
  }
`;
const D = styled.div`
  //white-space: nowrap;
  margin: 0px 5px;
`;
const Announcment = () => {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState("");
  const [announcment, setAnnouncment] = useState("");
  const [tail, setTail] = useState("");

  useEffect(() => {
    let targetTime = moment()
      .add(0, "day")
      .endOf("day")
      .hour(15)
      .minutes(0)
      .seconds(0);
    const now = moment();
    const today = now.format("D MMM");
    const holidaysDateExceptions = [
      "30 Dec",
      "31 Dec",
      "1 Jan",
      "2 Jan",
      "8 Jan",
      "15 Feb",
      "1 May",
      "2 May",
      "3 May",
      "4 May",
      "5 May",
      "6 May",
    ];

    // Ako je danas praznik, postavljamo sledeću pošiljku za 3. januar
    if (holidaysDateExceptions.includes(today)) {
      const lastHolidayIndex = holidaysDateExceptions.length - 1;
      const lastHoliday = holidaysDateExceptions[lastHolidayIndex];
      const dayAfterLastHoliday = moment(lastHoliday, "D MMM")
        .add(1, "day")
        .format("D MMM");
      setAnnouncment(t("Announcment.ANNOUNCEMENT_NEXT_SHIPMENT"));
      setCountdown(dayAfterLastHoliday);
      setTail("");
      return;
    }

    setAnnouncment(t("Announcment.ANNOUNCEMENT_SHIPMENT_TOMORROW"));
    setTail(t("Announcment.ANNOUNCEMENT_REMAINING"));

    const timer = setInterval(() => {
      const duration = moment.duration(targetTime.diff(now));

      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      const formattedTime = `  ${hours}h ${minutes}min ${seconds}sec`;
      setCountdown(formattedTime);

      // Provera da li je dan u nedelji vikend
      if (now.day() === 0) {
        // Nedelja
        setAnnouncment(t("Announcment.ANNOUNCEMENT_NEXT_SHIPMENT"));
        setCountdown(moment().add(1, "days").locale("sr").format("ddd, D MMM"));
        setTail("");
        return;
      } else if (now.day() === 5 && now.hour() < 15) {
        setAnnouncment(t("Announcment.ANNOUNCEMENT_SHIPMENT_FRIDAY"));
        return;
      } else if (now.day() === 5) {
        // Petak posle 16h
        setAnnouncment(t("Announcment.ANNOUNCEMENT_NEXT_SHIPMENT"));
        setCountdown(moment().add(1, "days").locale("sr").format("ddd, D MMM"));
        setTail("");
        return;
      } else if (now.day() === 6) {
        //&& now.hour() < 11
        // Subota
        targetTime = moment()
          .add(0, "day")
          .endOf("day")
          .hour(12)
          .minutes(0)
          .seconds(0);
        setAnnouncment(t("Announcment.ANNOUNCEMENT_SHIPMENT_FRIDAY"));

        // Subota
        setAnnouncment(t("Announcment.ANNOUNCEMENT_NEXT_SHIPMENT"));
        setCountdown(moment().add(2, "days").locale("sr").format("ddd, D MMM"));
        setTail("");
        return;
      }

      if (duration.asSeconds() <= 0) {
        clearInterval(timer);
        if (now.day() !== 5 && now.day() !== 6) {
          setAnnouncment(t("Announcment.ANNOUNCEMENT_NEXT_SHIPMENT"));
        }
        setCountdown(moment().add(1, "days").locale("sr").format("ddd, D MMM"));
        setTail("");
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container>
      <ContainerWrapper>
        <LocalShipping />
        <D>
          {" "}
          {announcment} <span style={{ color: "teal" }}>{countdown}</span>{" "}
          {tail}{" "}
        </D>
      </ContainerWrapper>
    </Container>
  );
};

export default Announcment;
