import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { motion,useAnimation } from 'framer-motion';
import data from "../data.js";
import { SliderContext } from "../context/SliderContext";

const ComponentX = styled(motion.div)`
    height: 100vh;
    padding-top:100px;

`;
const PrevArrow = styled.div`
  position: absolute;
  top: 50%;
  left:33.333%;;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  //background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
  @media only screen and (max-width:768px){
    left: 0%;
  }
`;
const NextArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 33.333%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
  @media only screen and (max-width:768px){
    right: 0%;
  }
`;

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        ease: 'easeInOut',
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
    },
  };
  
const CategorySlider = ({isMobile}) => {
  const [overview, setOverview] = useState(true);

  let { slideIndex, setSlideIndex} = useContext(SliderContext);
  
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  const handleClick = () => {
      setOverview(!overview);
      console.log(overview)

  };
    // state za pracenje aktivnog slide-a
    //const data = ["","https://a.storyblok.com/f/153226/1920x1080/b9fe274750/three-penguin-chairs.jpg","https://a.storyblok.com/f/153226/1920x1080/b9fe274750/three-penguin-chairs.jpg","https://a.storyblok.com/f/153226/1920x1080/b9fe274750/three-penguin-chairs.jpg","https://a.storyblok.com/f/153226/1920x1080/b9fe274750/three-penguin-chairs.jpg","https://a.storyblok.com/f/153226/1920x1080/b9fe274750/three-penguin-chairs.jpg","https://a.storyblok.com/f/153226/1920x1080/b9fe274750/three-penguin-chairs.jpg", ""]
    const handleBeforeChange = (current, next) => {
        setSlideIndex(next);
        console.log(slideIndex)
    }
    const settings = {
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical:false,
    initialSlide: 0, // postavite initialSlide na 1 kako biste postavili aktivni slajd na drugi slajd u nizu
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  


  return (
    <ComponentX animate={controls} variants={variants} initial="hidden">
     
        <Slider {...settings} >
        {!isMobile && <div></div>}
           {data.map((item,index) => (
          <div key={item.id} 
          style={{
            //margin:index === slideIndex ? "0 20vw" : "0px",
            paddingBottom:"40px",
            //maxWidth:!isMobile ? "33.3333vw" : "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //minHeight: "70vh"
            height:!isMobile ? index === slideIndex ? "40vh":"70vh"  : index === slideIndex ? "40vh" : "70vh"
    
          }}

          >
            <div>
             <img src={item.image} alt={item.title} 
             style={{
                 aspectRatio:!isMobile ? index === slideIndex ? "1/1":"11/7"  : index === slideIndex ? "1/1" : "11/6",
                 transform: !isMobile ? (index < slideIndex) ?  "translateX(0%)" : (index > slideIndex) ? "translateX(0%)" : "translateX(0%)"  : (index < slideIndex) ?  "translateX(+20%)" : (index > slideIndex) ? "translateX(-20%)" : "translateX(0%)" ,
                 display:"block",
                //margin:"auto",
                transition: "transform 1s ease-in-out, aspect-ratio 1s ease-in-out",

                width: !isMobile ? "90%" : "80%",
         //height: "100%",
         objectFit: "cover",
         objectPosition: "center",
             }} /> 
             <h1 className='h1__djm'
             >{item.title}</h1>
             <p className="slider__text" style={{ touchAction: "pan-y" }}>{item.text}</p>

             </div>
          </div>
        ))}
        {!isMobile && <div></div>}
        </Slider>
    </ComponentX>
  );
};

export default CategorySlider;