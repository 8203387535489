import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import styled from "styled-components"
import { popularProducts } from "../data";
import ProductCard from "./ProductCard";
import { useState } from "react";
import { useSelector } from "react-redux";

const Container = styled.div`
    display: flex;

`;
const Wrapper = styled.div`
  //padding:40px;
  display:flex;
  flex-wrap:wrap;
  margin:0 auto;
  @media only screen and (max-width:768px){
      padding:0px;
      justify-content: center;
      align-items: center;
      margin-top:40px;
    }
`;
const ProductsGallery = (props) => {
  const [slideIndex, setSlideIndex] = useState(0);
    const handleClick = (direction) =>
    {
//DORADA KADA SE UBACI DA DOBAVLJAM IZ BAZE PODATKE, SLIKE ITD..
        if(direction === "left"){
            setSlideIndex(slideIndex > 0  ? slideIndex-1 : 2)
        } else {
            setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0)
        }
    };
  
  return (
    <Container>

      <Wrapper>
        {props.articles.map(item=>(
            <ProductCard item={item} key={item.id}/>
        ))}
        </Wrapper>
    </Container>
  )
}

export default ProductsGallery