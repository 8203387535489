import { KeyboardBackspace, ShoppingBasket, Storefront } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'
import { useNavigation } from '../../utils/navigation'
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
    height:70px;
    position:fixed;
    background-color: teal;
    display:flex;
    padding:0;
    min-width: 100%;
    max-width: 100%;
    overflow:hidden;
    margin-top:70px;
    margin-bottom: 70px;
    z-index:999;
    overflow-x:hidden;
    @media only screen and (max-width:768px){
        display:none;
    }
`
const Wrapper = styled.div`
    min-width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:#fff;

`
const WrapperDiv = styled.div`
    flex:1;
    display:flex;
`
const H3 = styled.h3`
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    font-family: "Righteous";
    color: #fff;
    text-transform:uppercase;
    margin: 0px;
    padding:0px;
    &:hover{
        color: #52c5c5;
    }
`;
const WrapTwo = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    gap:10px;
`;
const DesktopNavbar = () => {
    const {goToHome, goToCart} = useNavigation();
    const navigate = useNavigate();
  return (
    <Container>
        <Wrapper>
            <WrapperDiv style={{justifyContent:"space-around", paddingLeft:"30px"}} >
                <WrapTwo>
                    <Storefront style={{color:"#fff"}}/>
                    <H3>PRODAVNICA</H3>
                </WrapTwo>
                <WrapTwo>
                    <KeyboardBackspace style={{color:"#fff"}}/>
                    <H3>PRODAVNICA</H3>
                </WrapTwo>
            </WrapperDiv>

        <WrapperDiv  style={{justifyContent:"center"}} >
        
        </WrapperDiv>
    <WrapperDiv style={{justifyContent:"right", paddingRight:"30px"}}>
        <ShoppingBasket/>
    </WrapperDiv>
        </Wrapper>
    </Container>
  )
}

export default DesktopNavbar