import styled from "styled-components"
import {categories} from "../data"
import CategoryItem from "./CategoryItem";

const Container = styled.div`
  
  display:flex;
  align-items: center;
  justify-content: center;
`
const Wrapper = styled.div`
    
    display:grid;
    grid-template-columns: repeat(3, 1fr); 
    gap:5px;
    @media only screen and (max-width:768px){
      grid-template-columns: repeat(1, 1fr); 
    }
    @media only screen and (min-width:769px){
      padding:0px 200px;
    }
    
`;

const Categories = () => {
  return (
    <Container>  
      <Wrapper>
        {categories.map(item=>(
            <CategoryItem item={item} key={item.id}/>
        ))}
        </Wrapper>
    </Container>

  )
}

export default Categories