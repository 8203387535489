import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components"
import { AuthUserContext } from "../../context/AuthUserContext";
import bgpic from '../../assets/images/background.webp'

const rotate = keyframes`
  from{
    transform:rotate(0deg)
  }
  to{
    transform:rotate(-360deg)
  }
`;
const Container = styled.div`
  @media only screen and (max-width:768px){
    text-align:center;
    padding: 0px 20px;
  }
  @media only screen and (min-width:768px){
    padding:20px 50px;
  }
`;
const Title = styled.h2`
  font-size: 26px;
  font-weight: 400;
  font-family: "Righteous";
  padding: 0px 10px;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: #1f1f1f;
  @media only screen and (max-width:768px){
    font-size:24px;
    padding: 20px 10px;
  }
`
const SubTitle = styled(Title)`
  font-size:18px;
  @media only screen and (max-width:768px){
      font-size:16px;
      padding: 0px 10px;
    }
`;
const P = styled.p`
  font-size:14px;
  padding:0;
  margin:0;
  text-align:left;
`
const PB = styled(P)`
  font-weight:bold;
  padding: 10px 0;
  font-family: "Righteous";
  font-weight: 100;
`
const Button = styled.button`
    font-size:13px;
    padding: 10px 20px;
  @media only screen and (min-width:768px){
    max-width:300px;
  }
`;
const AvatarDiv = styled.div`
  display:flex;
  align-items: center;
  justify-content:center;
  background-color:#eee;
  border-radius:10px;
  min-height:120px;
  @media only screen and (min-width:768px){
    min-height:100px;
  }
`
const AvatarBody = styled.div`
  flex:2;
  display:flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width:768px){
    flex:3;
  }
`;
const AvatarPicture = styled.picture`
  display:flex;
  align-items: center;
  justify-content: center;
  flex:1;
`
const AvatarImg = styled.img`
  height:60px;
  width:60px;
  border-radius: 50%;
`

const AddressContainer = styled.div`
  border:1px solid #ddd;
  width:100%;
  min-height:220px;
  @media only screen and (min-width:768px){
    width:400px;
  }
`
const AddressWrapper = styled.div`
  padding: 25px 20px;
`
const ButtonAddress = styled.button`
    font-size:12px;
    //padding: 10px 20px;
    margin:0px;
    margin-top:20px;
  @media only screen and (min-width:768px){
    max-width:300px;
  }
`;
const OrderHistory = styled.div`
  
  position: relative;
  padding: 10px 0px 40px 0;
  background: rgb(247, 247, 247);
  background-image: url(${bgpic});
  background-repeat: repeat-x;
  background-position: left top;
`
const OrderHistoryContainer = styled.div`
padding:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  gap:10px;
  @media only screen and (min-width:768px){
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 10px;
    justify-content: center;
  }
`
const OrderHistoryCard = styled.div`
  
  background-color:transparent;
  //clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
  border:1px solid #777;
  min-width:80%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
@media only screen and (max-width:768px){
  max-width:80%;
} 
  min-height:100px;
  ::before{
    content: '';
    position:absolute;
    width:80px;
    height:400%;
    background: teal;
    animation:${rotate} 4s linear infinite;
    
    @media only screen and (max-width:768px){
      width:140%;
      height:30px;
      left:-20%;
      top:50%;
    }
    
  }
  ::after{
    content: '';
    position:absolute;
    background:#fff;
    inset:3px;
    background: rgb(247, 247, 247);
    background-image: url(${bgpic});
    background-repeat: repeat-x;
    background-position: left top;
  }

  &:hover {
    inset:4px;
  }

`;
const OrderHistoryHead = styled.div`
  z-index:2;
`;
const OrderHistoryTitle = styled(Title)`
  font-size:14px;
  margin-bottom:0;
`;
const OrderHistoryBody = styled.div`
  z-index:2;
  flex:2;
  padding:10px;
  display:flex;
  align-items: left;
  justify-content: space-around;
  flex-direction: column;
  gap:10px;
`;
const OrderHistoryP = styled(P)`
`;
const WrapBr = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`
const Br = styled.div`
    border-bottom:none;
    border-left:1px solid #1f1f1f;
    width:1px;
    height:40px;

`
const MyAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, emailUser, getOrderHistory, orderHistory } = useContext(AuthUserContext);
  useEffect(()=>{
    getOrderHistory()
  },[])
  return (
    <Container>
      <Title >{t('MyAccount.ACCOUNT_OVERVIEW')}</Title>
      <SubTitle>{t('MyAccount.WELCOME')} {user.first_name}</SubTitle>
      <div className='login-br'/>
      <Title >{t('MyAccount.ACCOUNT_DETAILS')}</Title>
      <P>{user.first_name}  {user.last_name}</P>
      <PB>{user.email}</PB>
      {emailUser &&
      <P>{t('MyAccount.CONNECTED_WITH_GOOGLE')}</P>
    }
      <Button className='login-form-button'
        >
          <Link style={{textDecoration:"none", all:"unset"}}  to="/kupac/moji-detalji/">
          {t('MyAccount.EDIT_DETAILS')}
          </Link>
        </Button>
        {emailUser && 
        <>
        <div className='login-br'/>
          <Title >{t('MyAccount.LINKED_ACCOUNTS')}</Title>
          <PB>{t('MyAccount.ACTIVE_LINKED_ACCOUNTS')}</PB>
          
          <AvatarDiv >
            <AvatarPicture>
              <AvatarImg src={emailUser.avatar}
              />
            </AvatarPicture>
            <AvatarBody>
            <PB>{t('MyAccount.GREETING')} {emailUser.name} {emailUser.surname}</PB>
            <P style={{width:"70%"}}>{t('MyAccount.SUCCESSFULLY_LINKED')}</P>
        </AvatarBody>
        </AvatarDiv>
        </>
      }

      <div className='login-br'/>
      <OrderHistory>
      <Title >{t('MyAccount.RECENT_ORDERS')}</Title>
      {orderHistory && orderHistory.length > 0 ? 
      <>
      <OrderHistoryContainer>
      {orderHistory.slice(0, 3).map((item, index) => (
      <OrderHistoryCard key={index} onClick={()=>navigate("/kupac/istorija-narudzbina/")}>
        <OrderHistoryHead>

          <WrapBr >
          <OrderHistoryTitle>{item.order_id}</OrderHistoryTitle>
          <Br />
          </WrapBr>
        </OrderHistoryHead>
        <OrderHistoryBody>
        <OrderHistoryP>{t('MyAccount.ORDER_DATE')}</OrderHistoryP>
          <OrderHistoryP>
        {item.order_date}
        </OrderHistoryP>
        </OrderHistoryBody>
      </OrderHistoryCard>
    ))}
          </OrderHistoryContainer>
      {orderHistory.length > 3 &&
    <Button className='login-form-button'
        >
          <Link style={{textDecoration:"none", all:"unset"}}  to="/kupac/istorija-narudzbina/">
          {t('MyAccount.VIEW_ALL')}
          </Link>
        </Button>
        }
          </>
      : 
      <P>{t('MyAccount.NO_ORDERS')}</P>
      }
      </OrderHistory>


      <div className='login-br'/>
      <Title >{t('MyAccount.ADDRESS_BOOK')}</Title>
      <AddressContainer>
        <AddressWrapper>
          <PB style={{padding:"5px 0px"}}>{user.first_name} {user.last_name}</PB>
          <P style={{padding:"5px 0px", color:"#777"}}>{t('MyAccount.COUNTRY')}</P>
          <P style={{padding:"5px 0px", color:"#777"}}>{user.city},</P>
          <P style={{padding:"5px 0px", color:"#777"}}>{user.address},</P>
          <P style={{padding:"5px 0px", color:"#777"}}>{user.postal_code},</P>
          <ButtonAddress className='login-form-button'
        >
          <Link style={{textDecoration:"none", all:"unset"}}  to="/kupac/adresar/">
          {t('MyAccount.EDIT_ADDRESS')}
          </Link>
        </ButtonAddress>
          </AddressWrapper>
      </AddressContainer>

    </Container>
  )
}

export default MyAccount