import { Info } from "@mui/icons-material";
import styled from "styled-components";
import { useContext, useState } from "react";
import { Add, Remove } from "@mui/icons-material";
import Multibuy from "./Multibuy";
import { cartActions } from "../store/cart-slice";
import { useDispatch } from "react-redux";
import { useNavigation } from "../utils/navigation";
import Loading from "./Loading";
import { ProductContext } from "../context/ProductContext";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  /*width:20vw;//25vw;
    position:relative;
    
    display:flex;
    flex-direction: column;
    padding:0px;
    //height:700px;
    @media only screen and (max-width:768px){
        max-width: 50vw;
        margin:0px;
        padding:0px;
        width:49vw;
        min-width:45vw;
        //height:500px;
    }*/
  background-color: #fff;
  max-width: 100%;
  overflow: hidden;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 10px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    height: 150px;
    //width:100px;
    padding: 0px;
  }
`;

const Image = styled.img`
  height: 100%;
  max-width: 200px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  //width:auto;
`;
const Review = styled.div`
  min-width: 80px;
  position: absolute;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 5px 5px;
  z-index: 3;
  background: teal;
  margin-left: 10px;
  margin-top: 5px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const Bottom = styled.div`
  flex: 1;
  padding: 10px;
  @media only screen and (max-width: 768px) {
    flex: 2;
    padding: 5px 1px;
  }
`;

const MultibuyContainer = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ProductTitle = styled.h1`
  font-size: 20px;
  line-height: 1.25;
  font-weight: 100;
  height: 40px;
  font-family: "Righteous";
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
const ProductPrice = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
    font-weight: 700;
  }
`;
const NicotineTitle = styled.h2`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px;
  margin-top: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    font-weight: bold;
  }
`;
const Nicotine = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.available ? "teal" : "#ccc")};
  padding: 4px;
  align-items: center;
  height: 30px;

  ${(props) =>
    !props.available &&
    `
    position: relative; /* dodano svojstvo za pozicioniranje pseudoelementa */
    
    :before {
        content: ''; /* stvara pseudo-element */
        position: absolute;
        top: 50%;
        left: 0; 
        width: 100%; 
        border-top: 1px solid #696969; 
        height:1px;
        transform: rotate(-7deg); 
    }
    `}
`;
const NicotineMG = styled.h2`
  color: white;
  font-weight: 700;
  font-size: 15px;
  margin-left: 10px;
`;
const Circles = styled.div`
  display: flex;
  justify-content: space-around;
`;
const Circle = styled.div`
  flex: 1;

  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #9dfafa;
  margin: 0px 2px;
  border: 0.5px solid white;
`;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: space-around;
  margin-top: 80px;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
    //gap:10px;
  }
  @media only screen and (min-width: 400px) {
    margin-top: 10px;
  }
`;
const AmountContainer = styled.div`
  border: 2px solid #5862bf;
  padding: 5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-left: 10px;
  width: 43%;
  justify-content: space-between;
  border-radius: 100px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    width: 90%;
    justify-content: space-between;
  }
`;
const AmountInput = styled.input`
  border: none;
  box-sizing: border-box;
  height: 100%;
  border: none;
  outline: none;
  max-width: 40px;
  background: none;
  font-weight: 600;
  font-size: 16px;
  ::placeholder {
    font-size: 12px;
    font-weight: 100;
    color: #ececec;
  }
  text-align: center;
`;
const Button = styled.button`
  padding: 10px;
  border: 2px solid #5862bf;
  border-radius: 100px;
  background-color: #5862bf;
  cursor: pointer;
  font-weight: 500;
  color: white;
  width: 45%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 13px;
    font-weight: 300;
  }
  &:hover {
    background-color: white;
    color: #5862bf;
  }
`;
const NotAvailable = styled.div`
  //overflow: hidden;
  min-height: 100px;
  border: 0.1px solid teal;
  background-color: #e7e7e771;
  border-radius: 10px;
  margin-top: 80px;
  padding: 0px 5px;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  @media only screen and (min-width: 400px) {
    padding: 10px;
    min-height: auto;
    margin-top: 15px;
  }
`;
const NotAvailableTitle = styled.h2`
  font-size: 20px;
  font-weight: 100;
  white-space: nowrap;
  font-family: "Righteous";
  margin: 0px;
  padding: 0px;
  padding-left: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    padding: 0;
  }
`;
const NotAvailableText = styled.h6`
  display: inline;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const ShopProduct = ({ item }) => {
  const { t, i18n } = useTranslation();
  const [isHidden, setIsHidden] = useState(false);
  const [amount, setAmount] = useState(1);
  const { goToProduct } = useNavigation();
  const { setPopUp } = useContext(ProductContext);

  const getColor = (nicotine, val) => {
    const nicotineValue = parseInt(nicotine);
    return nicotineValue > val ? "white" : "teal";
  };

  function handleChange(e) {
    const value = parseInt(e.target.value);
    const newValue = Math.max(value, 0);
    setAmount(newValue);
  }
  function addOne() {
    if (parseInt(amount) >= 0) {
      setAmount(parseInt(amount) + 1);
    } else {
      setAmount(1);
    }
  }
  function removeOne() {
    if (parseInt(amount) > 0) {
      setAmount(parseInt(amount) - 1);
    }
  }
  const dispatch = useDispatch();
  const images = item.images.map((img) => img.image_url); // izvlačenje svih URL-ova slika u niz
  const primaryImage = images.find(
    (img) => item.images.find((i) => i.is_primary_image).image_url === img
  ); // pronalaženje URL-a primarne slike

  const addToCart = (quantity) => {
    dispatch(
      cartActions.addToCart({
        article: item,
        quantity: quantity,
      })
    );
    setAmount(0);
  };
  return (
    <Container>
      <Top
        onClick={() =>
          goToProduct(
            item.category.toLowerCase(),
            item.title.toLowerCase().replace(/ /g, "-")
          )
        }
      >
        {item.review != null ? (
          i18n.language === "en" ? (
            <></>
          ) : (
            <Review>{item.review}</Review>
          )
        ) : (
          <></>
        )}

        <Image src={primaryImage} alt={item.title} loading="lazy" />
      </Top>
      <Bottom>
        {item.state === "Dostupno" ? (
          <MultibuyContainer>
            <Multibuy />
          </MultibuyContainer>
        ) : (
          <MultibuyContainer>
            <Button
              onClick={() => setPopUp(item)}
              style={{
                minWidth: "100%",
                textTransform: "uppercase",
                borderRadius: "5px",
                height: "100%",
              }}
            >
              {t("ProductCard.SUBSCRIBE_TEXT")}
            </Button>
          </MultibuyContainer>
        )}
        <ProductTitle
          onClick={() =>
            goToProduct(
              item.category.toLowerCase(),
              item.title.toLowerCase().replace(/ /g, "-")
            )
          }
        >
          {item.category} {item.title}
        </ProductTitle>
        <ProductPrice>{item.price}RSD</ProductPrice>

        <NicotineTitle>{t("ProductCard.NICOTINE_STRENGTH")}</NicotineTitle>
        <Nicotine available={item.state === "Dostupno" ? true : false}>
          <NicotineMG>{item.nicotine}MG</NicotineMG>

          <Circles>
            <Circle style={{ backgroundColor: getColor(item.nicotine, 0) }} />
            <Circle style={{ backgroundColor: getColor(item.nicotine, 5) }} />
            <Circle style={{ backgroundColor: getColor(item.nicotine, 13) }} />
            <Circle style={{ backgroundColor: getColor(item.nicotine, 20) }} />
            <Circle style={{ backgroundColor: getColor(item.nicotine, 30) }} />
          </Circles>
        </Nicotine>
        {item.state === "Dostupno" ? (
          <AddContainer>
            <AmountContainer>
              <Remove onClick={removeOne} />
              <AmountInput
                type="number"
                value={amount}
                step={1}
                min={0}
                onChange={handleChange}
                aria-label="quantity"
              />
              <Add onClick={addOne} />
            </AmountContainer>

            <Button onClick={() => addToCart(amount)}>
              {t("ProductCard.ADD_TO_CART_BUTTON")}
            </Button>
          </AddContainer>
        ) : (
          <NotAvailable>
            <NotAvailableTitle>
              {" "}
              {i18n.language === "sr" ? item.state : "Out Of Stock"}
            </NotAvailableTitle>
            <NotAvailableText>
              {t("ProductCard.APOLOGIZE")}{" "}
              <span style={{ fontWeight: "700" }}>
                {item.category} {item.title} {item.nicotine}MG
              </span>{" "}
              {t("ProductCard.NOT_AVAILABLE_TEXT")}
            </NotAvailableText>
          </NotAvailable>
        )}
      </Bottom>
    </Container>
  );
};

export default ShopProduct;
