import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Footer from '../layouts/footer/Footer'
import MainNavbar from '../layouts/header/MainNavbar'

const PrivacyPolicyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const PrivacyPolicyTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const PrivacyPolicyText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
`;

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
      <>
      <MainNavbar /> 
    <PrivacyPolicyContainer>
<PrivacyPolicyTitle>{t('PrivacyPolicy.TITLE')}</PrivacyPolicyTitle>
<PrivacyPolicyText>
{t('PrivacyPolicy.LAST_UPDATED')} 22.6.2023.
</PrivacyPolicyText>
<PrivacyPolicyText>
{t('PrivacyPolicy.THANK_YOU')}
</PrivacyPolicyText>
<PrivacyPolicyText>
{t('PrivacyPolicy.BEST_EXPERIENCE')}
</PrivacyPolicyText>
<PrivacyPolicyText>
{t('PrivacyPolicy.USE_OF_DATA')}
</PrivacyPolicyText>
<PrivacyPolicyText>
{t('PrivacyPolicy.SHARING_WITH_PARTNERS')}
</PrivacyPolicyText>
<PrivacyPolicyText>
{t('PrivacyPolicy.DATA_SECURITY')}
</PrivacyPolicyText>
<PrivacyPolicyText>
{t('PrivacyPolicy.EXTERNAL_LINKS')}
</PrivacyPolicyText>
<PrivacyPolicyText>
{t('PrivacyPolicy.CONTACT_US')}
</PrivacyPolicyText>
</PrivacyPolicyContainer>
<Footer/>
</>
  );
};

export default PrivacyPolicy;
