import React, { useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components';
import { AuthUserContext } from '../../context/AuthUserContext';
import bgpic from '../../assets/images/background.webp'
import { useTranslation } from 'react-i18next';
import velo from '../../assets/images/category1.webp'
import pablo from '../../assets/images/category3.webp'
import grant from '../../assets/images/category2.webp'
import lyft from '../../assets/images/lyftCategory.webp'
import fedrs from '../../assets/images/fedrsCategory.webp'
import iceberg from '../../assets/images/icebergCategory.webp'
import siberia from '../../assets/images/siberiaCategory.webp'

import kurwa from '../../assets/images/kurwaCategory.webp'
import killa from '../../assets/images/killaCategory.webp'
import rabbit from '../../assets/images/rabbitCategory.webp'
import thunder from '../../assets/images/thunderCategory.webp'
import volt from '../../assets/images/voltCategory.webp'


const rotate = keyframes`
  from{
    transform:rotate(0deg);
  }
  to{
    transform:rotate(360deg);

  }
`;
const rotateDesk = keyframes`
  0% {
    transform: rotate(0deg);
    width: 200px;
  }
  50% {
    transform: rotate(180deg);
    width: 140px;
  }
  75% {
    transform: rotate(270deg);
    width: 80px;
  }
  100% {
    transform: rotate(360deg);
    width: 200px;
  }
`;

const rotateMob = keyframes`
  0% {
    transform: rotate(0deg);
    height: 70px;
  }
  50% {
    transform: rotate(180deg);
    height: 100px;
  }
  75% {
    transform: rotate(270deg);
    height: 140px;
  }
  100% {
    transform: rotate(360deg);
    height: 70px;
  }
`;

const Title = styled.h2`
  font-size: 26px;
  font-weight: 400;
  font-family: "Righteous";
  padding: 0px 10px;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: #1f1f1f;
  @media only screen and (max-width:768px){
    font-size:24px;
    padding: 20px 10px;
  }
`
const H3 = styled.h3`
  font-size:14px;
  padding:0;
  margin:0;
  text-align:left;
  letter-spacing:1.4px;
  text-transform: uppercase;
  padding: 5px 0px;

`
const P = styled.p`
  font-size:14px;
  padding:0;
  margin:0;
  text-align:left;
`
const OrderP = styled(P)`
  font-size:14px;
  padding:0;
  margin:0;
  text-align:left;
  text-transform:uppercase;
  color: #777;
`

const Button = styled.button`
    font-size:13px;
    padding: 10px 20px;
  @media only screen and (min-width:768px){
    max-width:300px;
  }
`;

const OrderHistoryX = styled.div`
  
  position: relative;
  padding: 10px 0px 40px 0;
  background: rgb(247, 247, 247);
  background-image: url(${bgpic});
  background-repeat: repeat-x;
  background-position: left top;
`
const OrderHistoryContainer = styled.div`
padding:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  gap:10px;
  @media only screen and (min-width:768px){
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 10px;
    justify-content: center;
  }
`
const OrderHistoryCard = styled.div`
  
  background-color:transparent;
  //clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
  border:1px solid #777;
  min-width:80%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  
@media only screen and (max-width:768px){
  max-width:80%;
} 
  min-height:100px;
  &.active {
  ::before{
    content: '';
    position:absolute;
    width:80px;
    height:400%;
    background: teal;
    animation:${rotate} 4s linear infinite;
    
    @media only screen and (max-width:768px){
      width:140%;
      height:30px;
      left:-20%;
      top:50%;
    }
    
  }
  ::after{
    content: '';
    position:absolute;
    background:#fff;
    inset:3px;
    background: rgb(247, 247, 247);
    background-image: url(${bgpic});
    background-repeat: repeat-x;
    background-position: left top;
  }

  &:hover {
    inset:-4px;
  }
}

`;
const OrderHistoryHead = styled.div`
  z-index:2;
`;
const OrderHistoryTitle = styled(Title)`
  font-size:14px;
  margin-bottom:0;
`;
const OrderHistoryBody = styled.div`
  z-index:2;
  flex:2;
  padding:10px;
  display:flex;
  align-items: left;
  justify-content: space-around;
  flex-direction: column;
  gap:10px;
`;
const OrderHistoryP = styled(P)`
`;
const WrapBr = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`
const Br = styled.div`
    border-bottom:none;
    border-left:1px solid #1f1f1f;
    width:1px;
    height:40px;

`

const OrderContainer = styled.div`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: rgb(247, 247, 247);
    //background-image: url(${velo});
    background-image: ${({ activeCategory }) =>
      `url(${getImageForCategory(activeCategory)})`};
    background-repeat: no-repeat;
    transition: opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    opacity: 0.1; 
    z-index: -1;
  }
`;
const SubTitle = styled(Title)`
  font-size:18px;
  padding:0;
  @media only screen and (max-width:768px){
      font-size:16px;
    }
`;
const Text = styled(SubTitle)`
  font-size:14px;
  padding:0;
  letter-spacing:1.2px;
  margin:10px 0 10px 5px;
  color: #1f1f1f;
  @media only screen and (max-width:768px){
      font-size:14px;
    }

`;
const OrderTitle = styled(SubTitle)`
  font-size:14px;
  padding:0;
  letter-spacing:1.2px;
  @media only screen and (max-width:768px){
      font-size:14px;
    }
`;

const OrderHead = styled.div`
  border-bottom: 1px solid #777;
  border-top: 1px solid #777;
  display:flex;
  align-items:center;
  justify-content:center;
  gap:10px;
`
const OrderBodyX = styled.div`
  @media only screen and (min-width:768px){
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 10px;
    justify-content: center;
  }
`
const OrderBody = styled.div`
  min-height:40vh;
  
  
`
const Figure = styled.figure`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    overflow: hidden;
    position:relative;
    
    height: 300px;
    padding: 10px;
    cursor:pointer;
    border-radius:50%;
    border:1px solid #777;
    @media only screen and (max-width: 768px) {
        padding: 0px;
    }
    ::before{
    content: '';
    position:absolute;
    //width:80px;
    height:400%;
    background: ${props=>props.color};
    animation:${rotateDesk} 3s linear infinite;
    
    @media only screen and (max-width:768px){
      width:140%;
      //height:30px;
      animation:${rotateMob} 3s linear infinite;
      left:-20%;
      top:40%;
    }
    
  }
  ::after{
    content: '';
    position:absolute;
    background:#fff;
    border-radius:50%;
    inset:3px;
    z-index:1;
  }
`
const Image = styled.img`
  height: 50%;
  object-fit: contain;
  display: block;
  width:auto;
  z-index:2;
`
const getImageForCategory = (category) => {
  const formattedCategory = category.toLowerCase().trim();
  switch (formattedCategory) {
    case 'velo':
      return velo;
    case 'grant':
      return grant;
    case 'pablo':
        return pablo;
    case 'lyft':
      return lyft;
    case 'siberia':
      return siberia;
    case 'fedrs':
      return fedrs;
    case 'kurwa':
      return kurwa;
    case 'killa':
      return killa;
    case 'rabbit':
      return rabbit;
    case 'thunder':
      return thunder;
    case 'volt':
      return volt;
    default:
      return iceberg;
  }
};


const OrderHistory = ({selected}) => {
  const { t } = useTranslation();
  const { getOrderHistory, orderHistory } = useContext(AuthUserContext);
  const [singleOrder, setSingleOrder] = useState(null)
  const [categories, setCategories] = useState(null)
  const [activeCard, setActiveCard] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  
  useEffect(()=>{
    getOrderHistory()
  },[])
  useEffect(() => {
    if (orderHistory && orderHistory.length > 0 && selected) {
    
      setSingleOrder(orderHistory[selected]);
      
    }
  }, []);
  useEffect(()=>{
    if (!singleOrder) {return;} 
    const uniqueCategories = [...new Set(singleOrder.order_items.map((article) => article.article.category))];
    setCategories(uniqueCategories)
    setActiveCategory(uniqueCategories[0])
  },[singleOrder])

  function getRandomDelay() {
    return Math.random() * 1000; // Generiše slučajno kašnjenje u opsegu od 0ms do 1000ms
  }
  
  return (
    <div>
      <OrderHistoryX>
      <Title >{t('OrderHistory.ALL_ORDERS')}</Title>
      {orderHistory && orderHistory.length > 0 ? 
      <>
      <OrderHistoryContainer>
      {orderHistory.map((item, index) => (
      <OrderHistoryCard key={index}
      className={activeCard === index ? 'active' : ''}
      onClick={() => {
        if (activeCard == index) {setActiveCard(null); setSingleOrder(null); return;}
        setActiveCard(index)
        setSingleOrder(item)
      }}
      >
        <OrderHistoryHead>

          <WrapBr >
          <OrderHistoryTitle>{item.order_id}</OrderHistoryTitle>
          <Br />
          </WrapBr>
        </OrderHistoryHead>
        <OrderHistoryBody>
        <OrderHistoryP>{t('OrderHistory.ORDER_DATE')}</OrderHistoryP>
          <OrderHistoryP>
        {item.order_date}
        </OrderHistoryP>
        </OrderHistoryBody>
      </OrderHistoryCard>
    ))}
          </OrderHistoryContainer>
          </>
      : 
      <P>{t('MyAccount.NO_ORDERS')}</P>
      }
      </OrderHistoryX>
      {singleOrder && categories &&
      <OrderContainer activeCategory={activeCategory}>
          <SubTitle style={{margin:"0px", padding:"10px 0", textAlign:"center"}}>{singleOrder.order_date}</SubTitle>
          <Title style={{margin:"0px", padding:"0px", textAlign:"center"}}>{singleOrder.order_id}</Title>
          <div style={{padding:"0px 10px"}}>
          <OrderTitle style={{margin:"0px" , marginTop:"20px", padding:"0px 0", textAlign:"left"}}>{t('OrderHistory.ORDER_DETAILS')}</OrderTitle>
          <div style={{display:"flex", alignItems:"center", justifyContent:"space-around"}}>
            <OrderTitle style={{margin:"0px", padding:"0px"}}>{t('OrderHistory.TOTAL_PRICE')}</OrderTitle><Text>{singleOrder.total_price.toLocaleString()}RSD</Text>
          </div>
          
          <div style={{display:"flex", alignItems:"center", justifyContent:"space-around"}}>
            <OrderTitle style={{margin:"0px", padding:"0px"}}>{t('OrderHistory.SHIPPING_COST')}</OrderTitle>
            {singleOrder.shipping_cost == 0 ?
            <Text style={{color:"teal"}}>{t('OrderHistory.FREE_SHIPPING')}</Text>
            :
            <Text>{singleOrder.shipping_cost.toLocaleString()}RSD</Text>
            }
          </div>
          
          </div>
          <OrderHead>
          {activeCategory && categories.map((category, index) => (
          <H3 key={index} style={activeCategory === category ? {} : {color:"#777"}} onClick={()=>setActiveCategory(category)}>
              {category}
          </H3>
          ))}
          </OrderHead>
          <OrderBodyX>
          { singleOrder.order_items.map((item, index)=>(
             item.article.category === activeCategory &&(
          <OrderBody key={index}>
            <Figure color={item.article.color}
            style={{
      animationDelay: `${getRandomDelay()}ms`
    }}
            >
              <Image src={item.article.images[0].image_url}/>
            </Figure>
            <OrderTitle style={{margin:"0px", padding:"10px 0", textAlign:"center"}}>{item.article.title}</OrderTitle>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px", gap:"20px"}}>
              <OrderP style={{}}>{t('OrderHistory.QUANTITY')} </OrderP>
              <OrderP style={{color:"#171717"}}>{item.quantity} </OrderP>
            </div>
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"10px", gap:"20px"}}>
              <OrderP style={{}}>{t('OrderHistory.PRICE')} </OrderP>
              <OrderP style={{color:"#171717"}}>{item.discounted_price.toLocaleString()}RSD </OrderP>
            </div>
          </OrderBody>
        )
          ))}
          </OrderBodyX>
      </OrderContainer>
      }
    </div>
  )
}

export default OrderHistory