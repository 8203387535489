import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import ProductCard from "./ProductCard";
import "../assets/css/SlickSlider.css"

const Component = styled.div`
`;
const SlideContainer = styled.div`
  display:flex;
  padding:0px;
  margin:0px;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;
const PrevArrow = styled.div`
  position: absolute;
  top: 95%;
  left: 10px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  //background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;
const NextArrow = styled.div`
  position: absolute;
  top: 95%;
  right: 10px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover{
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;

const ProductCardSlider = ({sliderContent}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const settings = {
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Component>
      {isLoaded && (
        <Slider {...settings}>
           {sliderContent.map((item) => (
          <SlideContainer key={item.id}>
             <ProductCard item={item} /> 
            
          </SlideContainer>
        ))}
           
        </Slider>
      )}
    </Component>
  );
};

export default ProductCardSlider;
