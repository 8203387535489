import { Info } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  background-color: #009b9b;
  position: relative;
  color: white;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
`;
const Head = styled.div`
  padding: 2px 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin: 0px 10px;
    gap: 10px;
  }
`;
const Title = styled.h2`
  font-size: 14px;
  font-weight: 400;
  font-family: "Righteous";
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;
const Icon = styled.div`
  color: #fff;
`;
const Body = styled.div`
  position: absolute;
  z-index: 2;
  background-color: #009b9b;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
`;
const Text = styled.h3`
  padding: 10px;
  font-size: 12px;
  font-weight: 200;
`;

const Multibuy = () => {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(false);
  return (
    <Container>
      <Head
        onMouseEnter={() => setIsHidden(true)}
        onMouseLeave={() => setIsHidden(false)}
        isHidden={isHidden}
        onClick={() => setIsHidden(!isHidden)}
      >
        <Title>{t("Multibuy.TITLE")}</Title>
        <Icon>
          <Info />
        </Icon>
      </Head>
      {isHidden ? (
        <>
          <Body>
            <Text>
              <b>{t("Multibuy.FREE_SHIPPING")}</b> {t("Multibuy.ON_ANY")}{" "}
              <b>5+</b> {t("Multibuy.PRODUCTS")}
            </Text>
            <Text>
              <b>{t("Multibuy.FREE_SHIPPING")}</b> +<b>10%</b>{" "}
              {t("Multibuy.OFF")} {t("Multibuy.ON_ANY")} <b>10+</b>{" "}
              {t("Multibuy.PRODUCTS")}
            </Text>
            <Text>
              <b>22%</b> {t("Multibuy.OFF")} {t("Multibuy.ON_ANY")} <b>50+</b>{" "}
              {t("Multibuy.PRODUCTS")}
            </Text>
          </Body>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Multibuy;
