import { Tune, Add, Remove, Menu, Close, Storefront, PermIdentity } from "@mui/icons-material";
import styled , {keyframes, css} from "styled-components";
import { useContext, useState } from 'react';
import { useNavigation } from "../../utils/navigation";
import anime from 'animejs';
import {Link} from 'react-router-dom'
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";



const fadeInSubmenu = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const fadeOutSubmenu = keyframes`
  from {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    display:none;
  }
`;
const slideInRight = keyframes`
from {
  transform: translateX(0);
  opacity: 1;
}

to {
  transform: translateX(-100%);
  opacity: 0;
}
`;
const slideInLeft = keyframes`
from {
  transform: translateX(-100%);
  opacity: 0;
}

to {
  transform: translateX(0);
  opacity: 1;
}
`;
const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;
const ContainerX = styled.div`

`;

const ContainerTitle = styled.h2`
  padding:0;
  margin:0;
    font-size: 12px;
    font-weight: 400;
    color: white;
`;
const ContainerLink = styled.div`
  margin: 0;
  display:flex;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  flex-direction: column;
  &:hover{
    color: #52c5c5;;
  }
`;

const Container = styled.div`
${({ isOpen }) => isOpen
  ? css`
    display: none;
  `
  : css`
   display:block;
   animation: ${fadeIn} 0.5s ease-in-out both;
  `}
  padding: 0;
  border: 0;
  position:fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  font-weight: 700;
  height:100vh;
  background-color: rgba(0, 0, 0, 0.3);
  @media only screen and (max-width:768px){
  }
  z-index:99999;
`;
const Box = styled.div`
overflow: auto; /* omogućava skrolanje unutar div-a */
-webkit-overflow-scrolling: touch;
${({ isOpen }) => isOpen
  ? css`
    animation: ${slideInRight} 1.0s ease-in-out both;
  `
  : css`
    animation: ${slideInLeft} 0.5s ease-in-out both;
  `}
  width: 25vw;
  background-color: teal;
  height:100vh;
  padding:0px;
  @media only screen and (max-width:768px){
      height:100vh;
      width:100vw;
  }
  top:0;
`;






const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1px solid #ffffff76;
    transition: .4s all cubic-bezier(.22,1,.36,1);
    &:active{
        background-color: #00f9c786;
    }
    padding:20px;
`;
const MainIconTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
`;
const MainTitle = styled.h1`
 margin:0;
    padding:0;
    flex: 1 1 auto;
    font-size: 15px;
    line-height: 1.4;
    font-weight: 700;
    cursor: pointer;
    color: white;
    &:hover{
        color:#00F9C7;
    }
    text-transform: uppercase;
`;

const SubmainContainer = styled.div`
  
    background-color: #00AF8C;
    padding:0px;
    position: relative;
    display:block;
${({ show }) => show
  ? css`
    //display:block;
    opacity:1;
    animation: ${fadeInSubmenu} 0.6s ease-out ;
  `
  : css`
    animation: ${fadeOutSubmenu} 0.6s ease-out;
    display:none;
    //opacity:0;
  `}

`;
const SubmainTitle= styled.h3`
    padding:10px 30px;
    font-size: 13px;
    line-height: 1.4;
    font-weight: 700;
    cursor: pointer;
    color: white;
    border-bottom: 2px solid #ffffff76;
    width:80vw;
    margin: 0 auto;
`;

const ToggleNavbar = () => {
  const [isOpenContainer, setIsOpenContainer] = useState(true);
  const [isOpenBox, setIsOpenBox] = useState(true);
  const [isBoxAnimating, setIsBoxAnimating] = useState(false);
  const { goToShop } = useNavigation();
  const [animatedGrid, setAnimatedGrid] = useState(false);
  const { user, logoutUser } = useContext(AuthUserContext);
  const [isFirstIconVisible, setIsFirstIconVisible] = useState([true, true]);
  const [items, setItems] = useState([false, false]);

  const toggleBox = () => {
    if (isBoxAnimating) {
      return;
    }
    setIsOpenBox(!isOpenBox);
    setIsBoxAnimating(true);
    if (isOpenContainer === false) {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 1000); // 1400ms is equal to animation duration in milliseconds
    } else {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 10);
    }

    const targets = document.querySelectorAll('.animated-div');
    if (!animatedGrid) {
      anime({
        targets: '.animated-div:nth-child(even)',
        opacity: 0,
        direction: 'alternate',
        loop: false,
        margin: '4px',
      });
    } else {
      anime({
        targets,
        opacity: 1,
        margin: '0px',
        direction: 'alternate',
        loop: false,
        margin: '3px',
      });
    }
    setAnimatedGrid(!animatedGrid);
  };

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClick = (index) => {
    const newIsFirstIconVisible = [...isFirstIconVisible];
    newIsFirstIconVisible[index] = !newIsFirstIconVisible[index];
    setIsFirstIconVisible(newIsFirstIconVisible);

    const newItems = [...items];
    newItems[index] = !newItems[index];
    setItems(newItems);
  };

  const { t } = useTranslation();

  return (
    <ContainerX>
      <ContainerLink onClick={toggleBox}>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'repeat(3, 1fr)',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridGap: '0px',
            width: '30px',
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
            <div
              key={item}
              className="animated-div"
              style={{ backgroundColor: 'white', height: '4px', width: '4px', margin: '3px' }}
            />
          ))}
        </div>
        <ContainerTitle>{t('ToggleNavbar.ContainerTitle')}</ContainerTitle>
      </ContainerLink>
      <Container onClick={toggleBox} isOpen={isOpenContainer} isBoxAnimating={isBoxAnimating}>
        <Box isOpen={isOpenBox} isBoxAnimating={isBoxAnimating} onClick={handleStopPropagation}>
          <MainContainer>
            <MainIconTitle onClick={() => goToShop()}>
              <Storefront style={{ color: '#00F9C7', fontSize: '35px' }} />
              <MainTitle>{t('ToggleNavbar.ShopTitle')}</MainTitle>
            </MainIconTitle>
          </MainContainer>
          <MainContainer key={0} onClick={() => handleClick(0)}>
            <MainIconTitle>
              <Tune style={{ color: '#00F9C7', fontSize: '35px' }} />
              <MainTitle>{t('ToggleNavbar.SnusTitle')}</MainTitle>
            </MainIconTitle>
            {isFirstIconVisible[0] ? <Add style={{ fontSize: '25px' , color:"#56e9e9"}} /> : <Remove style={{ fontSize: '25px', color:"#56e9e9" }} />}
          </MainContainer>
          <SubmainContainer show={items[0]} onClick={toggleBox}>
            <Link to="/snus-prodaja/velo" style={{ textDecoration: 'none' }}>
              <SubmainTitle>VELO</SubmainTitle>
            </Link>
            <Link to="/snus-prodaja/pablo" style={{ textDecoration: 'none' }}>
              <SubmainTitle>PABLO</SubmainTitle>
            </Link>
            <Link to="/snus-prodaja/grant" style={{ textDecoration: 'none' }}>
              <SubmainTitle>GRANT</SubmainTitle>
            </Link>
            <Link to="/snus-prodaja/lyft" style={{ textDecoration: 'none' }}>
              <SubmainTitle>LYFT</SubmainTitle>
            </Link>
            <Link to="/snus-prodaja/fedrs" style={{ textDecoration: 'none' }}>
              <SubmainTitle>FEDRS</SubmainTitle>
            </Link>
            <Link to="/snus-prodaja/iceberg" style={{ textDecoration: 'none' }}>
              <SubmainTitle>ICEBERG</SubmainTitle>
            </Link>
            <Link to="/snus-prodaja/siberia" style={{ textDecoration: 'none' }}>
              <SubmainTitle>SIBERIA</SubmainTitle>
            </Link>
          </SubmainContainer>

          <MainContainer key={1} onClick={() => handleClick(1)}>
            <MainIconTitle>
              <PermIdentity style={{ color: '#00F9C7', fontSize: '35px' }} />
              <MainTitle>{t('ToggleNavbar.AccountTitle')}</MainTitle>
            </MainIconTitle>
            {isFirstIconVisible[1] ? <Add style={{ fontSize: '25px', color:"#56e9e9" }} /> : <Remove style={{ fontSize: '25px' , color:"#56e9e9"}} />}
          </MainContainer>
          {!user ? (
            <SubmainContainer show={items[1]} onClick={toggleBox}>
              <Link to="/prijava" style={{ textDecoration: 'none' }}>
                <SubmainTitle>{t('ToggleNavbar.LoginTitle')}</SubmainTitle>
              </Link>
              <Link to="/registracija" style={{ textDecoration: 'none' }}>
                <SubmainTitle>{t('ToggleNavbar.RegisterTitle')}</SubmainTitle>
              </Link>
            </SubmainContainer>
          ) : (
            <SubmainContainer show={items[1]} onClick={toggleBox}>
              <SubmainTitle onClick={() => logoutUser()}>{t('ToggleNavbar.LogoutTitle')}</SubmainTitle>
            </SubmainContainer>
          )}
        </Box>
      </Container>
    </ContainerX>
  );
};

export default ToggleNavbar;