import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Container = styled.div`
  display: ${(props) => (props.percentage == 0 ? "none" : "flex")};
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #f6f6f6;
  border-radius: var(--border-radius-small);
  box-shadow: 0 2px 4px rgba(251, 225, 225, 0.1);
  position: fixed;

  width: 100%;

  height: 40px;
  z-index: var(--zindex-fixed);
  font-size: 12px;
  color: var(--primary-color);

  top: ${(props) => (props.$isScrolled ? "70px" : "70px")};
`;

const BarWrapper = styled.div`
  width: 90%;
  max-width: 1400px;
  background-color: red;
  position: relative;
  background-color: #fff;
  height: 8px;
  border-radius: 40px;
  margin-top: 14px;
`;
const BarScore = styled.div`
  position: absolute; // Make BarScore absolute to fill the Container from start
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: teal;
  max-width: 1400px;
  width: ${(props) => props.percentage || 0}%; // Use the percentage prop
  border-radius: 40px;
  transition: width 0.3s;
`;

const ShippingInfo = styled.div`
  position: absolute; // Make BarScore absolute to fill the Container from start
  top: -14px;
  left: ${(props) => props.percentage || 0}%;
  /* /transform: rotate(-4deg) translateX(-30%); */
  z-index: 1; // Make sure this text appears above the BarScore
  font-weight: bold;
  /* @media (min-width: 768px) {
    left: ${(props) =>
    props.percentage > 15
      ? props.percentage + 10
      : props.percentage || 0}%; // Use the percentage prop
  } */
  @media (max-width: 768px) {
    left: ${(props) =>
      props.percentage > 40
        ? props.percentage - 30
        : props.percentage || 0}%; // Use the percentage prop
  }
  opacity: ${(props) => (!props.free ? "1" : "0")};
  transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
  white-space: nowrap;
  background-color: #f6f6f6;
  z-index: 3;
`;

const BarValue = styled.div`
  z-index: 1;
  position: absolute;
  right: 0px;
  top: -14px;
  font-weight: bold;
  opacity: ${(props) => (props.free ? "1" : "0.4")};
`;

const DeliveryBar = ({ isScrolled, position }) => {
  const { t } = useTranslation();
  const freeShippingItemThreshold = 5;
  const cartItems = useSelector((state) => state.cart.itemsList);

  // Izračunavanje ukupnog broja artikala u korpi
  const cartItemsCount = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const percentage = Math.min(
    (cartItemsCount / freeShippingItemThreshold) * 100,
    100
  );

  return (
    <Container
      $isScrolled={isScrolled}
      position={position}
      percentage={percentage}
    >
      <BarWrapper>
        <BarScore percentage={percentage} />
        <ShippingInfo
          free={cartItemsCount >= freeShippingItemThreshold}
          percentage={percentage}
        >
          {t("DeliveryBar.SHIPPING_COST", {
            cost:
              cartItemsCount >= freeShippingItemThreshold
                ? t("DeliveryBar.FREE")
                : "~600rsd",
          })}
        </ShippingInfo>
        <BarValue free={cartItemsCount >= freeShippingItemThreshold}>
          {cartItemsCount >= freeShippingItemThreshold
            ? t("DeliveryBar.FREE")
            : t("DeliveryBar.FREE")}
        </BarValue>
      </BarWrapper>
      <div>
        {cartItemsCount >= freeShippingItemThreshold ? (
          <span>{t("DeliveryBar.FREE_SHIPPING_UNLOCKED")}</span>
        ) : (
          <span>
            {t("DeliveryBar.ADD_MORE_ITEMS", {
              count: freeShippingItemThreshold - cartItemsCount,
            })}
          </span>
        )}
      </div>
    </Container>
  );
};

export default DeliveryBar;
