import styled, {keyframes, css} from "styled-components";
import {useState, useEffect} from "react"
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";
import { useNavigation } from "../../utils/navigation";
import { CallMade, Search } from "@mui/icons-material";
import APIService from "../../services/APIService";

const fadeInTop = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const fadeOutBottom = keyframes`
  from {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const SearchInput = styled.input`
    min-width: 240px;
    border:none;
    outline:none;
    ::placeholder {
        font-size: 12px;
        font-weight: 100;
        color: #cacaca;
  }
    font-size: 12px;
    font-weight: 400;
    background:none;
    color:white;
`;

const Container = styled.div`
  ${({ isOpen }) => isOpen
    ? css`
      display: none;
    `
    : css`
        display:block;
    `}

    color: black;
    padding: 0;
    //margin-top:70px;
    border: 0;
    outline: none;
    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-weight: 700;
    width: 100vw;
    height:100vh;
    background-color: transparent;
    z-index:99999;
    
    @media only screen and (max-width:768px){
       display:none;
    }
`;
const Box = styled.div`
    overflow: auto; /* omogućava skrolanje unutar div-a */
    -webkit-overflow-scrolling: touch;
    margin-top:70px;
  ${({ isOpen }) => isOpen
    ? css`
      animation: ${fadeOutBottom} 0.5s ease-in-out both;
    `
    : css`
      animation: ${fadeInTop} 0.5s ease-in-out both;
    `}
    position:absolute;
    left:15%;
    width: 70%;
    //min-height:50vh;
    max-height:60vh;
    //margin:0;
    background-color: #F1F4F8;
    padding:0px;
    top:0;
    margin-right:15%;
    border:1px solid teal;
    
`;
const BoxWrapper = styled.div`
  padding:10px;
`
const  ProductsContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(3, 1fr); 
    gap:10px;
    
`
const ProductContainer = styled.div`
    display: flex;
    background-color: #fff;
    height:85px;
    border-radius:5px;
    overflow: hidden;
    cursor:pointer;
    border: 2px solid #fff;
    &:hover{
        border: 2px solid #03bbbb;
    }
    &:before {
        border: 2px solid transparent;
    }
    &hover:before{
        border-color:#009b9b;
    }
    img {
    transition: transform 0.3s ease-out;
    }
  &:hover img {
    transform: scale(1.1);
  }
    cursor:pointer;
`;
const ProductImageContainer = styled.div`
    flex:1;
    margin:5px;
    
`;
const ProductPicture = styled.picture`
    width: 100%;
    height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
    border-radius: 10px;
  
`;
const ProductImage = styled.img`
    height: 65px;
    //width: 70px;
`;
const ProductInfoContainer =styled.div`
    flex:2;
    padding-left:5px;
    

`;
const ProductTitle = styled.h1`
    margin:0;
    padding-top:10px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 100;
    color: #1f1f1f;
    display:flex;
    align-items: left;
    text-transform:uppercase;
    margin-bottom: 10px;
    font-family: "Righteous";
`;
const ProductPrice = styled.h2`
    margin:0;
    padding:0px;
    font-size: 14px;
    line-height: 1.0;
    font-weight: 700;
    color: black;
    display:flex;
    text-transform:uppercase;
    margin-right:20px;
`;
const BottomTitle = styled.h1`
    font-weight: 100;
    font-size: 26px;
    letter-spacing: 0px;
    margin:0;
    padding:0;
    margin: 10px 0px 20px 0px;
    text-transform: uppercase;
    font-family: "Righteous";
    color: #1f1f1f;
`;

const Button = styled.button`
    line-height: 1.25;
    letter-spacing: 0;
    font-weight: 700;
    color: #1f1f1f;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    text-align: left;
    margin: 0;
    background-color: transparent;
    border:none;
    padding:10px;
    display:flex;
    justify-content: center;
    align-items: center;
`;
const Icon = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ rotated }) => (rotated ? 'rotate(45deg)' : 'rotate(0)')};
`;

const DesktopSearchBar = () => {
  const { t } = useTranslation();
  const [rotated, setRotated] = useState(false);

  const [isOpenContainer, setIsOpenContainer] = useState(true);
  const [isOpenBox, setIsOpenBox] = useState(true);

  const [isBoxAnimating, setIsBoxAnimating] = useState(false);
    const [searchText,setSearchText] = useState('')
    const [searchProducts, setSearchProducts] = useState([])
    const navigate = useNavigate();
        useEffect(() => {
            const delayDebounceFn = setTimeout(() => {
            if (searchText !== '') {
            APIService.searchArticles(searchText, 'title', [], null)
                .then(resp => {
                    setSearchProducts(resp.slice(0, 9));
                });
            }
            }, 1500)
            return () => clearTimeout(delayDebounceFn)
        }, [searchText])

        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && searchText !== '') {
            const queryParams = new URLSearchParams({
                search: searchText
            }).toString();
            navigate('/search?' + queryParams);
            window.location.reload()
            }
          }
    useEffect(()=>{
        if(isOpenContainer === true && searchProducts.length){
            toggleBox()
        }
    },[searchProducts])
  const toggleBox = () => {
    if (isBoxAnimating) {
      return;
    }
    setIsOpenBox(!isOpenBox);
    setIsBoxAnimating(true);
    if (isOpenContainer === false) {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 500);
    } else {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 10);
    }
  };
  const { goToProduct, goToShop } = useNavigation();

  const handleProductCardClick = (category, title) => {
    toggleBox();
    goToProduct(category, title);
  };
  return (
    <>
    <>
    <SearchInput 
        type="input" placeholder={t('MainNavbar.SearchInput.placeholder')} value={searchText} 
        onChange={e=>setSearchText(e.target.value)}
        onKeyDown={handleKeyDown}
    ></SearchInput>
    <Search style={{color:"white", fontSize:"30"}} />
    </>
        <Container onClick={()=>{toggleBox(); setSearchText('')}} isOpen={isOpenContainer} isBoxAnimating={isBoxAnimating}>
            <Box isOpen={isOpenBox} isBoxAnimating={isBoxAnimating} onClick={(event) => event.stopPropagation()}>
                <BoxWrapper>
                <BottomTitle>{t('SearchMenu.BottomTitle')}:</BottomTitle>
                    <ProductsContainer>
                    {searchProducts?.map((item, index) => {
              const images = item.images.map((img) => img.image_url);
              const primaryImage = images.find(
                (img) => item.images.find((i) => i.is_primary_image).image_url === img
              );

              return (
                <ProductContainer
                  key={item.id}
                  onClick={() =>
                    handleProductCardClick(
                      item.category.toLowerCase(),
                      item.title.toLowerCase().replace(/ /g, '-'),
                      item.id
                    )
                  }
                >
                  <ProductImageContainer>
                    <ProductPicture>
                      <ProductImage src={primaryImage} alt={item.title} />
                    </ProductPicture>
                  </ProductImageContainer>
                  <ProductInfoContainer>
                    <ProductTitle>{item.category} {item.title}</ProductTitle>
                    <ProductPrice>{item.price} RSD</ProductPrice>
                  </ProductInfoContainer>
                </ProductContainer>
              );
            })}
                    </ProductsContainer>
            <Button
            onMouseEnter={() => setRotated(true)}
            onMouseOut={() => setRotated(false)}
            onClick={() => goToShop()}
            >
                {t('SearchMenu.ButtonText')}
                <Icon rotated={rotated}>
                    <CallMade />
                </Icon>
            </Button>
        
                </BoxWrapper>
            </Box>
        </Container>
    </>
  );
};

export default DesktopSearchBar;