import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Autosuggest from "react-autosuggest";
import placesData from "./places.json";
import municipalitiesData from "./municipalities.json";
import streetsData from "./streets.json";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 80%;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const SuggestionsContainer = styled.div`
  position: absolute;

  z-index: 2;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  //padding: 0 10px;
  //min-width: 400px;
  @media only screen and (max-width: 768px) {
    left: 0;
  }
`;

const SuggestionItem = styled.div`
  padding: 4px 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const StyledInput = styled.input`
  outline: none;
  padding: 15px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: black;
  border: none;
  background-color: rgb(230, 230, 230);
  margin: 0px;
  height: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-left: -16px;

  ::placeholder {
    font-size: 12px;
    font-weight: 400;
    color: rgb(97, 97, 97);
  }
`;

const joinPlaceWithMunicipality = () => {
  return placesData.map((place) => {
    const municipality = municipalitiesData.find(
      (m) => m.MunicipalitiesId === place.Municipalities
    );
    return {
      ...place,
      MunicipalityName: municipality ? municipality.MunicipalitiesName : "",
    };
  });
};

const getPlaceSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const joinedData = joinPlaceWithMunicipality();

  const filteredSuggestions =
    inputLength === 0
      ? []
      : joinedData
          .filter((place) => place.PlaceName.toLowerCase().includes(inputValue))
          .slice(0, 100);

  //console.log("Filtered place suggestions:", filteredSuggestions);
  return filteredSuggestions;
};

const getZipCodeSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const joinedData = joinPlaceWithMunicipality();

  const filteredSuggestions =
    inputLength === 0
      ? []
      : joinedData
          .filter((place) => place.Zip.toString().includes(inputValue))
          .slice(0, 100);

  //console.log("Filtered zip code suggestions:", filteredSuggestions);
  return filteredSuggestions;
};

const getStreetSuggestions = (value, streets) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const filteredSuggestions =
    inputLength === 0
      ? []
      : streets
          .filter((street) => street.name.toLowerCase().includes(inputValue))
          .slice(0, 100);

  //console.log("Filtered street suggestions:", filteredSuggestions);
  return filteredSuggestions;
};

const getSuggestionValue = (suggestion) =>
  suggestion.PlaceName || suggestion.name;

const renderSuggestion = (suggestion) => (
  <SuggestionItem>
    {suggestion.PlaceName
      ? `${suggestion.PlaceName} - ${
          suggestion.MunicipalityName
        } (${suggestion.Zip.toString()})`
      : suggestion.name}
  </SuggestionItem>
);

const renderSuggestionsContainer = ({ containerProps, children, query }) => (
  <SuggestionsContainer {...containerProps}>{children}</SuggestionsContainer>
);

const renderInputComponent = (inputProps) => <StyledInput {...inputProps} />;

function Places({
  placeValue,
  setPlaceValue,
  zipCodeValue,
  setZipCodeValue,
  streetValue,
  setStreetValue,
}) {
  const { t } = useTranslation();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [streets, setStreets] = useState([]);
  //const [placeValue, setPlaceValue] = useState("");
  //const [zipCodeValue, setZipCodeValue] = useState("");
  //const [streetValue, setStreetValue] = useState("");
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [zipCodeSuggestions, setZipCodeSuggestions] = useState([]);
  const [streetSuggestions, setStreetSuggestions] = useState([]);

  useEffect(() => {
    if (selectedPlace) {
      const relatedStreets = streetsData.filter(
        (street) => street.placeId === selectedPlace.PlaceId
      );
      setStreets(relatedStreets);
    } else {
      setStreets([]);
    }
  }, [selectedPlace]);

  const debouncedGetPlaceSuggestions = debounce((value) => {
    setPlaceSuggestions(getPlaceSuggestions(value));
  }, 300);

  const debouncedGetZipCodeSuggestions = debounce((value) => {
    setZipCodeSuggestions(getZipCodeSuggestions(value));
  }, 300);

  const debouncedGetStreetSuggestions = debounce((value) => {
    setStreetSuggestions(getStreetSuggestions(value, streets));
  }, 300);

  const onPlaceChange = (event, { newValue }) => {
    setPlaceValue(newValue);
  };

  const onZipCodeChange = (event, { newValue }) => {
    setZipCodeValue(newValue);
  };

  const onStreetChange = (event, { newValue }) => {
    setStreetValue(newValue);
  };

  const onPlaceSuggestionsFetchRequested = ({ value }) => {
    debouncedGetPlaceSuggestions(value);
  };

  const onZipCodeSuggestionsFetchRequested = ({ value }) => {
    debouncedGetZipCodeSuggestions(value);
  };

  const onStreetSuggestionsFetchRequested = ({ value }) => {
    debouncedGetStreetSuggestions(value);
  };

  const onPlaceSuggestionsClearRequested = () => {
    setPlaceSuggestions([]);
  };

  const onZipCodeSuggestionsClearRequested = () => {
    setZipCodeSuggestions([]);
  };

  const onStreetSuggestionsClearRequested = () => {
    setStreetSuggestions([]);
  };

  const onPlaceSelected = (event, { suggestion }) => {
    setSelectedPlace(suggestion);
    setZipCodeValue(suggestion.Zip.toString());
  };

  const onZipCodeSelected = (event, { suggestion }) => {
    setSelectedPlace(suggestion);
    setPlaceValue(suggestion.PlaceName);
    setZipCodeValue(suggestion.Zip.toString());
  };

  const placeInputProps = {
    //placeholder: "Unesite naziv mesta",
    value: placeValue,
    onChange: onPlaceChange,
  };

  const zipCodeInputProps = {
    //placeholder: "Unesite zip kod mesta",
    value: zipCodeValue,
    onChange: onZipCodeChange,
  };

  const streetInputProps = {
    //placeholder: "Unesite naziv ulice",
    value: streetValue,
    onChange: onStreetChange,
    //disabled: !selectedPlace,
  };

  const clearPlaceAndZipCode = () => {
    setSelectedPlace(null);
    setPlaceValue("");
    setZipCodeValue("");
  };

  return (
    <Container>
      <Label htmlFor="place" className="login-label">
        {t("Register.CITY_LABEL")}
      </Label>
      <Autosuggest
        suggestions={placeSuggestions}
        onSuggestionsFetchRequested={onPlaceSuggestionsFetchRequested}
        onSuggestionsClearRequested={onPlaceSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={placeInputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onPlaceSelected}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />

      <Label htmlFor="zipCode" className="login-label">
        {t("Register.POSTAL_CODE_LABEL")}
      </Label>
      <Autosuggest
        suggestions={zipCodeSuggestions}
        onSuggestionsFetchRequested={onZipCodeSuggestionsFetchRequested}
        onSuggestionsClearRequested={onZipCodeSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={zipCodeInputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onZipCodeSelected}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />

      <Label htmlFor="street" className="login-label">
        {t("Register.ADDRESS1_LABEL")}
      </Label>
      <Autosuggest
        suggestions={streetSuggestions}
        onSuggestionsFetchRequested={onStreetSuggestionsFetchRequested}
        onSuggestionsClearRequested={onStreetSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={streetInputProps}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
    </Container>
  );
}

export default Places;
