import { useState, createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import APIService from "../services/APIService";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [nicotine, setNicotine] = useState([]);
  const [recommendedArticles, setRecommendedArticles] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [chosenProducts, setChosenProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clickedNicotineORCategory, setClickedNicotineORCategory] =
    useState(false);
  const [popUp, setPopUp] = useState(false);

  const getProductByUrlSlug = (slug) => {
    if (!allProducts.length) {
      return;
    }
    const product = allProducts.find(
      (a) =>
        `${a.category.toLowerCase().replace(/ /g, "-")}-${a.title
          .toLowerCase()
          .replace(/ /g, "-")}` === slug
    );
    if (!product) {
      return;
    }
    const id = product.id;
    const temp_product = allProducts.find((p) => p.id === parseInt(id));
    setProduct(temp_product);
    //SAMO OVDE SAM DODAVAO getRecommendedByProductId
    getRecommendedByProductId(parseInt(id));
  };
  const getProductsByCategoryId = async (id) => {
    try {
      const response = await APIService.GetArticlesByCategoryId(id);
      setProducts(response);
      handleCategoryInitial(response[0].category);
    } catch (error) {
      console.error(error);
    }
  };
  const handleCategoryInitial = (value) => {
    setCategory((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((val) => val !== value);
      } else {
        return [value];
      }
    });
  };
  useEffect(() => {
    setChosenProducts(products);
  }, [products]);

  const loadData = async () => {
    try {
      const response = await APIService.GetArticles();
      setAllProducts(response);
    } catch (error) {
      console.error(error);
    }
    try {
      const response = await APIService.GetRecommended();
      setRecommendedArticles(response.slice(0, 7));
    } catch (error) {
      console.error(error);
    }
  };
  /*useEffect(()=>{
    // Funkcija za sortiranje po kategoriji
    const sortProductsByCategory = () => {
      const sorted = [...allProducts].sort((a, b) => {
        // Zamijenite 'category' sa stvarnim imenom atributa koji sadrži kategoriju
        const categoryA = a.category.toLowerCase();
        const categoryB = b.category.toLowerCase();
        if (categoryA > categoryB) {
          return -1;
        }
        if (categoryA < categoryB) {
          return 1;
        }
        return 0;
      });

      setAllProducts(sorted);
    };

    // Pozovite funkciju za sortiranje kada se promeni allProducts
    sortProductsByCategory();
  },[allProducts])*/

  useEffect(() => {
    if (nicotine.length || category.length) {
      setLoading(true);
      APIService.searchArticles("", "nicotine", nicotine, category).then(
        (resp) => {
          //setProducts(resp)
          setChosenProducts(resp);
          const timeoutId = setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      );
    } else if (clickedNicotineORCategory) {
      setChosenProducts(products);
      setClickedNicotineORCategory(false);
    }
  }, [nicotine, category]);

  const { t } = useTranslation();
  const handleSort = (selectedSort) => {
    if (selectedSort === t("DropDown.PRICE_ASC")) {
      setChosenProducts([...chosenProducts].sort((a, b) => b.price - a.price));
    } else if (selectedSort === t("DropDown.PRICE_DESC")) {
      setChosenProducts([...chosenProducts].sort((a, b) => a.price - b.price));
    } else if (selectedSort === "A-Z") {
      setChosenProducts(
        [...chosenProducts].sort((a, b) => a.title.localeCompare(b.title))
      );
    } else if (selectedSort === "Z-A") {
      setChosenProducts(
        [...chosenProducts].sort((a, b) => b.title.localeCompare(a.title))
      );
    } else if (selectedSort === t("DropDown.IN_STOCK")) {
      // Sorting by availability
      setChosenProducts(
        [...chosenProducts].sort((a, b) => {
          return a.state === "Dostupno" ? -1 : 1;
        })
      );
    }
  };
  const handleNicotineChange = (value) => {
    setNicotine((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((val) => val !== value);
      } else {
        return [...prevState, value];
      }
    });
    setClickedNicotineORCategory(true);
  };

  const handleCategoryChange = (value) => {
    setCategory((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((val) => val !== value);
      } else {
        return [...prevState, value];
      }
    });
    setClickedNicotineORCategory(true);
  };

  const fetchData = async () => {
    try {
      const response = await APIService.GetArticles();
      setProducts(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getRecommendedByProductId = async (id) => {
    try {
      const response = await APIService.getRecommendedByProductId(id);
      setRecommendedArticles(response.slice(1, 6));
    } catch (error) {
      console.error(error);
    }
  };

  let contextData = {
    products: products,
    setProducts: setProducts,
    fetchData: fetchData,
    handleNicotineChange: handleNicotineChange,
    nicotine: nicotine,
    handleCategoryChange: handleCategoryChange,
    category: category,
    loadData: loadData,
    recommendedArticles: recommendedArticles,
    getProductByUrlSlug: getProductByUrlSlug,
    product: product,
    allProducts: allProducts,
    getProductsByCategoryId: getProductsByCategoryId,
    loading: loading,
    setChosenProducts: setChosenProducts,
    chosenProducts: chosenProducts,
    setPopUp: setPopUp,
    popUp: popUp,
    handleSort: handleSort,
  };
  return (
    <ProductContext.Provider value={contextData}>
      {children}
    </ProductContext.Provider>
  );
};
