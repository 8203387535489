import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useContext } from "react";
import { AuthUserContext } from "../context/AuthUserContext";
import { useNavigation } from "../utils/navigation";
import APIService from "../services/APIService";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutContext } from "../context/CheckoutContext";
import { cartActions } from "../store/cart-slice";
import ReactGA from "react-ga4";
import { CartContext } from "../context/CartContext";
import { useTranslation } from "react-i18next";
import Places from "./customer/Places";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
`;
const DesktopCheckoutDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
    /* Stilovi za mobilne uređaje */
  }
`;
const CheckoutForm = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthUserContext);

  const { goToHome } = useNavigation();
  const [name, setName] = useState(() => (user ? user.first_name : ""));
  const [errorNameText, setErrorNameText] = useState("");

  const [surname, setSurname] = useState(() => (user ? user.last_name : ""));
  const [errorSurnameText, setErrorSurnameText] = useState("");

  const [email, setEmail] = useState(() => (user ? user.email : ""));
  const [ErrorEmailText, setErrorEmailText] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [address1, setAddress1] = useState(() => (user ? user.address : ""));
  const [errorMsgAddress1, setErrorMsgAddress1] = useState("");

  const [city, setCity] = useState(() =>
    user ? user.city + " " + user.house_number : ""
  );
  const [errorMsgCity, setErrorMsgCity] = useState("");

  const [postalCode, setPostalCode] = useState(() =>
    user ? user.postal_code : ""
  );
  const [errorMsgPostalCode, setErrorMsgPostalCode] = useState("");

  const [mobile, setMobile] = useState(() => (user ? user.phone : ""));
  const [errorMsgMobile, setErrorMsgMobile] = useState("");

  const [napomena, setNapomena] = useState("");

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.itemsList);
  const { setPurchaseData, submitted, setSubmitted } =
    useContext(CheckoutContext);
  const { totalDiscountedPrice } = useContext(CartContext);
  useEffect(() => {
    setName(user.first_name);
    setSurname(user.last_name);
    setEmail(user.email);
    setAddress1(user.address + " " + user.house_number);
    setCity(user.city);
    setPostalCode(user.postal_code);
    setMobile(user.phone);
  }, [user]);

  useEffect(() => {
    if (submitted) {
      const form = document.querySelector("form");
      if (form.checkValidity()) {
        const articles = cartItems.map((item) => {
          try {
            ReactGA.event({
              category: "narudžbina",
              action: "narudžbinaUspešna",
              label: `${item.article.category} ${item.article.title}`,
              value: item.article.discountPrice * item.quantity,
              quantity: item.quantity,
              currency: "din",
            });
          } catch (error) {
            console.error("Greška prilikom slanja događaja:", error);
          }
          return {
            article_id: item.article.id,
            quantity: item.quantity,
          };
        });
        APIService.checkout(
          name,
          surname,
          email,
          address1,
          mobile,
          napomena,
          articles,
          city,
          postalCode
        ).then((resp) => {
          setPurchaseData(resp);
          setSubmitted(false);
          try {
            ReactGA.event({
              category: "narudžbina",
              action: "Kupovina",
              label: email,
              value: totalDiscountedPrice, // Možete dodati vrednost narudžbine kao vrednost događaja
              currency: "din",
            });
          } catch (error) {
            console.error("Greška prilikom slanja događaja:", error);
          }
        });
        dispatch(cartActions.resetCart());
      } else {
        // show validation messages
        try {
          ReactGA.event({
            category: "narudžbina",
            action: "narudžbinaNeuspešna",
            label: "Greška u validaciji",
          });
        } catch (error) {
          console.error("Greška prilikom slanja događaja:", error);
        }
        setSubmitted(false);
        const firstInvalidField = form.querySelector(":invalid");
        if (firstInvalidField) {
          const yOffset = -140; // Pomeraj za 100 piksela unazad
          const y =
            firstInvalidField.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    }
  }, [submitted]);

  return (
    <Container>
      <form className="form1">
        <DesktopCheckoutDiv>
          <h1 className="login-title">{t("CheckoutForm.POTVRDA")}</h1>

          <label className="login-label">{t("CheckoutForm.YOUR_NAME")}</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="checkoutformjsx-input1"
            style={{ height: "8px" }}
            onFocus={(e) => {
              e.target.style.borderBottom = "1px solid aqua";
              setErrorNameText("");
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.style.borderBottom = "2px solid #991f39";
                setErrorNameText(t("CheckoutForm.REQUIRED_FIELD"));
              } else {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorNameText("");
              }
            }}
          />
          <motion.h3
            className="input-required-text"
            style={{ padding: "0", fontSize: "10px" }}
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: errorNameText !== "" ? 1 : 0,
              y: errorNameText !== "" ? 0 : 10,
            }}
            transition={{ duration: 0.3 }}
          >
            {errorNameText}
          </motion.h3>

          <label className="login-label">
            {t("CheckoutForm.YOUR_SURNAME")}
          </label>
          <input
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
            className="checkoutformjsx-input1"
            style={{ height: "8px" }}
            onFocus={(e) => {
              e.target.style.borderBottom = "1px solid aqua";
              setErrorSurnameText("");
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.style.borderBottom = "2px solid #991f39";
                setErrorSurnameText(t("CheckoutForm.REQUIRED_FIELD"));
              } else {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorSurnameText("");
              }
            }}
          />
          <motion.h3
            className="input-required-text"
            style={{ padding: "0", fontSize: "10px" }}
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: errorSurnameText !== "" ? 1 : 0,
              y: errorSurnameText !== "" ? 0 : 10,
            }}
            transition={{ duration: 0.3 }}
          >
            {errorSurnameText}
          </motion.h3>

          <label className="login-label">
            {t("CheckoutForm.EMAIL_ADDRESS")}
          </label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="checkoutformjsx-input1"
            style={{ height: "8px" }}
            // style for input when it's selected and empty
            onFocus={(e) => {
              e.target.style.borderBottom = "1px solid aqua";
              setErrorEmailText("");
            }}
            // style for input when it's not selected or has content
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.style.borderBottom = "2px solid #991f39";
                setErrorEmailText(t("CheckoutForm.REQUIRED_FIELD"));
              } else if (!emailRegex.test(e.target.value)) {
                e.target.style.borderBottom = "2px solid #991f39";
                setErrorEmailText(t("CheckoutForm.INVALID_EMAIL_ADDRESS"));
              } else {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorEmailText("");
              }
            }}
          />
          <motion.h3
            className="input-required-text"
            style={{ padding: "0", fontSize: "10px" }}
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: ErrorEmailText !== "" ? 1 : 0,
              y: ErrorEmailText !== "" ? 0 : 10,
            }}
            transition={{ duration: 0.3 }}
          >
            {ErrorEmailText}
          </motion.h3>

          <div className="login-br" />
          <h1 className="login-title">{t("CheckoutForm.YOUR_ADDRESS")}</h1>

          {/* <label className='login-label'
            >{t('CheckoutForm.STREET_AND_NUMBER')} </label>
            <input 
             type="text"
             value={address1}
             onChange={e => setAddress1(e.target.value)}
             required
             className='checkoutformjsx-input1'
             style={{height:"8px"}}
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorMsgAddress1('')
            }}
            onBlur={e => {
                 if (e.target.value === '') {
                    e.target.style.borderBottom = '2px solid #991f39';
                    setErrorMsgAddress1(t('CheckoutForm.REQUIRED_FIELD'))
                 }else{
                    e.target.style.borderBottom = '1px solid aqua';
                    setErrorMsgAddress1('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorMsgAddress1 !== '' ? 1 : 0, y: errorMsgAddress1 !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorMsgAddress1}</motion.h3>

        <label className='login-label'
            >{t('CheckoutForm.CITY_OR_SETTLEMENT')}</label>
            <input 
             type="text"
             value={city}
             onChange={e => setCity(e.target.value)}
             required
             className='checkoutformjsx-input1'
             style={{height:"8px"}}
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorMsgCity('')
            }}
            onBlur={e => {
                 if (e.target.value === '') {
                    e.target.style.borderBottom = '2px solid #991f39';
                    setErrorMsgCity(t('CheckoutForm.REQUIRED_FIELD'))
                 }else{
                    e.target.style.borderBottom = '1px solid aqua';
                    setErrorMsgCity('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorMsgCity !== '' ? 1 : 0, y: errorMsgCity !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorMsgCity}</motion.h3>

<label className='login-label'
            >{t('CheckoutForm.POSTAL_CODE')}</label>
            <input 
             type="text"
             value={postalCode}
             onChange={e => setPostalCode(e.target.value)}
             required
             className='checkoutformjsx-input1'
             style={{height:"8px"}}
             onFocus={e => { 
                 e.target.style.borderBottom = '1px solid aqua';
                 setErrorMsgPostalCode('')
            }}
            onBlur={e => {
                 if (e.target.value === '') {
                    e.target.style.borderBottom = '2px solid #991f39';
                    setErrorMsgPostalCode(t('CheckoutForm.REQUIRED_FIELD'))
                 }else{
                    e.target.style.borderBottom = '1px solid aqua';
                    setErrorMsgPostalCode('')
                }
             }}
            />
            <motion.h3
                className='input-required-text'
                style={{padding:"0", fontSize:"10px"}}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: errorMsgPostalCode !== '' ? 1 : 0, y: errorMsgPostalCode !== '' ? 0 : 10 }}
                transition={{ duration: 0.3 }}
            >{errorMsgPostalCode}</motion.h3> */}

          <label className="login-label">{t("CheckoutForm.COUNTRY")}</label>
          <input
            type="text"
            placeholder={t("CheckoutForm.SERBIA")}
            className="checkoutformjsx-input1"
            style={{ height: "8px", color: "#686868" }}
            readOnly
            onFocus={(e) => {
              e.target.style.borderBottom = "2px solid teal";
            }}
            onBlur={(e) => {
              e.target.style.borderBottom = "none";
            }}
          />
          <h3
            className="register-note-label"
            style={{ marginBottom: "40px", minWidth: "100%" }}
          >
            {" "}
            {t("CheckoutForm.DELIVERY_ONLY_IN_SERBIA")}
          </h3>
          <Places
            placeValue={city}
            setPlaceValue={setCity}
            zipCodeValue={postalCode}
            setZipCodeValue={setPostalCode}
            streetValue={address1}
            setStreetValue={setAddress1}
          />
          <label className="login-label">
            {t("CheckoutForm.MOBILE_PHONE")}
          </label>
          <input
            type="tel"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            required
            className="checkoutformjsx-input1"
            style={{ height: "8px" }}
            onFocus={(e) => {
              e.target.style.borderBottom = "1px solid aqua";
              setErrorMsgMobile("");
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                e.target.style.borderBottom = "2px solid #991f39";
                setErrorMsgMobile(t("CheckoutForm.REQUIRED_FIELD"));
              } else {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorMsgMobile("");
              }
            }}
          />
          <motion.h3
            className="input-required-text"
            style={{ padding: "0", fontSize: "10px" }}
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: errorMsgMobile !== "" ? 1 : 0,
              y: errorMsgMobile !== "" ? 0 : 10,
            }}
            transition={{ duration: 0.3 }}
          >
            {errorMsgMobile}
          </motion.h3>
        </DesktopCheckoutDiv>
        <div className="register-label-div">
          <label className="login-label">{t("CheckoutForm.NOTE")}</label>
          <h6 className="register-optional-text">
            {t("CheckoutForm.OPTIONAL")}
          </h6>
        </div>
        <input
          type="text"
          value={napomena}
          onChange={(e) => setNapomena(e.target.value)}
          className="checkoutformjsx-input1"
          style={{ height: "40px", marginBottom: "14px" }}
          onFocus={(e) => {
            e.target.style.borderBottom = "1px solid aqua";
          }}
          onBlur={(e) => {
            e.target.style.borderBottom = "none";
          }}
        />
      </form>
    </Container>
  );
};

export default CheckoutForm;
