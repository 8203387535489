import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import "../assets/css/ProductSlider.css";
import { motion } from "framer-motion";

const ImageContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  //width:300px;
  width: auto;
  min-height: 150px;
  max-height: 200px;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    //max-height: 150px;
  }
  @media only screen and (min-width: 769px) {
    max-height: 500px;
  }
`;
const PrevArrow = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  //background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  pointer-events: all;
  &:hover {
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;

const NextArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(58, 58, 58, 0.5);
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  pointer-events: all;
  &:hover {
    //background-color: rgba(22, 22, 22, 0.5);
    background-color: transparent;
  }
`;

const NavItem = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 20px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border: 2px solid teal;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
`;

const NavImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductSlider = ({ images }) => {
  const [indexImg, setIndexImg] = useState(0);
  const sliderRef = useRef(null);

  const handleImageClick = (index) => {
    setIndexImg(index);
    sliderRef.current.slickGoTo(index);
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(indexImg);
    }
  }, [indexImg]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div>
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <ImageContainer key={index}>
            <Image src={image} alt="proizvod" />
          </ImageContainer>
        ))}
      </Slider>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {images.map((image, index) => (
            <NavItem key={index} onClick={() => handleImageClick(index)}>
              <NavImage src={image} alt="proizvod" />
            </NavItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductSlider;
