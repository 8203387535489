import { Add, KeyboardArrowUp, Remove } from "@mui/icons-material";
import { useState, useEffect, useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import Announcment from "../components/Announcment";
import Footer from "../layouts/footer/Footer";
import MainNavbar from "../layouts/header/MainNavbar";
import Multibuy from "../components/Multibuy";
import { useNavigate, useParams } from "react-router-dom";
import APIService from "../services/APIService";
import ProductSlider from "../components/ProductSlider";
import { cartActions } from "../store/cart-slice";
import { useDispatch } from "react-redux";
import RecommendedShopSlider from "../components/RecommendedShopSlider";
import { useNavigation } from "../utils/navigation";
import { Helmet } from "react-helmet";
import { articles } from "../data";
import { ProductContext } from "../context/ProductContext";
import { seo_products } from "../data";
import NotifyPopUp from "../components/NotifyPopUp";
import SliderMini from "../components/SliderMini";
import { useTranslation } from "react-i18next";

const fadeInSubmenu = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const fadeOutSubmenu = keyframes`
  from {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    display:none;
  }
`;

const Container = styled.div`
  background-color: #f3f3f3;
`;
const Wrapper = styled.div`
  //padding: 20px;
  display: flex;
  min-width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const ImgContainer = styled.div`
  background-color: #fff;
  padding: 120px 100px;
  margin-top: 0px;
  max-width: 35vw;
  min-width: 35vw;
  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-top: 0px;
    max-width: none;
    min-width: auto;
  }
  @media only screen and (min-width: 769px) {
    padding: 0px 100px;
  }
`;
const InfoContainer = styled.div`
  background-color: #f3f3f3;
  flex: 1;
  width: 100vw;
  @media only screen and (max-width: 768px) {
    margin: 0;
    align-items: center;
  }
  overflow: hidden;
  @media only screen and (min-width: 769px) {
    max-width: 40vw;
  }
`;
const InfoWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  flex-direction: column;
`;
const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 1;
  display: block;
  flex: 0 1 auto;
  margin: 10px auto;
  text-transform: none;
  color: #06354e;
`;
const CategoryTitle = styled.span`
  text-transform: uppercase;
  color: black;
  font-family: "Righteous";
  font-weight: 100;
  font-size: 40px;
`;
const CategoryTitleDesktop = styled.span`
  text-transform: uppercase;
  color: black;
  font-family: "Righteous";
  font-weight: 100;
  font-size: 40px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ShortDesc = styled.div`
  margin: 0;
  padding: 0px;
  border: 0;
  outline: none;
  color: #686868;
  font-size: 14px;
`;
const Description = styled.div`
  margin: 0;
  padding: 30px 20px;
  border: 0;
  outline: none;
  color: #1f1f1f;
  background-color: #fff;
  z-index: 2;
  position: absolute;
  top: 40px;
  display: block;
  font-size: 14px;
  ${({ show }) =>
    show
      ? css`
          opacity: 1;
          animation: ${fadeInSubmenu} 0.6s ease-out;
        `
      : css`
          animation: ${fadeOutSubmenu} 0.6s ease-out;
          display: none;
        `}
  border: 2px solid #f3f3f3;
`;
const P = styled.p`
  letter-spacing: 0px;
  display: block;
  color: #686868;
  //margin: 0 0 2rem;
  font-size: 13px;
  letter-spacing: 1.2px;
  line-height: 1.4;
`;

const MultibuyContainer = styled.div`
  background-color: #0080803b;
  margin: 30px 0px;
  padding: 0px 20px;
  min-width: 35vw;

  @media only screen and (max-width: 768px) {
    margin: 10px 0px;
    min-width: 80vw;
  }
  @media only screen and (min-width: 400px) {
    max-width: 400px;
    margin: 20px auto;
  }
`;
const MultibuyWrapper = styled.div`
  display: block;
`;

const MultibuyBody = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 8px 0px;
  @media only screen and (min-width: 400px) {
    justify-content: space-around;
  }
`;
const MultibuyBodyTextRight = styled.span`
  letter-spacing: 0px;
  color: #041e42;
  padding: 0;
  border: 0;
  outline: none;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  word-spacing: 1px;
  margin-left: 40px;
  line-height: 0.7;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;
const MultibuyBodyTextLeft = styled.span`
  letter-spacing: 0px;
  color: #041e42;
  padding: 0;
  border: 0;
  outline: none;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  word-spacing: 4px;
  margin-right: 40px;
  line-height: 0.7;
  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

const NicotineContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  @media only screen and (max-width: 768px) {
    width: 70%;
  }
  padding-left: 20px;
`;
const NicotineTitle = styled.div`
  color: #00010d; //#008080;
  width: 100%;
  font-size: 14px;
  line-height: 1.25;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: none;
  padding-bottom: 0;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  margin: 0px;
  margin-bottom: 5px;
`;
const NicotineInfo = styled.div`
  letter-spacing: 0px;
  color: #041e42;
  outline: none;
  position: relative;
  display: block;
  width: 25%;
  padding: 10px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #045d5d;
  background-color: white;
  @media only screen and (max-width: 768px) {
    width: 100%;
    border: none;
    background-color: transparent;
    display: none;
  }
`;
const NicotineInfoTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  min-width: unset;
  pointer-events: none;
  color: #045d5d;
`;
const Circles = styled.div`
  display: flex;
  justify-content: space-around;
`;
const Circle = styled.div`
  flex: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  margin: 0px 2px;
  border: 1px solid #045d5d;
  display: block;
`;
const Price = styled.span`
  margin: 0;
  padding: 0;
  font-size: 30px;
  letter-spacing: 1.4px;
`;
const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 80px;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
    gap: 10px;
  }
`;
const AmountContainer = styled.div`
  border: 2px solid #5862bf;
  border-radius: 100px;
  padding: 4px;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-left: 10px;
  width: 250px;
  justify-content: space-around;
  height: 30px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    width: 80vw;
    justify-content: space-around;
  }
`;
const AmountInput = styled.input`
  border: none;
  box-sizing: border-box;
  height: 100%;
  border: none;
  outline: none;
  max-width: 40px;
  background: none;
  font-weight: 600;
  font-size: 16px;
  ::placeholder {
    font-size: 12px;
    font-weight: 100;
    color: #ececec;
  }
  text-align: center;
`;
const AmountTitle = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  min-width: unset;
  pointer-events: none;
  color: #045d5d;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  text-transform: uppercase;
  letter-spacing: 1.1px;
`;
const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;

  @media only screen and (max-width: 768px) {
    padding: 5px;
    margin: 0;
  }
`;
const Button = styled.button`
  margin-top: -20px;
  padding: 14px;
  border: 2px solid ${(props) => props.article_color}; //#5862bf;
  border-radius: 40px;
  background-color: ${(props) => props.article_color}; //#5862bf;
  cursor: pointer;
  font-weight: 500;
  color: white;
  min-width: 220px;

  &:hover {
    background-color: white;
    color: ${(props) => props.article_color}; //#5862bf;
  }
  @media only screen and (max-width: 768px) {
    margin: 0;
    width: 80vw;
  }
`;
const ButtonMobileDiv = styled.div`
  display: none;
  background-color: white;
  @media only screen and (max-width: 768px) {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 80px;
    z-index: 9;
  }
`;
const ContainerButtonPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 10px;
  gap: 10px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;
const TitlePriceMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f3f3f3;
    padding: 15px 8px 15px 8px;
  }
`;
const WrapTwo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: right;
  margin: 0;
  padding: 0;
`;
const CategoryMobTitle = styled.h1`
  margin: 0;
  padding: 0;
  //letter-spacing: 0px;
  font-family: "Righteous";
  font-weight: 100;
  font-size: 25px;
`;
const CategoryMobTitleDiv = styled.div`
  flex: 6;
  margin: 0;
  padding: 0;
  //letter-spacing: 0px;
  font-family: "Righteous";
  font-weight: 100;
  font-size: 25px;
`;
const PMob = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 100;
  color: #acacac;
  font-size: 14px;
  letter-spacing: 1.3px;
  text-align: right;
`;
const PriceMob = styled.h1`
  margin: 0;
  padding: 0px;
  text-align: right;
  display: block;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  color: black;
  white-space: nowrap;
`;
const NicotineContainerMob = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0px 0px;
  margin: 0;
  margin-top: 5px;
  width: 100%;
`;
const NicotineInfoMob = styled.p`
  margin: 0;
  padding: 0px 5px 0px 0px;
  font-size: 16px;
  letter-spacing: 1.2px;
  color: #686868;
  font-weight: 300;
  font-family: "Righteous";
  text-transform: uppercase;
`;
const ReadMore = styled.div`
  margin: 0;
  padding: 0px 5px 10px 0px;
  font-size: 16px;
  letter-spacing: 1.2px;
  color: black;
  font-weight: 300;
  font-family: "Righteous";
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 40px;
`;
const NicotineMGMob = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 16px;
`;

const Icon = styled.div`
  transition: transform 0.3s ease-in-out;
  transform: ${({ rotated }) => (rotated ? "rotate(-180deg)" : "rotate(0)")};
`;
const B = styled.span`
  padding: 0;
  font-family: "Righteous";
  text-transform: uppercase;
`;
const Product = () => {
  const { t, i18n } = useTranslation();
  const {
    product,
    recommendedArticles,
    getProductByUrlSlug,
    allProducts,
    setPopUp,
    popUp,
  } = useContext(ProductContext);
  const [imageUrls, setImageUrls] = useState([]);
  const [article, setArticle] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const { goToNotFound } = useNavigation();
  const [showDesc, setShowDesc] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    if (!slug) {
      return;
    }
    getProductByUrlSlug(slug);
  }, [slug, allProducts]);

  useEffect(() => {
    if (product) {
      window.scrollTo(0, 0);
      setArticle(product);
      setImageUrls(product.images.map((image) => image.image_url));
    }
  }, [product]);

  useEffect(() => {
    if (recommendedArticles) {
      setRecommended(recommendedArticles);
    }
  }, [recommendedArticles]);

  const getColor = (nicotine, val) => {
    const nicotineValue = parseInt(nicotine);
    return nicotineValue > val ? "#045D5D" : "white";
  };
  const [amount, setAmount] = useState(1);
  function handleChange(e) {
    const value = parseInt(e.target.value);
    const newValue = Math.max(value, 0);
    setAmount(newValue);
  }
  function addOne() {
    if (parseInt(amount) >= 0) {
      setAmount(parseInt(amount) + 1);
    } else {
      setAmount(1);
    }
  }
  function removeOne() {
    if (parseInt(amount) > 0) {
      setAmount(parseInt(amount) - 1);
    }
  }
  const dispatch = useDispatch();
  const addToCart = (quantity) => {
    dispatch(
      cartActions.addToCart({
        article: article,
        quantity: quantity,
      })
    );

    setAmount(0);
  };
  const [seoInfo, setSeoInfo] = useState(null);

  useEffect(() => {
    const foundSeoInfo = seo_products.find(
      (a) =>
        `${a.category.toLowerCase()}-${a.title
          .toLowerCase()
          .replace(/ /g, "-")}` === slug
    );
    setSeoInfo(foundSeoInfo);
  }, [slug]);

  return (
    <Container>
      {seoInfo && (
        <Helmet>
          <title>
            {`${seoInfo.category} ${seoInfo.title} | Snus Srbija `}{" "}
          </title>
          <meta name="description" content={seoInfo.meta_description} />
          <meta
            property="og:title"
            content={`${seoInfo.category} ${seoInfo.title} | Snus Srbija`}
          />
          <meta property="og:description" content={seoInfo.meta_description} />
          <meta property="og:image" content={seoInfo.cover_img} />
          <link
            rel="canonical"
            href={`https://www.snussrbija.com/${seoInfo.category.toLowerCase()}-${seoInfo.title
              .toLowerCase()
              .replace(/ /g, "-")}`}
          />
          <meta
            name="keywords"
            content={`${seoInfo.category} ${seoInfo.title} , ${seoInfo.keywords}  `}
          />
        </Helmet>
      )}
      {popUp && <NotifyPopUp />}
      <MainNavbar />
      <Announcment />
      <Wrapper>
        <ImgContainer>
          <TitlePriceMobile>
            <CategoryMobTitleDiv style={{ color: article.color, flex: "2" }}>
              <CategoryMobTitle style={{ minHeight: "50px" }}>
                {" "}
                <span style={{ color: "black", textTransform: "uppercase" }}>
                  {article.category}{" "}
                </span>{" "}
                {article.title}
              </CategoryMobTitle>
              {/* <CategoryMobTitle style={{color:"black"}}>{article.category.toUpperCase()}</CategoryMobTitle> */}
            </CategoryMobTitleDiv>
            <WrapTwo>
              <PMob>
                {article.pouches_per_can} {t("Product.POUCHES_PER_CAN")}
              </PMob>
              <PriceMob>{article.price} RSD</PriceMob>
            </WrapTwo>
          </TitlePriceMobile>
          <ProductSlider images={imageUrls} />
        </ImgContainer>
        <InfoContainer>
          <InfoWrapper>
            <Title>
              <CategoryTitle>{article.category}</CategoryTitle>{" "}
              <CategoryTitleDesktop style={{ color: article.color }}>
                {article.title}
              </CategoryTitleDesktop>{" "}
            </Title>

            <NicotineContainerMob>
              <NicotineInfoMob>
                {i18n.language === "sr" ? article.state + ":" : null}{" "}
              </NicotineInfoMob>
              <NicotineMGMob>{article.nicotine}MG</NicotineMGMob>
            </NicotineContainerMob>
            <SliderMini product={article} />
            <div style={i18n.language === "sr" ? {} : { display: "none" }}>
              <ShortDesc>
                <P>{article.shortDesc}</P>
              </ShortDesc>
              <div style={{ position: "relative" }}>
                <ReadMore onClick={() => setShowDesc(!showDesc)}>
                  {t("Product.PROCITAJ_VISE")}
                  <Icon rotated={showDesc}>
                    <KeyboardArrowUp />
                  </Icon>
                </ReadMore>
                <Description show={showDesc}>{article.description}</Description>
              </div>
            </div>

            <MultibuyContainer>
              <MultibuyWrapper>
                {/* <MultibuyBody  style={{borderBottom:"1px solid teal"}}>
            <MultibuyBodyTextRight>
            {t('Product.NA_BILO_KOJIH')} <b style={{margin:"0px 5px 0px 5px", padding:"0px"}}>(3+)</b>
            </MultibuyBodyTextRight>
            <MultibuyBodyTextLeft>
            <B style={{ padding:"0px"}}>{t('Product.BESPLATNA_DOSTAVA')}</B>
            </MultibuyBodyTextLeft>
        </MultibuyBody> */}
                <MultibuyBody>
                  <MultibuyBodyTextRight>
                    {t("Product.NA_BILO_KOJIH")}
                    <b style={{ margin: "0px 5px 0px 5px" }}>(5+)</b>
                  </MultibuyBodyTextRight>
                  {/* <MultibuyBodyTextLeft>
            {t('Product.OSTVARITE')} <B>6.5%</B> {t('Product.POPUSTA')}
            </MultibuyBodyTextLeft> */}
                </MultibuyBody>
                <MultibuyBody style={{ borderBottom: "1px solid teal" }}>
                  <MultibuyBodyTextRight></MultibuyBodyTextRight>
                  <MultibuyBodyTextLeft>
                    <B>{t("Product.BESPLATNA_DOSTAVA")}</B>
                  </MultibuyBodyTextLeft>
                </MultibuyBody>
                <MultibuyBody>
                  <MultibuyBodyTextRight>
                    {t("Product.NA_BILO_KOJIH")}
                    <b style={{ margin: "0px 5px 0px 5px" }}>(10+)</b>
                  </MultibuyBodyTextRight>
                  <MultibuyBodyTextLeft>
                    {t("Product.OSTVARITE")} <B>9%</B> {t("Product.POPUSTA")}
                  </MultibuyBodyTextLeft>
                </MultibuyBody>
                <MultibuyBody style={{ borderBottom: "1px solid teal" }}>
                  <MultibuyBodyTextRight></MultibuyBodyTextRight>
                  <MultibuyBodyTextLeft>
                    + <B>{t("Product.BESPLATNA_DOSTAVA")}</B>
                  </MultibuyBodyTextLeft>
                </MultibuyBody>
                {/* <MultibuyBody>
            <MultibuyBodyTextRight>
            {t('Product.NA_BILO_KOJIH')}<b style={{margin:"0px 5px 0px 5px"}}>(30+)</b>
            </MultibuyBodyTextRight>
            <MultibuyBodyTextLeft>
            {t('Product.OSTVARITE')} <B>30%</B> {t('Product.POPUSTA')}
            </MultibuyBodyTextLeft>
        </MultibuyBody>
        <MultibuyBody style={{borderBottom:"1px solid teal"}}>
            <MultibuyBodyTextRight>
            </MultibuyBodyTextRight>
            <MultibuyBodyTextLeft>
            + <B>{t('Product.BESPLATNA_DOSTAVA')}</B>
            </MultibuyBodyTextLeft>
        </MultibuyBody> */}
                <MultibuyBody>
                  <MultibuyBodyTextRight>
                    {t("Product.NA_BILO_KOJIH")} <b>(50+)</b>
                  </MultibuyBodyTextRight>
                  <MultibuyBodyTextLeft>
                    {t("Product.OSTVARITE")} <B>22%</B> {t("Product.POPUSTA")}
                  </MultibuyBodyTextLeft>
                </MultibuyBody>
                {/* <MultibuyBody>
            <MultibuyBodyTextRight>
            </MultibuyBodyTextRight>
            <MultibuyBodyTextLeft>
            + <B>{t('Product.BESPLATNA_DOSTAVA')}</B>
            </MultibuyBodyTextLeft>
        </MultibuyBody> */}
              </MultibuyWrapper>
            </MultibuyContainer>

            <NicotineContainer>
              <NicotineTitle>{t("Product.NICOTINE_STRENGTH")}</NicotineTitle>
              <NicotineInfo>
                <NicotineInfoTitle>{article.nictone}MG</NicotineInfoTitle>
                <Circles>
                  <Circle
                    style={{ backgroundColor: getColor(article.nicotine, 0) }}
                  />
                  <Circle
                    style={{ backgroundColor: getColor(article.nicotine, 5) }}
                  />
                  <Circle
                    style={{ backgroundColor: getColor(article.nicotine, 13) }}
                  />
                  <Circle
                    style={{ backgroundColor: getColor(article.nicotine, 20) }}
                  />
                  <Circle
                    style={{ backgroundColor: getColor(article.nicotine, 30) }}
                  />
                </Circles>
              </NicotineInfo>
            </NicotineContainer>
            {article.state === "Dostupno" ? (
              <>
                <AddContainer>
                  <AmountTitle htmlFor="quantity">
                    {t("Product.QUANTITY")}
                  </AmountTitle>
                  <AmountContainer>
                    <Remove onClick={removeOne} />
                    <AmountInput
                      id="quantity"
                      type="number"
                      value={amount}
                      step={1}
                      min={0}
                      onChange={handleChange}
                    />
                    <Add onClick={addOne} />
                  </AmountContainer>
                </AddContainer>

                <ContainerButtonPrice>
                  <Title>
                    <Price>{article.price}RSD</Price>{" "}
                  </Title>
                  <Button
                    article_color={article.color}
                    onClick={() => addToCart(amount)}
                  >
                    {t("Product.ADD_TO_CART")}
                  </Button>
                </ContainerButtonPrice>
              </>
            ) : (
              <>
                <ContainerButtonPrice>
                  <Title>
                    <Price>{article.price}RSD</Price>{" "}
                  </Title>
                  <Button
                    article_color={article.color}
                    onClick={() => setPopUp(article)}
                  >
                    {t("Product.NOTIFY_ME")}
                  </Button>
                </ContainerButtonPrice>
              </>
            )}
          </InfoWrapper>
        </InfoContainer>
      </Wrapper>
      <RecommendedShopSlider sliderContent={recommended} />
      <Footer />

      <ButtonMobileDiv>
        {article.state === "Dostupno" ? (
          <Button
            article_color={article.color}
            onClick={() => addToCart(amount)}
          >
            {t("Product.ADD_TO_CART")}
          </Button>
        ) : (
          <Button
            article_color={article.color}
            onClick={() => setPopUp(article)}
          >
            {t("Product.NOTIFY_ME")}
          </Button>
        )}
      </ButtonMobileDiv>
    </Container>
  );
};

export default Product;
