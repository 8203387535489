import { useState, createContext, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import APIService from "../services/APIService";
import { cartActions } from "../store/cart-slice";
import { calculatePrice, calculateShippingCost } from "../utils/discount";
import { AuthUserContext } from "./AuthUserContext";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const cartItems = useSelector((state) => state.cart.itemsList);
  const [totalDiscountedPrice, setTotalDiscountedPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingCostDiscount, setShippingCostDiscount] = useState(0);
  const { user } = useContext(AuthUserContext);

  useEffect(() => {
    let temp_totalDiscountedPrice = 0;
    let temp_totalPrice = 0;
    cartItems.forEach((item) => {
      temp_totalDiscountedPrice += item.article.discountPrice * item.quantity;
      temp_totalPrice += item.article.price * item.quantity;
    });
    setTotalDiscountedPrice(temp_totalDiscountedPrice);
    setTotalPrice(temp_totalPrice);
    setShippingCost(
      calculateShippingCost(
        totalQuantity,
        temp_totalDiscountedPrice,
        user && user.city ? user.city : ""
      )
    );
    setShippingCostDiscount(
      calculateShippingCostDiscount(totalQuantity, temp_totalDiscountedPrice)
    );
  }, [cartItems, user]);

  const calculateShippingCostDiscount = (totalQuantity, price) => {
    if (totalQuantity >= 5 && totalQuantity < 50) {
      return 0;
    } else {
      return calculateShippingCost(
        totalQuantity,
        price,
        user && user.city ? user.city : ""
      );
    }
  };
  const dispatch = useDispatch();
  const deleteCartItem = (id) => {
    dispatch(cartActions.removeFromCart(id));
  };
  const handleQuantityChange = (event, item) => {
    const newQuantity = parseInt(event.target.value);
    dispatch(
      cartActions.updateCart({
        article: item.article,
        quantity: newQuantity,
      })
    );
  };

  let contextData = {
    totalPrice: totalPrice,
    totalDiscountedPrice: totalDiscountedPrice,
    shippingCost: shippingCost,
    shippingCostDiscount: shippingCostDiscount,
  };
  return (
    <CartContext.Provider value={contextData}>{children}</CartContext.Provider>
  );
};
