import { useContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import APIService from '../services/APIService';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MainNavbar from '../layouts/header/MainNavbar';
import Footer from '../layouts/footer/Footer';
import { AuthUserContext } from '../context/AuthUserContext';


const Container = styled.div`
  display:flex;
  align-content: center;
  justify-content: center;
  padding-top: 100px;
  min-height: calc(75vh - 70px);
`
const Wrapper = styled.div`
  width:30%;
  @media only screen and (max-width:768px){
    width:90%;
  }
`
const Wrapper2 = styled.div`
  display:flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
  @media only screen and (max-width:768px){
    width:90%;
  }
`


const Title = styled.h1`
  padding:0;
  font-size: 22px;
  line-height: 1.4;
  font-weight: 700;
  color: #041e42;
  display:flex;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: "Righteous";
`
const Text = styled.h4`
  font-weight: 600;
  text-align:center;
  color: #6e6e6e;
`
const Text2 = styled.h4`
  font-weight: 600;
  text-align:center;
  color: #000;
`
const Input = styled.input`
  display: block;
  height:8px;
  margin:4px 0px;
  
`
const Button = styled.button`
  width:50%;
  text-transform: uppercase;
  

`

function ForgotPassowrd() {
  const { t } = useTranslation();
  const {loginUser, setEmailUser} = useContext(AuthUserContext);
  const [email, setEmail] = useState('');
  const [ErrorEmailText, setErrorEmailText] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [requestSubmited, setRequestSubmited] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await APIService.ResetPasswordRequest(email);
      console.log(response);
      setRequestSubmited(true)
      // Prikazati korisniku poruku o uspešnom slanju zahteva
    } catch (error) {
      console.error(error);
      // Obraditi grešku
    }
  };

  // GOOGLE
  function handleCallbackResponse(response) {
    var userObject = jwt_decode(response.credential)
    localStorage.setItem('emailUser', JSON.stringify({"email": userObject.email, "name":userObject.given_name, "surname":userObject.family_name, "avatar":userObject.picture}))
    setEmailUser({"email": userObject.email, "name":userObject.given_name, "surname":userObject.family_name, "avatar":userObject.picture})
    loginUser(userObject.email);
}

useEffect(() => {
/*global google*/ 
google.accounts.id.initialize({
    client_id: "289868755464-ifopbs2daeogjlg81ftdp92vgpat5av6.apps.googleusercontent.com",
    callback: handleCallbackResponse
});

google.accounts.id.renderButton(
    document.getElementById("signInDiv"),
    { theme: "outlined", size: "large" }
);
}, []);
  return (
    <div>
      <MainNavbar/>
    <Container>
      {!requestSubmited ? 
      <Wrapper>
      <Title>{t('ForgotPassword.TITLE')}</Title>
      <Text>{t('ForgotPassword.INSTRUCTION')}</Text>
    <form onSubmit={handleSubmit} className='form1'>
    <label className='login-label' 
            >{t('ForgotPassword.EMAIL_LABEL')}</label>
      <Input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t('Login.EMAIL_LABEL')}
        className='input1'
        
        required
        onFocus={e => { 
          e.target.style.borderBottom = '1px solid aqua';
          setErrorEmailText('')
     }}
      // style for input when it's not selected or has content
      onBlur={e => {
          if (e.target.value === '') {
          e.target.style.borderBottom = '2px solid #991f39';
          setErrorEmailText(t('Login.REQUIRED_FIELD'))
          }else if (!emailRegex.test(e.target.value)) {
             e.target.style.borderBottom = '2px solid #991f39';
             setErrorEmailText(t('Login.INVALID_EMAIL'))
         }
          else{
              e.target.style.borderBottom = '1px solid aqua';
             setErrorEmailText('')
         }
      }}
      />
      <Button className='login-form-button' type="submit">
                {t('ForgotPassword.SEND_BUTTON')}
              </Button>
      </form>
      <div className='login-br'></div>
        <Title>{t('ForgotPassword.ALTERNATIVE_TITLE')}</Title>
        <Text2>{t('ForgotPassword.ALTERNATIVE_TEXT')}</Text2>
      <div id="signInDiv" ></div>
    </Wrapper>
    :
    <Wrapper2 >
      <Title>{t('ForgotPassword.CHECK_INBOX')}</Title>
      <Text>{t('ForgotPassword.INBOX_INSTRUCTION')}</Text>
      <Text>{t('ForgotPassword.FOLLOW_INSTRUCTION')}</Text>
      <Button  style={{width:'100%', border:'none', bordeRadius: '7px'}} className='login-form-button-reverse' onClick={()=>setRequestSubmited(!requestSubmited)} >{t('ForgotPassword.NOT_RECEIVED')}</Button>
    </Wrapper2>
    }
    </Container>
    <Footer/>
    </div>
  );
}

export default ForgotPassowrd;
