import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import MainNavbar from "../layouts/header/MainNavbar";
import Footer from "../layouts/footer/Footer";
import { cartActions } from "../store/cart-slice";
import { ProductionQuantityLimits } from "@mui/icons-material";
import { useContext, useEffect } from "react";
import RecommendedShopSlider from "../components/RecommendedShopSlider";
import { useNavigation } from "../utils/navigation";
import { Helmet } from "react-helmet";
import { ProductContext } from "../context/ProductContext";
import { CartContext } from "../context/CartContext";
import { discountOptions } from "../data";
import { useTranslation } from "react-i18next";

const Container = styled.div``;
const BasketContainer = styled.div`
  display: flex;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const BasketLeft = styled.div`
  margin-top: 70px;
  flex: 2;
  padding: 20px 80px;
  @media only screen and (max-width: 768px) {
    padding: 20px 0px;
  }
`;

const BasketItemsTitle = styled.h3`
  padding: 4px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 100;
  color: #041e42;
  display: inline-block;
  padding-bottom: 20px;
  border-bottom: 1px solid #00000013;
  text-transform: uppercase;
  //font-family: "Righteous";
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MultibuyContainer = styled.div`
  background-color: #0080803b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
const MultibuyWrapper = styled.div`
  max-width: 70%;
  display: block;
`;

const MultibuyTitle = styled.p`
  letter-spacing: 0px;
  color: #041e42;
  padding: 0;
  border: 0;
  outline: none;
  text-align: center;
  font-size: 16px;
  //white-space: nowrap;

  @media only screen and (max-width: 768px) {
    white-space: normal;
  }
`;
const MultibuyBody = styled.div`
  display: flex;
  //align-items: center;
  justify-content: space-between;
  margin: 1px;
  padding: 0px;
`;
const MultibuyBodyTextRight = styled.p`
  letter-spacing: 0px;
  color: #041e42;
  padding: 0;
  border: 0;
  outline: none;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  word-spacing: 1px;
  margin-left: 40px;
  line-height: 0.7;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;
const MultibuyBodyTextLeft = styled.p`
  letter-spacing: 0px;
  color: #041e42;
  padding: 0;
  border: 0;
  outline: none;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  word-spacing: 4px;
  margin-right: 40px;
  line-height: 0.7;
  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

const BodyDiv = styled.div`
  width: 100%;
  display: flex;
  height: 250px;
  overflow: hidden;
  border-bottom: 1px solid #00000013;
  margin-top: 5px;
  @media only screen and (max-width: 768px) {
    height: 220px;
  }
  position: relative;
`;
const BodyIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #00000013;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImgContainer = styled.picture`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
const ProductImg = styled.img`
  width: 100%;
  height: auto;
`;
const ProductInfo = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
`;
const ProductTitle = styled.h1`
  margin: 0;
  padding-top: 10px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 100;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
  font-family: "Righteous";
`;

const ProductNicotine = styled.h2`
  margin: 0;
  padding: 10px 0px;
  font-size: 16px;
  line-height: 1;
  font-weight: 100;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
`;

const ProductDiscountNotes = styled.div`
  margin: 0px;
  display: flex;
  align-items: left;
  gap: 3px;
  justify-content: left;
  flex-wrap: wrap;
  max-width: 50%;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
const ProductDiscountNote = styled.h2`
  background-color: ${(props) => props.background_color};
  margin: 0;
  padding: 7px 7px;
  font-size: 12px;
  //line-height: 1.0;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: left;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;

const PriceQuantityContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
`;
const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: left;
`;
const QuantityTitle = styled.h2`
  margin: 0;
  padding-top: 10px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
`;
const QuantitySelect = styled.select`
  width: 50px;
  height: 30px;
  display: flex;
`;
const ProductPrice = styled.h2`
  margin: 0;
  padding: 10px 0px;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
  margin-right: 20px;
  @media only screen and (max-width: 768px) {
    padding: 0px;
  }
`;

const BasketRight = styled.div`
  flex: 1;
  background-color: #00000013;
  padding: 20px 80px;
  margin-top: 70px;
  @media only screen and (max-width: 768px) {
    padding: 20px 20px;
    margin-top: 0px;
  }
`;
const RightTitle = styled.h2`
  padding: 0;
  font-size: 22px;
  line-height: 1.4;
  font-weight: 700;
  color: #041e42;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: "Righteous";
`;
const BillContainer = styled.div`
  background-color: white;
  padding: 25px 30px;
`;
const BillWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SubtotalTitle = styled.h2`
  padding: 0;

  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  color: #00000086;
  text-transform: uppercase;
`;
const SubtotalPrice = styled.h2`
  padding: 0;
  font-size: 12px;
  line-height: 1;
  font-weight: 100;
  color: #00000086;
`;
const DiscountTitle = styled.h2`
  padding: 0;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  color: teal;
  text-transform: uppercase;
  white-space: nowrap;
`;
const DiscountPrice = styled.h2`
  padding: 0;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  color: teal;
  text-transform: uppercase;
  white-space: nowrap;
`;
const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid black;
  margin-top: 10px;
`;
const TotalTitle = styled.h2`
  padding: 0;
  font-size: 20px;
  line-height: 1.4;
  //font-weight: 700;
  color: #041e42;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: "Righteous";
`;
const TotalPrice = styled.h2`
  padding: 0;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
  color: #041e42;
  white-space: nowrap;
  font-family: "Righteous";
`;

const DeliveryNote = styled.p`
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #686868;
  font-size: 14px;
`;

const BottomDiv = styled.div`
  background-color: white;
  display: none;
  width: 100%;
  position: sticky;
  //top:130px;
  bottom: 0;
  //left:0;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  z-index: 9;
  overflow: hidden;
`;
const BottomDivWrapper = styled.div`
  width: 90%;
`;
const CheckoutButton = styled.button`
  width: 100%;
  margin-top: 20px;
  border-radius: 100px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 20px;
  text-decoration: none;
  transition: 0.6s all cubic-bezier(0.25, 1, 0.5, 1);
  background: #5862bf;
  border: 2px solid #5862bf;
  color: #fff;
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background: #6b76da;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Help = styled.div`
  padding: 30px 20px;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
`;
const HelpTitle = styled.h2`
  //margin-bottom: 10px;
  font-size: 20px;
  margin: 0;
  font-weight: 100;
  font-family: "Righteous";
  text-align: center;
`;
const HelpDesc = styled.h3`
  font-size: 16px;
  font-weight: 400;
`;
const TotalMobileDiv = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    margin-top: 10px;
  }
`;
const TotalMobileTitle = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
  //font-weight: 700;
  color: #041e42;
  text-transform: uppercase;
  font-family: "Righteous";
`;
const TotalMobilePrice = styled.h3`
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  color: #041e42;
  font-family: "Righteous";
`;

const EmptyCartContainer = styled.div`
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const EmptyCartIcon = styled.a`
  color: teal;
`;
const EmptyCartTitle = styled.h2`
  font-size: 26px;
  //font-weight: 700;
  margin: 0px;
  padding: 0px;
  color: teal;
  //letter-spacing: 1.2px;
  font-family: "Righteous";
  text-transform: uppercase;
`;
const EmptyCartDesc = styled.h3`
  font-size: 15px;
  color: #555555;
`;
const EmptyCartButton = styled.button`
  width: 20vw;
  margin-top: 20px;
  border-radius: 100px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 20px;
  text-decoration: none;
  transition: 0.6s all cubic-bezier(0.25, 1, 0.5, 1);
  background: #5862bf;
  border: 2px solid #5862bf;
  color: #fff;
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background: #6b76da;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    width: 70vw;
  }
`;
const B = styled.span`
  padding: 0;
  font-family: "Righteous";
  text-transform: uppercase;
`;
const Basket = () => {
  const { t, i18n } = useTranslation();
  const { goToCheckout, goToShop } = useNavigation();
  const { recommendedArticles } = useContext(ProductContext);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const cartItems = useSelector((state) => state.cart.itemsList);
  const { totalPrice, totalDiscountedPrice, shippingCostDiscount } =
    useContext(CartContext);

  const dispatch = useDispatch();
  const deleteCartItem = (id) => {
    dispatch(cartActions.removeFromCart(id));
  };
  const handleQuantityChange = (event, item) => {
    const newQuantity = parseInt(event.target.value);
    dispatch(
      cartActions.updateCart({
        article: item.article,
        quantity: newQuantity,
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>KORPA</title>
        <meta
          name="description"
          content="Naručite svoj omiljeni snus online u Srbiji, dodajte ga u vašu korpu na našoj online web stranici. Pronađite najbolje nikotinski proizvod na našem websajtu."
        />
        <meta property="og:title" content="KORPA | Snus Srbija" />
        <meta
          property="og:description"
          content="Naručite svoj omiljeni snus zvake online u Srbiji, dodajte ga u vašu korpu na našoj online web stranici. Pronađite najbolje nikotinski proizvod na našem websajtu."
        />
        <meta
          property="og:image"
          content={require("../assets/images/basketCover.webp")}
        />
        <link rel="canonical" href="https://www.snussrbija.com/korpa" />
        <meta name="keywords" content="snus Srbija, snus korpa, snus zvake" />
      </Helmet>

      <MainNavbar />
      {totalQuantity && Number(totalQuantity) !== 0 ? (
        <BasketContainer>
          <BasketLeft>
            <BasketItemsTitle>
              {t("Basket.BasketItemsTitle", { totalQuantity })}
            </BasketItemsTitle>
            <TotalMobileDiv>
              <TotalMobileTitle>
                {t("Basket.TotalMobileTitle")}
              </TotalMobileTitle>
              <TotalMobilePrice>
                {totalDiscountedPrice.toLocaleString()}RSD
              </TotalMobilePrice>
            </TotalMobileDiv>

            {totalQuantity && Number(totalQuantity) < 3 ? (
              <MultibuyContainer>
                <MultibuyWrapper>
                  <MultibuyTitle>
                    {t("Basket.MultibuyTitle", {
                      remainingQuantity: 3 - Number(totalQuantity),
                    })}{" "}
                  </MultibuyTitle>
                  {/* <MultibuyBody style={{ borderBottom: "1px solid teal" }}>
                    <MultibuyBodyTextRight>
                      {t("Basket.MultibuyBodyTextRight")}{" "}
                      <b style={{ margin: "0px 5px 0px 5px", padding: "0px" }}>
                        (3+)
                      </b>
                    </MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      <B style={{ padding: "0px" }}>
                        {t("Basket.MultibuyBodyTextLeft1")}
                      </B>
                    </MultibuyBodyTextLeft>
                  </MultibuyBody> */}
                  <MultibuyBody>
                    <MultibuyBodyTextRight>
                      {t("Basket.MultibuyBodyTextRight")}
                      <b style={{ margin: "0px 5px 0px 5px" }}>(5+)</b>
                    </MultibuyBodyTextRight>
                    {/* <MultibuyBodyTextLeft>
                      {t("Basket.MultibuyBodyTextLeft2")} <B>6.5%</B>{" "}
                      {t("Basket.MultibuyBodyTextLeft3")}
                    </MultibuyBodyTextLeft> */}
                  </MultibuyBody>
                  <MultibuyBody style={{ borderBottom: "1px solid teal" }}>
                    <MultibuyBodyTextRight></MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      + <B>{t("Basket.MultibuyBodyTextLeft1")}</B>
                    </MultibuyBodyTextLeft>
                  </MultibuyBody>
                  <MultibuyBody>
                    <MultibuyBodyTextRight>
                      {t("Basket.MultibuyBodyTextRight")}
                      <b style={{ margin: "0px 5px 0px 5px" }}>(10+)</b>
                    </MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      {t("Basket.MultibuyBodyTextLeft2")} <B>10%</B>{" "}
                      {t("Basket.MultibuyBodyTextLeft3")}
                    </MultibuyBodyTextLeft>
                  </MultibuyBody>
                  {/* <MultibuyBody style={{ borderBottom: "1px solid teal" }}>
                    <MultibuyBodyTextRight></MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      + <B>{t("Basket.MultibuyBodyTextLeft1")}</B>
                    </MultibuyBodyTextLeft>
                  </MultibuyBody>
                  <MultibuyBody>
                    <MultibuyBodyTextRight>
                      {t("Basket.MultibuyBodyTextRight")}
                      <b style={{ margin: "0px 5px 0px 5px" }}>(30+)</b>
                    </MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      {t("Basket.MultibuyBodyTextLeft2")} <B>30%</B>{" "}
                      {t("Basket.MultibuyBodyTextLeft3")}
                    </MultibuyBodyTextLeft>
                  </MultibuyBody> */}
                  <MultibuyBody style={{ borderBottom: "1px solid teal" }}>
                    <MultibuyBodyTextRight></MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      + <B>{t("Basket.MultibuyBodyTextLeft1")}</B>
                    </MultibuyBodyTextLeft>
                  </MultibuyBody>
                  <MultibuyBody>
                    <MultibuyBodyTextRight>
                      {t("Basket.MultibuyBodyTextRight")} <b>(50+)</b>
                    </MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      {t("Basket.MultibuyBodyTextLeft2")} <B>22%</B>{" "}
                      {t("Basket.MultibuyBodyTextLeft3")}
                    </MultibuyBodyTextLeft>
                  </MultibuyBody>
                  {/* <MultibuyBody>
                    <MultibuyBodyTextRight></MultibuyBodyTextRight>
                    <MultibuyBodyTextLeft>
                      + <B>{t("Basket.MultibuyBodyTextLeft1")}</B>
                    </MultibuyBodyTextLeft>
                  </MultibuyBody> */}
                </MultibuyWrapper>
              </MultibuyContainer>
            ) : (
              <></>
            )}
            {cartItems.map((item) => (
              <BodyDiv key={item.article.id}>
                <BodyIconWrapper>
                  <Close
                    style={{ fontSize: "25px" }}
                    onClick={() => deleteCartItem(item.article.id)}
                  />
                </BodyIconWrapper>
                <ImgContainer>
                  <ProductImg
                    src={
                      item.article.images.find((img) => img.is_primary_image)
                        ?.image_url || ""
                    }
                  />
                </ImgContainer>
                <ProductInfo>
                  <ProductTitle>
                    {item.article.category}{" "}
                    <span
                      style={{ color: item.article.color, marginLeft: "5px" }}
                    >
                      {item.article.title}
                    </span>
                  </ProductTitle>

                  <ProductNicotine>{item.article.nicotine}MG</ProductNicotine>

                  <ProductDiscountNotes>
                    {discountOptions.map((option) => {
                      if (
                        totalQuantity > option.quantity &&
                        (option.maxQuantity === undefined ||
                          totalQuantity <= option.maxQuantity)
                      ) {
                        return (
                          <ProductDiscountNote
                            background_color={option.backgroundColor}
                            key={option.id}
                          >
                            {i18n.language === "en"
                              ? option.messageEN
                              : option.message}
                          </ProductDiscountNote>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ProductDiscountNotes>
                  <PriceQuantityContainer>
                    <QuantityContainer>
                      <QuantityTitle>{t("Basket.QuantityTitle")}</QuantityTitle>
                      <QuantitySelect
                        value={item.quantity}
                        onChange={(event) => handleQuantityChange(event, item)}
                      >
                        <option>{item.quantity}</option>
                        {[1, 3, 5, 10, 30, 50].map((quantity) => {
                          return item.quantity !== quantity ? (
                            <option key={quantity}>{quantity}</option>
                          ) : null;
                        })}
                      </QuantitySelect>
                    </QuantityContainer>
                    <ProductPrice>{item.article.price}RSD</ProductPrice>
                  </PriceQuantityContainer>
                </ProductInfo>
              </BodyDiv>
            ))}
          </BasketLeft>
          <BasketRight>
            <RightTitle>{t("BasketRight.NARUDZBINA_UKUPNO")}</RightTitle>
            <BillContainer>
              <BillWrapper>
                <SubtotalTitle>
                  {t("BasketRight.CENA_BEZ_POPUSTA")}
                </SubtotalTitle>
                <SubtotalPrice>
                  {totalPrice.toLocaleString()}.00RSD
                </SubtotalPrice>
              </BillWrapper>
              <BillWrapper>
                <SubtotalTitle>{t("BasketRight.DOSTAVA")}</SubtotalTitle>
                <SubtotalPrice>
                  {shippingCostDiscount.toLocaleString()}.00RSD
                </SubtotalPrice>
              </BillWrapper>
              <BillWrapper>
                <DiscountTitle>{t("BasketRight.SNIZENJE")}</DiscountTitle>
                <DiscountPrice>
                  {Math.round(
                    totalDiscountedPrice - totalPrice
                  ).toLocaleString()}
                  .00RSD
                </DiscountPrice>
              </BillWrapper>
              <TotalWrapper>
                <TotalTitle>{t("BasketRight.UKUPNO")}</TotalTitle>
                <TotalPrice>
                  {(
                    totalDiscountedPrice + shippingCostDiscount
                  ).toLocaleString()}
                  .00RSD
                </TotalPrice>
              </TotalWrapper>
            </BillContainer>

            <DeliveryNote>{t("BasketRight.TROSKOVI_PREVOZA")}</DeliveryNote>
            <CheckoutButton onClick={() => goToCheckout()}>
              {t("BasketRight.NASTAVI_NA_PLACANJE")}
            </CheckoutButton>

            <Help>
              <HelpTitle>{t("BasketRight.TREBA_VAM_POMOC")}</HelpTitle>
              <HelpDesc>{t("BasketRight.KONTAKTIRAJ_OSOBLJE")}</HelpDesc>
              <HelpTitle> +381 63 817 5099 </HelpTitle>
              <HelpDesc>{t("BasketRight.PON_NED_12_20")}</HelpDesc>
            </Help>
          </BasketRight>

          <BottomDiv>
            <BottomDivWrapper>
              <CheckoutButton onClick={() => goToCheckout()}>
                {t("BasketRight.NASTAVI_NA_PLACANJE")}
              </CheckoutButton>
            </BottomDivWrapper>
          </BottomDiv>
        </BasketContainer>
      ) : (
        <EmptyCartContainer>
          <EmptyCartIcon>
            <ProductionQuantityLimits style={{ fontSize: "80px" }} />
          </EmptyCartIcon>
          <EmptyCartTitle>{t("Basket.VASA_KORPA")}</EmptyCartTitle>
          <EmptyCartDesc>{t("Basket.VASA_KORPA_PRAZNA")}</EmptyCartDesc>
          <EmptyCartButton onClick={() => goToShop()}>
            {t("Basket.PRONADJI_SVOJ_SNUS")}
          </EmptyCartButton>
        </EmptyCartContainer>
      )}
      <RecommendedShopSlider sliderContent={recommendedArticles} />
      <Footer />
    </Container>
  );
};

export default Basket;
