import React, { useState, createContext, useEffect } from 'react';
import jwt_decode from "jwt-decode";
import { useNavigation } from '../utils/navigation';
import APIService from '../services/APIService';


export const AuthUserContext = createContext();
export const AuthUserProvider = ({ children }) => {
  let [user,setUser] = useState(() => localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null)
  const [emailUser, setEmailUser] = useState(() => localStorage.getItem('emailUser') ? JSON.parse(localStorage.getItem('emailUser')) : null);
  const { goToHome, goToRegistration } = useNavigation()

  let [orderHistory,setOrderHistory] = useState(() => localStorage.getItem('order-history') ? JSON.parse(localStorage.getItem('order-history')) : null);

  let loginUser = async (email,password=null) => {
    try {
      const response = await APIService.Login(email, password); 
      setUser(response)
      localStorage.setItem('user', JSON.stringify(response))
      if (window.location.pathname == '/prijava'){
        goToHome();
      }else if (window.location.pathname == '/narudzbina'){
        window.location.reload()
      }
    } catch (error) {
      if(error.response.status === 404) {
        if(password === null){
          goToRegistration()
        }
      }else{
        //promeni display na block stila - .login-error-note-div
        document.querySelector('.login-error-note-div').style.display = 'block';
      }
      console.error(error);
      
    }
  }

  let logoutUser =  () => {
    console.log("Logout aktiviran")
    setUser(null)
    localStorage.removeItem('user')
    setEmailUser(null)
    localStorage.removeItem('emailUser');
    goToHome();
  }
  const getOrderHistory = async () => {
    if(user == null) { return; }
    try {
      const response = await APIService.orderHistory(user.customer_id); 
      console.log(response)
      setOrderHistory(response)
      localStorage.setItem('order-history', JSON.stringify(response))
    } catch (error) {
      console.error(error);
    }
  };

  let contextData = {
    loginUser:loginUser,
    logoutUser:logoutUser,
    user:user,
    setUser:setUser,
    emailUser:emailUser,
    setEmailUser:setEmailUser,
    orderHistory:orderHistory,
    getOrderHistory:getOrderHistory,
    }

  return (
    <AuthUserContext.Provider value={contextData}>
      {children}
    </AuthUserContext.Provider>
  );
};
