import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";
import styled from "styled-components"
import { popularProducts } from "../data";
import { useState } from "react";
import ShopProduct from "./ShopProduct";


const Container = styled.div`
`;
const Wrapper = styled.div`
  /*display:flex;
  align-items: center;
  justify-content: left;
  flex-wrap:wrap;
  padding:0px;
  margin:10px 0px;
  @media only screen and (max-width:768px){
    margin:0px;
    gap:0px;
  }
   */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  @media only screen and (min-width:768px){
    grid-template-columns: repeat(4, 1fr);
    padding: 10px 25vh;
    grid-gap:10px;
  }
  
  

`;
const ShopProducts = ({articles}) => {
  const [slideIndex, setSlideIndex] = useState(0);
    const handleClick = (direction) =>
    {
//DORADA KADA SE UBACI DA DOBAVLJAM IZ BAZE PODATKE, SLIKE ITD..
        if(direction === "left"){
            setSlideIndex(slideIndex > 0  ? slideIndex-1 : 2)
        } else {
            setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0)
        }
    };
  return (
    <Container>

      <Wrapper>
        {articles.map(item=>(
            <ShopProduct item={item} key={item.id}/>
        ))}
        </Wrapper>
    </Container>
  )
}

export default ShopProducts