import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MainNavbar from "../layouts/header/MainNavbar";
import Header from "../layouts/header/Header";
import Footer from "../layouts/footer/Footer";
import { useContext } from "react";
import { AuthUserContext } from "../context/AuthUserContext";
import { useNavigation } from "../utils/navigation";
import APIService from "../services/APIService";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Places from "../components/customer/Places";
import Loading from "../components/Loading";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;
const Register = () => {
  const { t } = useTranslation();
  const { setUser, user, emailUser, loginUser, setEmailUser } =
    useContext(AuthUserContext);
  const { goToHome, goToCheckout } = useNavigation();
  const [name, setName] = useState(() => (emailUser ? emailUser.name : ""));
  const [errorNameText, setErrorNameText] = useState("");

  const [surname, setSurname] = useState(() =>
    emailUser ? emailUser.surname : ""
  );
  const [errorSurnameText, setErrorSurnameText] = useState("");

  const [email, setEmail] = useState(() => (emailUser ? emailUser.email : ""));
  const [ErrorEmailText, setErrorEmailText] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [houseNumber, setHouseNumber] = useState(undefined);

  const [address1, setAddress1] = useState("");
  const [errorMsgAddress1, setErrorMsgAddress1] = useState("");

  const [address2, setAddress2] = useState(undefined);

  const [city, setCity] = useState("");
  const [errorMsgCity, setErrorMsgCity] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [errorMsgPostalCode, setErrorMsgPostalCode] = useState("");

  const [mobile, setMobile] = useState("");
  const [errorMsgMobile, setErrorMsgMobile] = useState("");

  const [password, setPassword] = useState(undefined);
  const [errorPasswordText, setErrorPasswordText] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const [password2, setPassword2] = useState(undefined);
  const [errorPasswordText2, setErrorPasswordText2] = useState("");
  const [isVisible2, setIsVisible2] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (password !== password2) {
      setErrorPasswordText2(t("Register.PASSWORD_DO_NOT_MATCH"));
    } else {
      setErrorPasswordText2("");
    }
  }, [password, password2]);

  const changeAccount = () => {
    localStorage.removeItem("emailUser");
    setEmailUser(null);
    setName("");
    setErrorNameText(t("Register.REQUIRED_FIELD"));
    setSurname("");
    setErrorSurnameText(t("Register.REQUIRED_FIELD"));
    setEmail("");
    setErrorEmailText(t("Register.REQUIRED_FIELD"));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Provera da li je errorMsgPrazan prazan svugde
    if (
      errorNameText === "" &&
      errorSurnameText === "" &&
      ErrorEmailText === "" &&
      errorMsgAddress1 === "" &&
      errorMsgCity === "" &&
      errorMsgPostalCode === "" &&
      errorMsgMobile === "" &&
      errorPasswordText === "" &&
      errorPasswordText2 === ""
    ) {
      // Pozovite funkciju Register

      Register();
    } else {
    }
  };
  let Register = async () => {
    setLoading(true);
    try {
      const response = await APIService.Register(
        name,
        surname,
        email,
        address1,
        city,
        postalCode,
        mobile,
        houseNumber,
        address2,
        password
      );
      console.log(response);
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response));
      if (window.location.pathname == "/narudzbina/registracija") {
        goToCheckout();
        return;
      }
      goToHome();
    } catch (error) {
      if (error.response.status === 404) {
        alert("Greska");
      } else if (error.response.status === 400) {
        alert("Email Address Err");
      }
      console.error(error);
    }
    setLoading(false);
  };
  // GOOGLE
  function handleCallbackResponse(response) {
    var userObject = jwt_decode(response.credential);
    localStorage.setItem(
      "emailUser",
      JSON.stringify({
        email: userObject.email,
        name: userObject.given_name,
        surname: userObject.family_name,
        avatar: userObject.picture,
      })
    );
    setEmailUser({
      email: userObject.email,
      name: userObject.given_name,
      surname: userObject.family_name,
      avatar: userObject.picture,
    });
    loginUser(userObject.email);
    setName(userObject.given_name);
    setErrorNameText("");
    setSurname(userObject.family_name);
    setErrorSurnameText("");
    setEmail(userObject.email);
    setErrorEmailText("");

    setErrorPasswordText("");
    setErrorPasswordText2("");
  }

  useEffect(() => {
    /*global google*/
    google.accounts.id.initialize({
      client_id:
        "289868755464-ifopbs2daeogjlg81ftdp92vgpat5av6.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outlined",
      size: "large",
    });
  }, []);
  return (
    <div>
      <Helmet>
        <title>Registracija | Snus Srbija</title>
        <meta
          name="description"
          content="Napravite nalog i registrujte se na našoj stranici za ekskluzivan pristup. Pridružite se našoj zajednici i uživajte u brojnim pogodnostima. Brza i jednostavna registracija."
        />
        <meta property="og:title" content="Registracija | Snus Srbija" />
        <meta
          property="og:description"
          content="Napravite nalog i registrujte se na našoj stranici za ekskluzivan pristup. Pridružite se našoj zajednici i uživajte u brojnim pogodnostima. Brza i jednostavna registracija."
        />
        <link rel="canonical" href="https://www.snussrbija.com/registracija" />
        <meta name="keywords" content="snus registracija, snus serbia" />
      </Helmet>
      {window.location.pathname == "/registracija" ? (
        <MainNavbar />
      ) : (
        <Header />
      )}
      <Container>
        {loading && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              zIndex: "99999",
            }}
          >
            <Loading />
          </div>
        )}
        <div className="login-form-div">
          {emailUser && (
            <div style={{ marginTop: "100px" }}>
              <div className="register-avatar-div">
                <img src={emailUser.avatar} className="register-avatar-img" />
                <h3 className="register-avatar-title">
                  {t("Register.GREETING")} {emailUser.name} {emailUser.surname}
                </h3>
                <ul className="register-avatar-ul">
                  <li>{t("Register.REGISTER_AVATAR_TEXT_1")}</li>
                  <li>{t("Register.REGISTER_AVATAR_TEXT_2")}</li>
                </ul>
                <button
                  className="login-form-button"
                  onClick={() => changeAccount()}
                >
                  {t("Register.CHANGE_ACCOUNT")}
                </button>
              </div>
            </div>
          )}

          <div style={emailUser ? { display: "none" } : { display: "block" }}>
            <h1 className="login-title" style={{ marginTop: "100px" }}>
              {t("Register.REGISTER_TITLE")}
            </h1>

            <div id="signInDiv"></div>
            <h6 className="register-top-text">
              {t("Register.REGISTER_TOP_TEXT")}
            </h6>
          </div>

          <div className="login-br" />
          {emailUser ? (
            <h1 className="login-title" style={{ marginTop: "40px" }}>
              {t("Register.REGISTER_TITLE_WITHOUT_PASSWORD")}
            </h1>
          ) : (
            <h1 className="login-title" style={{ marginTop: "40px" }}>
              {t("Register.REGISTER_TITLE_MANUAL")}
            </h1>
          )}
          <form className="form1" onSubmit={(e) => handleSubmit(e)}>
            <label className="login-label">{t("Register.NAME_LABEL")}</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="input1"
              style={{ height: "8px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorNameText("");
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorNameText(t("Register.REQUIRED_FIELD"));
                } else {
                  e.target.style.borderBottom = "1px solid aqua";
                  setErrorNameText("");
                }
              }}
            />
            <motion.h3
              className="input-required-text"
              style={{ padding: "0", fontSize: "10px" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: errorNameText !== "" ? 1 : 0,
                y: errorNameText !== "" ? 0 : 10,
              }}
              transition={{ duration: 0.3 }}
            >
              <svg
                fill="var(--error-color)"
                width="var(--font-size-base)"
                height="var(--font-size-base)"
                viewBox="0 0 36 36"
                version="1.1"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>error-solid</title>
                <path d="M18,6A12,12,0,1,0,30,18,12,12,0,0,0,18,6Zm-1.49,6a1.49,1.49,0,0,1,3,0v6.89a1.49,1.49,0,1,1-3,0ZM18,25.5a1.72,1.72,0,1,1,1.72-1.72A1.72,1.72,0,0,1,18,25.5Z"></path>
                <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
              </svg>
              {errorNameText}
            </motion.h3>

            <label className="login-label">{t("Register.SURNAME_LABEL")}</label>
            <input
              type="text"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              required
              className="input1"
              style={{ height: "8px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorSurnameText("");
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorSurnameText(t("Register.REQUIRED_FIELD"));
                } else {
                  e.target.style.borderBottom = "1px solid aqua";
                  setErrorSurnameText("");
                }
              }}
            />
            <motion.h3
              className="input-required-text"
              style={{ padding: "0", fontSize: "10px" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: errorSurnameText !== "" ? 1 : 0,
                y: errorSurnameText !== "" ? 0 : 10,
              }}
              transition={{ duration: 0.3 }}
            >
              {errorSurnameText}
            </motion.h3>

            <label className="login-label">{t("Register.EMAIL_LABEL")}</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
              readOnly={emailUser}
              required
              className="input1"
              style={{ height: "8px" }}
              // style for input when it's selected and empty
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorEmailText("");
              }}
              // style for input when it's not selected or has content
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorEmailText(t("Register.REQUIRED_FIELD"));
                } else if (!emailRegex.test(e.target.value)) {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorEmailText(t("Register.INVALID_EMAIL"));
                } else {
                  e.target.style.borderBottom = "1px solid aqua";
                  setErrorEmailText("");
                }
              }}
            />
            <motion.h3
              className="input-required-text"
              style={{ padding: "0", fontSize: "10px" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: ErrorEmailText !== "" ? 1 : 0,
                y: ErrorEmailText !== "" ? 0 : 10,
              }}
              transition={{ duration: 0.3 }}
            >
              {ErrorEmailText}
            </motion.h3>

            <div className="login-br" />
            <h1 className="login-title">{t("Register.ADDRESS_LABEL")}</h1>

            <label className="login-label">{t("Register.COUNTRY_LABEL")}</label>
            <input
              type="text"
              placeholder={t("Register.SERBIA")}
              className="input1"
              style={{ height: "8px", color: "#686868" }}
              readOnly
              onFocus={(e) => {
                e.target.style.borderBottom = "2px solid teal";
              }}
              onBlur={(e) => {
                e.target.style.borderBottom = "none";
              }}
            />
            <h3
              className="register-note-label"
              style={{ marginBottom: "40px" }}
            >
              {t("Register.DELIVERY_ONLY_IN_SERBIA")}
            </h3>
            <Places
              placeValue={city}
              setPlaceValue={setCity}
              zipCodeValue={postalCode}
              setZipCodeValue={setPostalCode}
              streetValue={address1}
              setStreetValue={setAddress1}
            />
            {/* <label className="login-label">{t("Register.CITY_LABEL")}</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              className="input1"
              style={{ height: "8px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorMsgCity("");
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorMsgCity(t("Register.REQUIRED_FIELD"));
                } else {
                  e.target.style.borderBottom = "1px solid aqua";
                  setErrorMsgCity("");
                }
              }}
            />
            <motion.h3
              className="input-required-text"
              style={{ padding: "0", fontSize: "10px" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: errorMsgCity !== "" ? 1 : 0,
                y: errorMsgCity !== "" ? 0 : 10,
              }}
              transition={{ duration: 0.3 }}
            >
              {errorMsgCity}
            </motion.h3>

            <label className="login-label">
              {t("Register.POSTAL_CODE_LABEL")}
            </label>
            <input
              type="tel"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              required
              className="input1"
              style={{ height: "8px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorMsgPostalCode("");
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorMsgPostalCode(t("Register.REQUIRED_FIELD"));
                } else {
                  e.target.style.borderBottom = "1px solid aqua";
                  setErrorMsgPostalCode("");
                }
              }}
            />
            <motion.h3
              className="input-required-text"
              style={{ padding: "0", fontSize: "10px" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: errorMsgPostalCode !== "" ? 1 : 0,
                y: errorMsgPostalCode !== "" ? 0 : 10,
              }}
              transition={{ duration: 0.3 }}
            >
              {errorMsgPostalCode}
            </motion.h3>

            <label className="login-label">
              {t("Register.ADDRESS1_LABEL")}
            </label>
            <input
              type="text"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              required
              className="input1"
              style={{ height: "8px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorMsgAddress1("");
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorMsgAddress1(t("Register.REQUIRED_FIELD"));
                } else {
                  e.target.style.borderBottom = "1px solid aqua";
                  setErrorMsgAddress1("");
                }
              }}
            />
            <motion.h3
              className="input-required-text"
              style={{ padding: "0", fontSize: "10px" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: errorMsgAddress1 !== "" ? 1 : 0,
                y: errorMsgAddress1 !== "" ? 0 : 10,
              }}
              transition={{ duration: 0.3 }}
            >
              {errorMsgAddress1}
            </motion.h3> */}

            <div className="register-label-div">
              <label className="login-label">
                {t("Register.HOUSE_NUMBER_LABEL")}
              </label>
              {/* <h6 className="register-optional-text">
                {t("Register.OPTIONAL_TEXT")}
              </h6> */}
            </div>
            <input
              type="tel"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
              className="input1"
              style={{ height: "8px", marginBottom: "14px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
              }}
              onBlur={(e) => {
                e.target.style.borderBottom = "none";
              }}
            />

            <div className="register-label-div">
              <label className="login-label">
                {t("Register.ADDRESS2_LABEL")}
              </label>
              <h6 className="register-optional-text">
                {t("Register.OPTIONAL_TEXT")}
              </h6>
            </div>
            <input
              type="text"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              className="input1"
              style={{ height: "8px", marginBottom: "14px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
              }}
              onBlur={(e) => {
                e.target.style.borderBottom = "none";
              }}
            />

            <label className="login-label">{t("Register.MOBILE_LABEL")}</label>
            <input
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
              className="input1"
              style={{ height: "8px" }}
              onFocus={(e) => {
                e.target.style.borderBottom = "1px solid aqua";
                setErrorMsgMobile("");
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.style.borderBottom = "2px solid #991f39";
                  setErrorMsgMobile(t("Register.REQUIRED_FIELD"));
                } else {
                  e.target.style.borderBottom = "1px solid aqua";
                  setErrorMsgMobile("");
                }
              }}
            />
            <motion.h3
              className="input-required-text"
              style={{ padding: "0", fontSize: "10px" }}
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: errorMsgMobile !== "" ? 1 : 0,
                y: errorMsgMobile !== "" ? 0 : 10,
              }}
              transition={{ duration: 0.3 }}
            >
              {errorMsgMobile}
            </motion.h3>
            {!emailUser && (
              <>
                <div className="login-br" />

                <h1 className="login-title">
                  {t("Register.YOUR_LOGIN_INFORMATION")}
                </h1>

                <label className="login-label">
                  {t("Register.PASSWORD_LABEL")}
                </label>

                <div
                  className="login-input-container"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{ position: "absolute", right: "0%", top: "6px" }}
                  >
                    {isVisible ? (
                      <Visibility onClick={() => setIsVisible(!isVisible)} />
                    ) : (
                      <VisibilityOff onClick={() => setIsVisible(!isVisible)} />
                    )}
                  </div>
                  <input
                    type={isVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={!emailUser}
                    className="input1"
                    style={{ height: "8px", width: "100%" }}
                    // style for input when it's selected and empty
                    onFocus={(e) => {
                      e.target.style.borderBottom = "1px solid aqua";
                      setErrorPasswordText("");
                    }}
                    // style for input when it's not selected or has content
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        e.target.style.borderBottom = "2px solid #991f39";
                        setErrorPasswordText(t("Register.REQUIRED_FIELD"));
                      } else {
                        e.target.style.borderBottom = "1px solid aqua";
                        setErrorPasswordText("");
                      }
                    }}
                  />
                </div>

                <motion.h3
                  className="input-required-text"
                  style={{ padding: "0", fontSize: "10px" }}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{
                    opacity: errorPasswordText !== "" ? 1 : 0,
                    y: errorPasswordText !== "" ? 0 : 10,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {errorPasswordText}
                </motion.h3>

                <label className="login-label">
                  {t("Register.PASSWORD_CONFIRM_LABEL")}
                </label>

                <div
                  className="login-input-container"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{ position: "absolute", right: "0%", top: "6px" }}
                  >
                    {isVisible2 ? (
                      <Visibility onClick={() => setIsVisible2(!isVisible2)} />
                    ) : (
                      <VisibilityOff
                        onClick={() => setIsVisible2(!isVisible2)}
                      />
                    )}
                  </div>
                  <input
                    type={isVisible2 ? "text" : "password"}
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    required={!emailUser}
                    className="input1"
                    style={{ height: "8px", width: "100%" }}
                    // style for input when it's selected and empty
                    onFocus={(e) => {
                      e.target.style.borderBottom = "1px solid aqua";
                      setErrorPasswordText2("");
                    }}
                    // style for input when it's not selected or has content
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        e.target.style.borderBottom = "2px solid #991f39";
                        setErrorPasswordText2(t("Register.REQUIRED_FIELD"));
                      } else {
                        e.target.style.borderBottom = "1px solid aqua";
                        setErrorPasswordText2("");
                      }
                    }}
                  />
                </div>

                <motion.h3
                  className="input-required-text"
                  style={{ padding: "0", fontSize: "10px" }}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{
                    opacity: errorPasswordText2 !== "" ? 1 : 0,
                    y: errorPasswordText2 !== "" ? 0 : 10,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {errorPasswordText2}
                </motion.h3>
              </>
            )}
            <button type="submit" className="login-form-button">
              {t("Register.REGISTER_TITLE")}
            </button>
          </form>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Register;
