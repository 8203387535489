import { ArrowRightAlt, Details, PersonOutlineOutlined } from "@mui/icons-material";
import styled, {keyframes, css} from "styled-components";
import {useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../../context/AuthUserContext";
import { useTranslation } from "react-i18next";

const fadeInTop = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;
const fadeOutBottom = keyframes`
  from {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  to {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`;
const Container = styled.div`
  ${({ isOpen }) => isOpen
    ? css`
      display: none;
    `
    : css`
    display:block;
    `}

    color: black;
    padding: 0;
    border: 0;
    outline: none;
    position:fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    width: 100vw;
    font-weight: 700;
    height:100vh;
    background-color: transparent;
    z-index:99999;
    @media only screen and (min-width:400px){
      display:flex;
      //align-items: center;
      justify-content: right;
    }
`;
const Box = styled.div`
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  margin-top:70px;
 
  ${({ isOpen }) => isOpen
    ? css`
      animation: ${fadeOutBottom} 0.5s ease-in-out both;
    `
    : css`
      animation: ${fadeInTop} 0.5s ease-in-out both;
    `}
    
    width: 25%;
    //margin:0;
    background-color: teal;
    padding:0px;
    @media only screen and (max-width:768px){
        //height:40vh;
        width:100vw;
    }
    top:0;
    //padding:20px;
    @media only screen and (min-width:768px){
      margin-right:100px;
      height:40vh;
      border:2px solid #fff;
    }
    
`;
const BoxWrapper = styled.div`
  padding:10px;
`
const Welcome = styled.h3`
  padding:20px 0;
  margin:0;
  font-size:16px;
  font-weight: 100;
  color:#fff;
  margin-bottom: 10px;
`;
const DetailsBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px 10px;
`
const DetailsBottom = styled.div`
  border-top:2px solid #fff;
  padding:20px 0px;
  margin-top:20px;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
  to {
    opacity: 1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`;

const Component  = styled.div`
    vertical-align: baseline;
    outline: none;
    display: inline-block;
    position: relative;
    //
    @media only screen and (max-width:768px){
        margin-right:0;
    }

`;
const Button  = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    margin:0;
    padding:0px;
    background-color: transparent;
    position:relative;
`;
const ButtonLink = styled.div`
  margin: 0;
  padding:0;
  display:flex;
  align-items: center;
  justify-content: center;  
  flex-direction:column;
  cursor: pointer;
  color: #fff;
  &:hover{
    color: #52c5c5;
  }
  
`;
const Span  = styled.span`
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 12px;
    font-family: "Urbanist";
    
    font-weight: 100;
    transition: .4s all cubic-bezier(.22,1,.36,1);
`;
const Ul  = styled.ul`
    padding: 0;
    border: 0;
    outline: none;
    list-style: none;
    position: absolute;
    top: 130%;
    right: 0;
    margin: 0;
    width: 250px;
    box-shadow: 0 2.8px 2.2px #00000009,0 6.7px 5.3px #0000000c,0 12.5px 10px #0000000f,0 22.3px 17.9px #00000012,0 41.8px 33.4px #00000016,0 100px 80px #0000001f;
    display: block;
    pointer-events: all;
    background-color: none;
    animation: ${fadeIn} 0.3s ease-out ;
    animation-delay:0ms;
    z-index: 999;
`;
const Li  = styled.li`
    font-weight: 400;
    text-align: left;
    padding: 14px;
    font-size: 14px;
    //line-height: 20px;
    background: #fff;
    border-bottom: 1px solid #dedede;
    cursor: pointer;
    font-family: "Righteous";
    color: #1f1f1f;
    text-transform:uppercase;
    &:hover{
        color:white;
        background-color: #52c5c5;
    }
`;
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #fff;
  position:absolute;
`;
const TriangleSec = styled(Triangle)`
  @media only screen and (max-width:768px){
    display:none;
  }
`
const DetailsButton = styled.button`
  width: 100%;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 20px;
  text-decoration: none;
  transition: .6s all cubic-bezier(.25,1,.5,1);
  background-color: transparent;
  border: 3px solid #fff;
  color: #fff;
  display: block;
  cursor: pointer;
  &:hover{
      background-color:#fff;
      color:teal;
  }
  @media only screen and (min-width:768px){
    font-size:10px;
    padding: 10px 20px;
  }
`
const ButtonViewAll = styled(Button)`
  color:white;
  font-size:16px;
  gap:10px;
  text-transform:uppercase;

`;
const AccountDropDown = () => {
  const { t } = useTranslation();
  const { user, logoutUser } = useContext(AuthUserContext);
  const items = !user
    ? [
        {
          name: t('AccountDropDown.Login'),
          link: '/prijava',
        },
        {
          name: t('AccountDropDown.Register'),
          link: '/registracija',
        },
      ]
    : [
        {
          name: t('AccountDropDown.MyAccount'),
          link: '/kupac/moj-nalog/',
        },
        {
          name: t('AccountDropDown.MyDetails'),
          link: '/kupac/moji-detalji/',
        },
        {
          name: t('AccountDropDown.OrderHistory'),
          link: '/kupac/istorija-narudzbina/',
        },
        {
          name: t('AccountDropDown.AddressBook'),
          link: '/kupac/adresar/',
        },
      ];
  const [isActive, setIsActive] = useState(false);
  
  const [isOpenContainer, setIsOpenContainer] = useState(true);
  const [isOpenBox, setIsOpenBox] = useState(true);
  const [isBoxAnimating, setIsBoxAnimating] = useState(false);

  const toggleBox = () => {
    
    if (!user) { 
      setIsActive(!isActive)
      return 
    }
    if (isBoxAnimating) {
      return;
    }
    setIsOpenBox(!isOpenBox);
    setIsBoxAnimating(true);
    if (isOpenContainer === false) {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
        setIsActive(!isActive)
      }, 500);
    } else {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
        setIsActive(!isActive)
      }, 10);
    }
  };
  return (
    <Component>
      <Button onClick={toggleBox}>
        <ButtonLink>
          <PersonOutlineOutlined style={{ fontSize: "30px" }} />
          <Span>{t('AccountDropDown.Account')}</Span>
        </ButtonLink>
      </Button>
      {isActive && (
        <>
          {!user ?
          <>
          <Triangle/>
          <Ul>
            {items.map((item, index) => (
              <Li key={index} delay={index * 100}>
              <Link
                to={item.link}
                style={{ textDecoration: 'none', border: 'none', color: 'inherit'}}
                onClick={item.action}
              >
                  {item.name}
              </Link>
                </Li>
            ))}
          </Ul>
          </>
          :
          <>
          <TriangleSec/>
          <Container onClick={toggleBox} isOpen={isOpenContainer} isBoxAnimating={isBoxAnimating}>
            <Box isOpen={isOpenBox} isBoxAnimating={isBoxAnimating} onClick={(event) => event.stopPropagation()}>
            <BoxWrapper>
              {/* <div style={{backgroundColor:"orange", height:"100px", position:"absolute", width:"90%"}}>sta se dje</div> */}
                <Welcome>{t('AccountDropDown.WELCOME')} {user.first_name}</Welcome>
                <DetailsBody>
                {items.map((item, index) => (
                  <DetailsButton onClick={toggleBox} key={index} ><Link style={{textDecoration:"none", all:"unset"}} to={item.link}>{item.name}</Link></DetailsButton>
                ))}
                </DetailsBody>
                <DetailsBottom>
                <ButtonViewAll
                onClick={logoutUser}
                  >{t('AccountDropDown.Logout')}
                      <ArrowRightAlt style={{fontSize:"20px;"}}/>
                  
                  </ButtonViewAll>

                </DetailsBottom>
              </BoxWrapper>
            </Box>
          </Container>
          </>
          }
          
        </>
      )}
    </Component>
  );
};

export default AccountDropDown;