import { useNavigate } from "react-router-dom";

export function useNavigation() {
  const navigate = useNavigate();

  function goToHome() {
    navigate("/");
  }

  function goToCart() {
    navigate("/korpa");
  }

  function goToCheckout() {
    navigate("/narudzbina");
    window.location.reload();
  }

  function goToProduct(category, title) {
    const formattedCategory = encodeURIComponent(
      category.replace(/\s+/g, "-").toString()
    );
    const formattedTitle = encodeURIComponent(title.toString());
    navigate(`/${formattedCategory}-${formattedTitle}`);
  }

  function goToShop() {
    navigate("/snus-prodaja");
    window.location.reload();
  }
  function goToCategoryShop(categoryName) {
    const formattedCategoryName = categoryName.replace(/\s+/g, "-"); // Zamenjuje razmake sa "-"
    navigate(`/snus-prodaja/${formattedCategoryName}`);
    window.location.reload();
  }
  function goToNotFound() {
    navigate("/error/404");
  }
  function goToRegistration() {
    if (window.location.pathname == "/narudzbina") {
      navigate("/narudzbina/registracija");
      window.scrollTo(0, 0);
      return;
    }
    navigate("/registracija");
    window.scrollTo(0, 0);
  }
  function goToLogin() {
    navigate("/prijava");
    window.scrollTo(0, 0);
  }
  function goToForgotPassword() {
    navigate("/zaboravio-lozinku");
    window.scrollTo(0, 0);
  }

  // dodajte ostale funkcije za navigaciju prema potrebi

  return {
    goToHome,
    goToCart,
    goToCheckout,
    goToProduct,
    goToShop,
    goToNotFound,
    goToCategoryShop,
    goToRegistration,
    goToLogin,
    goToForgotPassword,
  };
}
