import styled, { keyframes, css } from "styled-components";
import Badge from "@mui/material/Badge";
import { Close, ShoppingCartOutlined } from "@mui/icons-material";
import { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { useNavigation } from "../../utils/navigation";
import { CartContext } from "../../context/CartContext";
import { discountOptions } from "../../data";
import { useTranslation } from "react-i18next";

const slideInRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;
const slideInLeft = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ContainerX = styled.div``;

const ContainerTitle = styled.h2`
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin: 0;
`;
const ContainerLink = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #52c5c5;
  }
  flex-direction: column;
`;

// const Container = styled.div`

// ${({ isOpen }) => isOpen
//     ? css`
//       display: none;
//     `
//     : css`
//      display:block;
//      animation: ${fadeIn} 1.0s ease-in-out both;
//     `}

//     color: black;
//     padding: 0;
//     border: 0;
//     outline: none;
//     position:fixed;
//     top: 0;
// bottom: 0;
// left: 0;
// right: 0;

//     width: 100%;
//     max-width: 100%;
//     max-height: 100%;
//     font-weight: 700;
//     height:100vh;
//     background-color: rgba(0, 0, 0, 0.3);
//     @media only screen and (max-width:768px){
//         width: 100%;
//     }
//     z-index:99999;

// `;
// const Box = styled.div`
//     overflow: auto; /* omogućava skrolanje unutar div-a */
//   -webkit-overflow-scrolling: touch;

//   ${({ isOpen }) => isOpen
//     ? css`
//       animation: ${slideInRight} 1.2s ease-in-out both;
//     `
//     : css`
//       animation: ${slideInLeft} 0.7s ease-in-out both;
//     `}

//     width: 35%;
//     background-color: white;
//     height:100%;
//     @media only screen and (max-width:768px){
//         height:100%;
//         width:100%;
//     }
//     top:0;
//     float:right;
//     background-color: #f5f5f5;
// `;

const Container = styled.div`
  ${({ isOpen }) =>
    isOpen
      ? css`
          display: none;
        `
      : css`
          display: block;
          animation: ${fadeIn} 1s ease-in-out both;
        `}

  color: black;
  padding: 0;
  border: 0;
  outline: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  max-width: 100%;
  max-height: 100%;
  font-weight: 700;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  z-index: 99999;
`;
const Box = styled.div`
  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;

  ${({ isOpen }) =>
    isOpen
      ? css`
          animation: ${slideInRight} 1.2s ease-in-out both;
        `
      : css`
          animation: ${slideInLeft} 0.7s ease-in-out both;
        `}

  width: 30%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
  }
  top: 0;
  float: right;
  background-color: #f5f5f5;
`;

const HeadBodyDiv = styled.div`
  padding: 40px;
  min-height: 100vh;
  padding-top: 20px;
`;
const HeadDiv = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  left: 0px;
  padding-top: 20px;
  background-color: #f5f5f5;
  z-index: 9;
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 25px;
  line-height: 1.4;
  font-weight: 700;
  color: #041e42;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 20px;
`;
const HeadIconWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;
const ColumnHeadWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid black;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    gap: 15px;
  }
`;
const ColumnTitle = styled.h2`
  margin: 0;
  margin-left: 30px;
  padding: 0;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 200;
  color: #041e42;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;
const TotalPrice = styled.div`
  margin: 0;
  margin-right: 30px;
  padding: 0;
  font-size: 25px;
  line-height: 1.4;
  font-weight: 600;
  color: #041e42;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    font-size: 17px;
  }
`;
const TotalItem = styled.h2`
  margin: 0;
  margin-left: 10px;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 100;
  color: #041e42;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
const EmptyNote = styled.h3`
  margin: 0;
  margin-left: 10px;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 100;
  color: #041e42;
  display: flex;
  justify-content: center;
`;

const BodyContainer = styled.div`
  margin-top: 30px;
  overflow-y: scroll;
  touch-action: pan-y;
`;
const BodyDiv = styled.div`
  display: flex;
  height: 200px;
  overflow: hidden;
  background-color: white;
  margin-top: 5px;
  @media only screen and (max-width: 768px) {
    height: 200px;
    //max-height: 250px;
  }
  position: relative;
`;
const BodyIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #00000013;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImgContainer = styled.picture`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  //width:10px;
`;
const ProductImg = styled.img`
  width: 100%;
  height: auto;
`;
const ProductInfo = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 10px;
  @media only screen and (max-width: 768px) {
    flex: 3;
  }
`;
const ProductTitle = styled.h1`
  margin: 0;
  padding: 0;
  padding-top: 10px;

  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
`;
const PriceNicotineContainer = styled.div`
  margin: 0;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ProductNicotine = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 1;
  font-weight: 100;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
`;
const ProductPrice = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
  margin-right: 20px;
`;
const ProductDiscountNotes = styled.div`
  margin: 0px;
  display: flex;
  align-items: left;
  gap: 3px;
  justify-content: left;
  flex-wrap: wrap;
`;
const ProductDiscountNote = styled.h2`
  background-color: ${(props) => props.background_color};
  margin: 0;
  padding: 7px 7px;
  font-size: 10px;
  line-height: 1;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: left;
  text-transform: uppercase;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
const QuantityContainer = styled.div`
  //padding:10px 0px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: left;
`;
const QuantityTitle = styled.h2`
  margin: 0;
  padding-top: 10px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #041e42;
  display: flex;
  align-items: left;
  text-transform: uppercase;
`;
const QuantitySelect = styled.select`
  width: 50px;
  height: 30px;
  display: flex;
`;

const BottomDiv = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0px;
  align-items: center;
`;
const CheckoutButton = styled.button`
  width: 80%;
  margin-top: 20px;
  border-radius: 100px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 20px;
  text-decoration: none;
  transition: 0.6s all cubic-bezier(0.25, 1, 0.5, 1);
  background: #5862bf;
  border: 2px solid #5862bf;
  color: #fff;
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background: #6b76da;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const ViewBasketButton = styled.button`
  width: 80%;
  border-radius: 100px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 20px;
  text-decoration: none;
  transition: 0.6s all cubic-bezier(0.25, 1, 0.5, 1);
  background: #fff;
  border: 2px solid #5862bf;
  color: #5862bf;
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background: #ebebeb;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const CustomDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;
const CustomInput = styled.input`
  font-size: 14px;
  text-align: left;
  border-radius: 2px;

  width: 40px;
  height: 30px;
  padding: 0;
  padding-left: 5px;
  background-color: #efefef;
  border: 1px solid #2b2b2b45;
`;
const CustomButton = styled.div`
  padding: 10px 10px;
  font-size: 15px;
  font-weight: 100;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  background: #5862bf;
  color: #fff;
`;

const CartMenu = () => {
  const { t, i18n } = useTranslation();
  const [isOpenContainer, setIsOpenContainer] = useState(true);
  const [isOpenBox, setIsOpenBox] = useState(true);
  const [isBoxAnimating, setIsBoxAnimating] = useState(false);
  const toggleBox = () => {
    if (isBoxAnimating === true) {
      return;
    }
    setIsOpenBox(!isOpenBox);
    setIsBoxAnimating(true);
    if (isOpenContainer === false) {
      setTimeout(() => {
        setIsOpenContainer(!isOpenContainer);
        setIsBoxAnimating(false);
      }, 1400); // 1400ms is equal to animation duration in milliseconds
    } else {
      setIsOpenContainer(!isOpenContainer);
      setIsBoxAnimating(false);
    }
  };

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const cartItems = useSelector((state) => state.cart.itemsList);
  const { totalPrice, totalDiscountedPrice } = useContext(CartContext);
  const [customQuantityInput, setCustomQuantityInput] = useState(null);
  const quantityInput = useRef(null);

  const dispatch = useDispatch();
  const deleteCartItem = (id) => {
    dispatch(cartActions.removeFromCart(id));
  };
  const handleQuantityChange = (event, item) => {
    if (event.target.value === "custom") {
      setCustomQuantityInput(item.article.id);
      return;
    }
    const newQuantity = parseInt(event.target.value);
    dispatch(
      cartActions.updateCart({
        article: item.article,
        quantity: newQuantity,
      })
    );
  };
  const handleButtonQuantityClick = (item) => {
    setCustomQuantityInput(null);
    let newQuantity = parseInt(quantityInput.current.value);
    if (isNaN(newQuantity) | (newQuantity === 0)) {
      return;
    } else if (newQuantity < 0) {
      newQuantity = -newQuantity;
    }
    dispatch(
      cartActions.updateCart({
        article: item.article,
        quantity: newQuantity,
      })
    );
  };

  const { goToCart, goToCheckout } = useNavigation();
  const handleViewBasket = (event) => {
    toggleBox();
    goToCart();
  };
  const handleCheckout = (event) => {
    toggleBox();
    goToCheckout();
  };

  return (
    <ContainerX>
      <ContainerLink onClick={toggleBox}>
        <Badge badgeContent={totalQuantity} color="primary">
          <ShoppingCartOutlined style={{ color: "white", fontSize: 30 }} />
        </Badge>
        <ContainerTitle>{t("CartMenu.ContainerTitle")}</ContainerTitle>
      </ContainerLink>
      <Container
        onClick={toggleBox}
        isOpen={isOpenContainer}
        isBoxAnimating={isBoxAnimating}
      >
        <Box
          isOpen={isOpenBox}
          isBoxAnimating={isBoxAnimating}
          onClick={handleStopPropagation}
        >
          <HeadBodyDiv>
            <HeadDiv>
              <Title>{t("CartMenu.Title")}</Title>
              <HeadIconWrapper onClick={toggleBox}>
                <Close style={{ fontSize: "30px" }} />
              </HeadIconWrapper>
              <ColumnHeadWrapper>
                <ColumnTitle>{t("CartMenu.ColumnTitle")}</ColumnTitle>
                <TotalPrice>
                  {totalDiscountedPrice.toLocaleString()}RSD{" "}
                  <TotalItem>
                    ({totalQuantity}) {t("CartMenu.Items")}
                  </TotalItem>
                </TotalPrice>
              </ColumnHeadWrapper>
            </HeadDiv>

            {totalQuantity && Number(totalQuantity) > 0 ? (
              <BodyContainer>
                {cartItems.map((item, index) => (
                  <BodyDiv key={item.article.id}>
                    <BodyIconWrapper>
                      <Close
                        style={{ fontSize: "25px", color: "black" }}
                        onClick={() => deleteCartItem(item.article.id)}
                      />
                    </BodyIconWrapper>
                    <ImgContainer>
                      <ProductImg
                        src={
                          item.article.images.find(
                            (img) => img.is_primary_image
                          )?.image_url || ""
                        }
                        alt={item.article.title}
                      />
                    </ImgContainer>
                    <ProductInfo>
                      <ProductTitle>
                        {item.article.category} {item.article.title}
                      </ProductTitle>
                      <PriceNicotineContainer>
                        <ProductNicotine>
                          {item.article.nicotine}MG
                        </ProductNicotine>
                        <ProductPrice>
                          {item.article.discountPrice}RSD
                        </ProductPrice>
                      </PriceNicotineContainer>
                      <ProductDiscountNotes>
                        {discountOptions.map((option) => {
                          if (
                            totalQuantity > option.quantity &&
                            (option.maxQuantity === undefined ||
                              totalQuantity <= option.maxQuantity)
                          ) {
                            return (
                              <ProductDiscountNote
                                background_color={option.backgroundColor}
                                key={option.id}
                              >
                                {i18n.language === "en"
                                  ? option.messageEN
                                  : option.message}
                              </ProductDiscountNote>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </ProductDiscountNotes>
                      {customQuantityInput &&
                      customQuantityInput === item.article.id ? (
                        <CustomDiv>
                          <QuantityTitle>
                            {t("CartMenu.QuantityTitle")}
                          </QuantityTitle>
                          <CustomInput
                            type="number"
                            name="quantity"
                            ref={quantityInput}
                            min="1"
                          />
                          <CustomButton
                            onClick={() => handleButtonQuantityClick(item)}
                          >
                            Update
                          </CustomButton>
                        </CustomDiv>
                      ) : (
                        <QuantityContainer>
                          <QuantityTitle>
                            {t("CartMenu.QuantityTitle")}
                          </QuantityTitle>
                          <QuantitySelect
                            value={item.quantity}
                            onChange={(event) =>
                              handleQuantityChange(event, item)
                            }
                          >
                            <option>{item.quantity}</option>
                            {[1, 3, 5, 10, 50].map((quantity) => {
                              return item.quantity !== quantity ? (
                                <option key={quantity}>{quantity}</option>
                              ) : null;
                            })}
                            <option value="custom">1+</option>
                          </QuantitySelect>
                        </QuantityContainer>
                      )}
                    </ProductInfo>
                  </BodyDiv>
                ))}
              </BodyContainer>
            ) : (
              <EmptyNote>{t("CartMenu.EmptyNote")}</EmptyNote>
            )}
          </HeadBodyDiv>
          <BottomDiv>
            <CheckoutButton onClick={handleCheckout}>
              {t("CartMenu.CheckoutButton")}
            </CheckoutButton>
            <ViewBasketButton onClick={handleViewBasket}>
              {t("CartMenu.ViewBasketButton")}
            </ViewBasketButton>
          </BottomDiv>
        </Box>
      </Container>
    </ContainerX>
  );
};

export default CartMenu;
