import { Close, KeyboardArrowDown, KeyboardArrowUp, Tune } from "@mui/icons-material";
import styled , {keyframes, css} from "styled-components";
import { useState, useEffect, useContext } from 'react';
import APIService from '../services/APIService'
import { ProductContext } from "../context/ProductContext";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

const slideInRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;
const slideInLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ContainerX = styled.div`
  
`;

const ContainerTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  margin-right: 10px;
  font-family: "Righteous";
  font-weight: 100;
  text-transform: uppercase;
`;
const ContainerLink = styled.div`
  margin: 0;
  display:flex;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  &:hover{
    color: #52c5c5;;
  }
`;
const Container = styled.div`

${({ isOpen }) => isOpen
    ? css`
      display: none;
    `
    : css`
     display:block;
     //animation: ${fadeIn} 0.5s ease-in-out both;
    `}

    color: black;
    padding: 0;
    border: 0;
    outline: none;
    position:fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;

    width: 100%;
    max-width: 100%;
    max-height: 100%;
    font-weight: 700;
    height:100vh;
    background-color: rgba(0, 0, 0, 0.3);
    @media only screen and (max-width:768px){
        width: 100%;
    }
    z-index:999;

`;
const Box = styled.div`
overflow: auto; /* omogućava skrolanje unutar div-a */
-webkit-overflow-scrolling: touch;
  ${({ isOpen }) => isOpen
    ? css`
      animation: ${slideInRight} 1.2s ease-in-out both;
    `
    : css`
      animation: ${slideInLeft} 0.3s ease-in both;
    `}
    
    width: 20%;
    //margin:0;
    background-color: white;
    height:100%;
    padding:0px;
    @media only screen and (max-width:768px){
        height:100%;
        width:90%;
    }
    top:0;
    padding:20px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top:15px;
    padding-bottom: 40px;
    font-size:18px;
`;
const Title = styled.h1`
    letter-spacing: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    display: block;
    flex: 0 1 auto;
    margin: 0;
    color: #041e42;
`;
const Column = styled.div`
    box-sizing: border-box;
    outline: none;
    display: block;
    width: 100%;
    border-top: 1px solid #dedede;
    padding: 10px 0px;
`;
const ColumnHeadWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;

`;
const ColumnTitle = styled.h1`
    margin:0;
    padding:0;
    flex: 1 1 auto;
    font-size: 25px;
    line-height: 1.4;
    font-weight: 500;
    cursor: pointer;
    color: #041e42;
`;
const ColumnClearButton = styled.button`
        pointer-events: all;
    box-sizing: border-box;
    flex: 0 1 auto;
    font-size: 12px;
    line-height: 1.25;
    font-weight: 700;
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0 50px 0 0;
    text-transform: uppercase;
    color: #686868;
    letter-spacing: 1px;
    display: block;
`;
const Icon = styled.div`
    transition: transform 0.3s ease-in-out;
    transform: ${({ rotated }) => (rotated ? 'rotate(180deg)' : 'rotate(0)')};

`;
const CategoryWrapper= styled.div`
    ${({ animationColumn }) => animationColumn
    ? css`
      animation: ${slideInRight} 0.3s ease-in-out both;
      `
    : css`
    animation: ${slideInLeft} 0.3s ease-in-out both;
    `}
`;
const NicotineWrapper= styled.div`
    ${({ animationColumn }) => animationColumn
    ? css`
      animation: ${slideInLeft} 0.3s ease-in-out both;
    `
    : css`
      animation: ${slideInRight} 0.3s ease-in-out both;
    `}
`;
const ColumnOptions = styled.div`
    display: block;
    width: 100%;
    overflow: hidden;
    max-height: 100rem;
    //padding-bottom: 10px;
    transition: .4s all cubic-bezier(.22,1,.36,1);
    
    
`;
const Label = styled.label`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    line-height: 1.5;
    margin: 0 0 1rem;
    text-transform: none;
    cursor: pointer;
    
`;
const LabelTitle = styled.h1`
    margin: 0;
    padding: 0;
    display: block;
    flex: 1 1 auto;
    order: 2;
    line-height: 1;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Righteous';
`;
const LabelCount = styled.div`
    display: block;
    flex: 0 1 auto;
    min-width: 2em;
    margin-left: auto;
    margin-right: 0;
    color: #686868;
    order: 4;
    line-height: 1;
`;
const LabelInput = styled.input`
    //appearance: none;
    display: block;
    flex: 0 0 auto;
    width: 14px;
    height: 14px;
    border-radius: 0;
    margin: 0 16px 0 0;
    padding: 0;
    background: none;
    outline: none;
    order: 1;
    border: 2px solid #041e42;
    
`;



  const Circles = styled.div`
    display:flex;
    justify-content: space-around;

    flex: 0 1 auto;
    order:3;
    margin-right:10px;
`;
const Circle = styled.div`

    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #9dfafa;
    margin:0px 1px;
    border: 0.5px solid black;
    
`;
function Category({ item, categoryCounts}) {
    let { handleCategoryChange, category} = useContext(ProductContext)
    
  return (
    <ColumnOptions key={item} >
      <Label
      
      >
         <LabelTitle>{item}</LabelTitle>
        <LabelCount>({categoryCounts[item]})</LabelCount>
        <LabelInput type="checkbox" 
        checked={category.includes(item)} 
        onChange={() => {}}
        // onClick={(event) => {
        //   event.stopPropagation(); 
        // }}
        onClick={() => handleCategoryChange(item)}
        />
      </Label>
    </ColumnOptions>
  );
}
  function Nicotine({ item,nicotineCounts}) {
    let { handleNicotineChange, nicotine} = useContext(ProductContext)

    const getColor = (nicotineVal, val) => {
      const nicotineValue = parseInt(nicotineVal);
      return nicotineValue > val ? "teal" : "white";
    };
    
    return (
      <ColumnOptions key={item}>
        <Label
        
        >
          <LabelTitle >{item}MG</LabelTitle>
          <LabelCount>({nicotineCounts[item]})</LabelCount>
          <LabelInput type="checkbox" 
            checked={nicotine.includes(item)} 
            //onClick={(event) => event.stopPropagation()}
            onChange={() => {}}
            onClick={() => handleNicotineChange(item)}
          />
          <Circles>
                <Circle style={{ backgroundColor: getColor(item, 0)}} /><Circle style={{ backgroundColor: getColor(item, 5)}}/><Circle style={{ backgroundColor: getColor(item, 13)}}/><Circle style={{ backgroundColor: getColor(item, 20)}}/><Circle style={{ backgroundColor: getColor(item, 30)}}/>
            </Circles>
        </Label>
      </ColumnOptions>
    );
  }

const ShopFilterMenu = ({nicotineValues,nicotineCounts, categoryCounts, categoryValues}) => {
    
    const { t } = useTranslation();
    let { loading } = useContext(ProductContext)


  
    const [isOpenContainer, setIsOpenContainer] = useState(true);
    const [isOpenBox, setIsOpenBox] = useState(true);
    const [isBoxAnimating, setIsBoxAnimating] = useState(false);
    const toggleBox = () => {
      if(isBoxAnimating==true){
        console.log("vratio")
        return;}
        setIsOpenBox(!isOpenBox);
        if(isOpenContainer === false){
          setIsBoxAnimating(true);
        setTimeout(() => {
          setIsOpenContainer(!isOpenContainer);
          setIsBoxAnimating(false);
        }, 1400); // 1400ms is equal to animation duration in milliseconds
    }else{
        setIsOpenContainer(!isOpenContainer);
    }

    };
    const [itemsCategories, setItemsCategoriessetItems] = useState(true);
    const [rotated, setRotated] = useState(false);
    const [isCategoriesAnimating,setIsCategoriesAnimating] = useState(false);
    const handleClickColumnCategories = () => {
        if(isCategoriesAnimating==true){
          console.log("vratio")
          return;}
        setRotated(!rotated);
        if(itemsCategories === true){
          setIsCategoriesAnimating(true);
            setTimeout(() => {
              setIsCategoriesAnimating(false);
              setItemsCategoriessetItems(!itemsCategories);
              }, 400);
        }else{
            setItemsCategoriessetItems(!itemsCategories);
        }
      };
      const [itemsNicotine, setItemsNicotine] = useState(true);
      const [rotatedNicotine, setRotatedNicotine] = useState(false);
      const [isNicotineAnimating, setIsNicotineAnimating] = useState(false);
    
      const handleClickColumnNicotine = () => {
        if(isNicotineAnimating == true){return;}
        setRotatedNicotine(!rotatedNicotine);
        if(itemsNicotine === true){
          setIsNicotineAnimating(true);
            setTimeout(() => {
                setIsNicotineAnimating(false);
                setItemsNicotine(!itemsNicotine);
              }, 400);
        }else{
            setItemsNicotine(!itemsNicotine);
        }
      };
      const handleStopPropagation = (event) => {
        event.stopPropagation();
      };
    
    
    return (
    <ContainerX >
      <ContainerLink  onClick={toggleBox}>
        <ContainerTitle> Filter
        </ContainerTitle>
        <Tune />
      </ContainerLink>
        <Container onClick={toggleBox} isOpen={isOpenContainer} isBoxAnimating={isBoxAnimating} style={{postion:"relative"}}>
          {loading && <Loading/> }
        <Box isOpen={isOpenBox} isBoxAnimating={isBoxAnimating} onClick={handleStopPropagation}>
            <TitleWrapper>
                <Title>FILTER</Title>
                <Close onClick={toggleBox} style={{fontSize:"30px"}}/>
            </TitleWrapper>
             <Column id="kategorija">
                <ColumnHeadWrapper onClick={handleClickColumnCategories}>
                    <ColumnTitle>{t('ShopFilterMenu.CATEGORY_COLUMN_TITLE')}</ColumnTitle>
                    <ColumnClearButton>{t('ShopFilterMenu.CLEAR_BUTTON_TEXT')}</ColumnClearButton>
                    <Icon rotated={rotated} ><KeyboardArrowDown/> </Icon>
                </ColumnHeadWrapper>
                 {itemsCategories && ( 
                <CategoryWrapper animationColumn={isCategoriesAnimating}>
                {categoryValues?.map((item,index) => (
                    <Category key={item} item={item} 
                    index={index} 
                    categoryCounts={categoryCounts}
                    />
            ))}
            </CategoryWrapper>
            )}
            </Column> 
            <Column style={{paddingBottom:"140px"}} id="nikotin">
                <ColumnHeadWrapper onClick={handleClickColumnNicotine}>
                    <ColumnTitle>{t('ShopFilterMenu.NICOTINE_COLUMN_TITLE')}</ColumnTitle>
                    <ColumnClearButton>{t('ShopFilterMenu.CLEAR_BUTTON_TEXT')}</ColumnClearButton>
                    <Icon rotated={rotatedNicotine} ><KeyboardArrowDown/> </Icon>
                </ColumnHeadWrapper>
                 {itemsNicotine && ( 
                <NicotineWrapper animationColumn={setIsNicotineAnimating}>
                {nicotineValues?.map((item, index) => (
                    <Nicotine key={item} item={item} 
                    index={index} 
                    nicotineCounts={nicotineCounts}
                    />
            ))}
            </NicotineWrapper>
            )}
            </Column> 
        </Box>
        </Container >
    </ContainerX>
  )
}

export default ShopFilterMenu